import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import FirstView from "../../Repeated-Component/FirstView";
import EgcImage from "../../../../../../../../assets/medical-record/requests/ECG.png";
import { connect } from "react-redux";
import { getEcgList } from "../../../../../../../../store/actions";
import { useToast } from "@chakra-ui/react";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const ListingEcg = ({ ecg, patient, getEcgList, EcgList }) => {
  const { url } = useRouteMatch();
  let query = useQuery().get("page");
  const toast = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Ecg, setEcg] = useState([]);
  const [filterEcg, setFilterEcg] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    getEcgList(toast, history, patient.id, query);
  }, [getEcgList, query]);

  useEffect(() => {
    if (EcgList) {
      const { loading, error, data } = EcgList;
      setLoading(loading);
      setError(error);
      if (data) {
        setEcg(data.data);
        setFilterEcg(data.data);
        setTotalResults(data.meta.total);
        setCuttentPage(data.meta.current_page);
      }
    }
  }, [EcgList]);

  return (
    <div>
      <FirstView
        loading={loading}
        error={error}
        headerName="ECG"
        buttonLink={`${url}/request-ecg`}
        cardLink={`${url}/view-ecg`}
        data={Ecg}
        filterData={filterEcg}
        setFilter={setFilterEcg}
        conditionImage={EgcImage}
        pages={pagesNumber}
        nextPage={nextPage}
        prevPage={prevPage}
        specificPage={specificPage}
        currentPage={currentPage}
        totalResults={totalResults}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    EcgList: state.EcgList,
  };
};
export default connect(mapStateToProps, { getEcgList })(ListingEcg);
