import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import FirstView from "../../Repeated-Component/FirstView";
import medicalReportImage from "../../../../../../../../assets/medical-record/requests/medical.png";
import { connect } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { getMedicalReportList } from "../../../../../../../../store/actions";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ListingMedicalReport = ({
  patient,
  getMedicalReportList,
  MedicalReportList,
}) => {
  const { url } = useRouteMatch();
  let query = useQuery().get("page");
  const toast = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [MedicalReport, setMedicalReport] = useState([]);
  const [filterMedicalReport, setFilterMedicalReport] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    getMedicalReportList(toast, history, patient.id, query);
  }, [getMedicalReportList, query]);

  useEffect(() => {
    if (MedicalReportList) {
      const { loading, error, data } = MedicalReportList;
      setLoading(loading);
      setError(error);
      if (data) {
        setMedicalReport(data.data);
        setFilterMedicalReport(data.data);
        setTotalResults(data.meta.total);
        setCuttentPage(data.meta.current_page);
      }
    }
  }, [MedicalReportList]);
  return (
    <div>
      <FirstView
        loading={loading}
        error={error}
        headerName="Medical Reports"
        buttonLink={`${url}/request-medical_report`}
        cardLink={`${url}/view-medical_report`}
        data={MedicalReport}
        filterData={filterMedicalReport}
        setFilter={setFilterMedicalReport}
        conditionImage={medicalReportImage}
        pages={pagesNumber}
        nextPage={nextPage}
        prevPage={prevPage}
        specificPage={specificPage}
        currentPage={currentPage}
        totalResults={totalResults}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    MedicalReportList: state.MedicalReportList,
  };
};
export default connect(mapStateToProps, { getMedicalReportList })(
  ListingMedicalReport
);
