import React, { useEffect, useState } from "react";
import { Box, Button, Text, useColorMode } from "@chakra-ui/react";

import { ErrorMessage, FieldArray, getIn } from "formik";
import moment from "moment";

import { style } from "../../../../global/style";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import {
  checkColorMode,
  checkColorModeHover,
} from "../../../../global/changeColor";
const ProfileFieldArray = ({ values, setFieldValue }) => {
  // const [dayes] = useState([
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  // ]);
  const [deletedItems, setDeletedItems] = useState([]);
  const { colorMode } = useColorMode();

  // function disableAfterTwoWeeks(date) {
  //   const afterTwoWeeks = moment().add(2, "weeks").format();
  //   return date.toISOString() > afterTwoWeeks;
  // }
  useEffect(() => {
    localStorage.setItem("deletedItems", JSON.stringify(deletedItems));
  }, [deletedItems]);
  return (
    <FieldArray name={"schedules"}>
      {({ remove, push }) => (
        <>
          <Box className="d-flex flex-wrap align-items-center mb-3 justify-content-between">
            <Text
              className=""
              color={style.secondary}
              fontSize={"lg"}
              fontWeight="semibold"
            >
              Appointment Time
            </Text>
            <Button
              bg={checkColorMode(colorMode)}
              _hover={{ bg: checkColorModeHover(colorMode) }}
              color="white"
              onClick={() => {
                push({
                  id: null,
                  from_time: null,
                });
              }}
              className="my-2"
            >
              <i className="fas fa-plus"></i>
            </Button>
          </Box>
          {values.schedules && values.schedules.length
            ? values.schedules.map((item, index) => (
                <Box
                  key={index}
                  className="col-12 mt-3 row m-0 p-0 align-items-center "
                >
                  <Box className="col-md-5 mt-3 p-0">
                    <label
                      htmlFor={`schedules[${index}].from_time`}
                      className="chakra-form__label css-2gx1h6 mb-2"
                    >
                      Select date
                    </label>
                    <Flatpickr
                      name={`schedules[${index}].from_time`}
                      value={getIn(values, `schedules[${index}].from_time`)}
                      onChange={([date]) => {
                        setFieldValue(
                          `schedules[${index}].from_time`,
                          moment(date).format()
                        );
                      }}
                      placeholder="Select date"
                      className="mt-2"
                      options={{
                        enableTime: true,
                        altInput: true,
                        altFormat: "d/m/Y h:i K",
                        minDate: "today",
                        maxDate: new Date().fp_incr(14),
                      }}
                      disabled={item.consultant_appointment_id}
                    />

                    <ErrorMessage
                      name={`schedules[${index}].from_time`}
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "14px" }}
                    />
                  </Box>

                  {values.schedules.length > 1 ? (
                    <div className="col-md-1 ps-2 text-end p-0">
                      <Button
                        bg={style.error_Scheme}
                        color={style.error}
                        _hover={{
                          bg: style.error_Scheme_hover,
                        }}
                        _active={{
                          bg: style.error_Scheme,
                        }}
                        _focus={{
                          border: "none",
                        }}
                        onClick={() => {
                          item.delete = true;
                          if (item.id && item.from_time) {
                            setDeletedItems([...deletedItems, item]);
                          }
                          remove(index);
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
              ))
            : ""}
        </>
      )}
    </FieldArray>
  );
};

export default ProfileFieldArray;
