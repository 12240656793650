import { types } from "../types/types";
const appointmentTypes = types.appointmentTypes;

const INIT_APPOINTMENTS_STATE = {
  loading: false,
  error: null,
  data: null,
};
export const getAppointmentListReducer = (
  state = INIT_APPOINTMENTS_STATE,
  { type, payload }
) => {
  switch (type) {
    case appointmentTypes.GET_APPOINTMENTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case appointmentTypes.GET_APPOINTMENTS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case appointmentTypes.GET_APPOINTMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
