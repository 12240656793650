import React from "react";
import { Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../global/Animations/Animations";

const AccountNotActivate = () => {
  return (
    <motion.div
      className="mt-5 text-center"
      variants={pageVariants}
      transition={pageTransition}
      initial="out"
      animate="in"
      exit="out"
    >
      <Text fontWeight="semibold" fontSize="xl">
        Your account is not active. Please contact the administrator to activate
        your account
      </Text>
    </motion.div>
  );
};

export default AccountNotActivate;
