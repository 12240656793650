import React from "react";
import { Box, Text, useColorMode } from "@chakra-ui/react";
import { Link, NavLink, useRouteMatch } from "react-router-dom";

import "./overViewSideNav.scss";
import { checkBgColorMode, checkColorMode } from "../global/changeColor";

const OverViewSideNav = ({ requestes, appointment, closeSideNave }) => {
  const { url } = useRouteMatch();
  const { colorMode } = useColorMode();
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      boxShadow="xs"
      className="w-100 p-3 consultation-sideNav"
    >
      <Link to={""}>
        <Box className="d-flex align-items-center px-3" fontWeight="semibold">
          <Text
            className="me-2"
            fontSize={"lg"}
            color={checkColorMode(colorMode)}
            fontWeight="extrabold"
          >
            <i className="fas fa-chevron-left"></i>
          </Text>
          <Text
            fontSize="sm"
            color={colorMode === "dark" ? "white" : "secondary"}
          >
            New Consultations
          </Text>
        </Box>
      </Link>

      <ul className="py-3" style={{ listStyle: "none" }}>
        <li
          className="list-item my-2"
          onClick={() => {
            if (closeSideNave) closeSideNave();
          }}
        >
          <NavLink
            exact
            activeClassName={
              colorMode === "dark"
                ? "active-consultation-link-dark"
                : "active-consultation-link"
            }
            to={`${url}`}
            className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
              colorMode === "dark" ? "consultation_nav-dark" : ""
            }`}
          >
            <Box
              className="d-flex align-items-center"
              fontSize={"sm"}
              fontWeight="bold"
            >
              <Text className="me-2">
                <i className="fas fa-eye"></i>
              </Text>
              <Text className="me-1">OverView</Text>
            </Box>
          </NavLink>
        </li>
        {requestes.length ? (
          <li
            className="list-item mb-2"
            onClick={() => {
              if (closeSideNave) closeSideNave();
            }}
          >
            <NavLink
              activeClassName={
                colorMode === "dark"
                  ? "active-consultation-link-dark"
                  : "active-consultation-link"
              }
              to={`${url}/requestes`}
              className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
                colorMode === "dark" ? "consultation_nav-dark" : ""
              }`}
            >
              <Box
                className="d-flex align-items-center"
                fontSize={"sm"}
                fontWeight="bold"
              >
                <Text className="me-2">
                  <i className="fas fa-notes-medical"></i>
                </Text>
                <Text className="me-1">Requests</Text>
              </Box>
            </NavLink>
          </li>
        ) : (
          ""
        )}

        <li
          className="list-item mb-2"
          onClick={() => {
            if (closeSideNave) closeSideNave();
          }}
        >
          <NavLink
            activeClassName={
              colorMode === "dark"
                ? "active-consultation-link-dark"
                : "active-consultation-link"
            }
            to={`${url}/medical-report`}
            className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
              colorMode === "dark" ? "consultation_nav-dark" : ""
            }`}
          >
            <Box
              className="d-flex align-items-center"
              fontSize={"sm"}
              fontWeight="bold"
            >
              <Text className="me-2">
                <i className="fas fa-clipboard-list"></i>
              </Text>
              <Text className="me-1">Doctor Report</Text>
            </Box>
          </NavLink>
        </li>

        <li
          className="list-item mb-2"
          onClick={() => {
            if (closeSideNave) closeSideNave();
          }}
        >
          <NavLink
            activeClassName={
              colorMode === "dark"
                ? "active-consultation-link-dark"
                : "active-consultation-link"
            }
            to={`${url}/readings`}
            className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
              colorMode === "dark" ? "consultation_nav-dark" : ""
            }`}
          >
            <Box
              className="d-flex align-items-center"
              fontSize={"sm"}
              fontWeight="bold"
            >
              <Text className="me-2">
                <i className="fas fa-clipboard"></i>
              </Text>
              <Text className="me-1">Readings</Text>
            </Box>
          </NavLink>
        </li>

        <li
          className="list-item mb-2"
          onClick={() => {
            if (closeSideNave) closeSideNave();
          }}
        >
          <NavLink
            activeClassName={
              colorMode === "dark"
                ? "active-consultation-link-dark"
                : "active-consultation-link"
            }
            to={`${url}/medical-record/conditions`}
            className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
              colorMode === "dark" ? "consultation_nav-dark" : ""
            }`}
          >
            <Box
              className="d-flex align-items-center"
              fontSize={"sm"}
              fontWeight="bold"
            >
              <Text className="me-2">
                <i className="fas fa-book-medical"></i>
              </Text>
              <Text className="me-1">Medical Record</Text>
            </Box>
          </NavLink>
        </li>
        {appointment ? (
          <li
            className="list-item mb-2"
            onClick={() => {
              if (closeSideNave) closeSideNave();
            }}
          >
            <NavLink
              activeClassName={
                colorMode === "dark"
                  ? "active-consultation-link-dark"
                  : "active-consultation-link"
              }
              to={`${url}/appointment`}
              className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
                colorMode === "dark" ? "consultation_nav-dark" : ""
              }`}
            >
              <Box
                className="d-flex align-items-center"
                fontSize={"sm"}
                fontWeight="bold"
              >
                <Text className="me-2">
                  <i className="far fa-calendar-alt"></i>
                </Text>
                <Text className="me-1">Appointment</Text>
              </Box>
            </NavLink>
          </li>
        ) : (
          ""
        )}
      </ul>
    </Box>
  );
};

export default OverViewSideNav;
