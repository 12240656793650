import { Text, Box, useColorMode } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { checkBgColorMode } from "../global/changeColor";

const Footer = () => {
  const { colorMode } = useColorMode();
  return (
    <div className="footer container-fluid p-0">
      <Box bg={checkBgColorMode(colorMode)} className="row m-0 shadow">
        <div className="col-12 row m-0 p-3 align-items-center">
          <div className="col-md-4 me-auto">
            <Text fontSize="sm" fontWeight="semibold">
              &copy; 2022 PatRec
            </Text>
          </div>
          <Box
            className="col-md-6 d-flex justify-content-end"
            fontSize="sm"
            color="smallTextGray"
          >
            <Text>
              <a
                rel="noreferrer"
                href="https://pat-rec.com/privacy-policy"
                target={"_blank"}
                className="nav-link"
              >
                Privacy Policy
              </a>
            </Text>
            <span className="mx-2">.</span>
            <Text>
              <a
                rel="noreferrer"
                href="https://pat-rec.com/terms-of-use"
                target={"_blank"}
                className="nav-link"
              >
                Terms of use
              </a>
            </Text>
            <span className="mx-2">.</span>
            <Text as={Link} to={"#"} className="nav-link">
              {" "}
              Cookies
            </Text>
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default Footer;
