import React from "react";

import { Text, Button, Box, useColorMode } from "@chakra-ui/react";

import EmptyMedicalReport from "../../../../../../assets/svg-icons/EmptyMedicalReport.svg";
import { Link, useRouteMatch } from "react-router-dom";
import { style } from "../../../../../global/style";
import {
  pageTransition,
  pageVariants,
} from "../../../../../global/Animations/Animations";
import { motion } from "framer-motion";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../../global/changeColor";

const EmptyReport = ({ Consultation }) => {
  const { url } = useRouteMatch();
  const { colorMode } = useColorMode();
  return (
    <motion.div
      className="my-3"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Text
        fontSize="2xl"
        fontWeight="semibold"
        color={checkColorMode(colorMode)}
      >
        Doctor Report
      </Text>
      <Box
        bg={checkBgColorMode(colorMode)}
        className="col-md-12 p-3 rounded row m-0 my-3 align-items-center justify-content-center"
      >
        <Box className="d-flex justify-content-center mt-5">
          <img src={EmptyMedicalReport} alt="EmptyMedicalReport" />
        </Box>

        <Box className="text-center">
          <Text fontSize={"2xl"} fontWeight="bold" className="mt-5">
            Medical Report
          </Text>
          <Text fontSize={"sm"} className="mt-1" color={style.smallTextGray}>
            Add your Medical Report
          </Text>
        </Box>
        <Button
          as={Link}
          to={`${url}/add-report`}
          bg={checkColorMode(colorMode)}
          color={"white"}
          _hover={{ bg: checkColorModeHover(colorMode), color: "white" }}
          size="lg"
          className="mb-5 mt-4"
          disabled={
            Consultation.status === "done" ||
            Consultation.status === "finished_appointment" ||
            Consultation.status === "new"
          }
          pointerEvents={
            Consultation.status === "done" ||
            Consultation.status === "finished_appointment" ||
            Consultation.status === "new"
              ? "none"
              : ""
          }
        >
          <span className="me-2">
            <i className="fas fa-plus"></i>
          </span>{" "}
          Add Medical Report
        </Button>
      </Box>
    </motion.div>
  );
};

export default EmptyReport;
