import React, { useState } from "react";
import { connect } from "react-redux";
import conditionImage from "../../../../../../../../assets/medical-record/requests/conditions.png";
import { useParams, useHistory } from "react-router-dom";

import {
  getSingleConsultations,
  requestScan,
} from "../../../../../../../../store/actions";

import AddRequest from "../../Repeated-Component/request";

import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";

const Schema = Yup.object().shape({
  title: Yup.string().min(4).required("Required"),
  body: Yup.string().min(4).required("Required"),
});

const RequestCondition = ({
  requestScan,
  requestedSacn,
  getSingleConsultations,
}) => {
  const history = useHistory();
  const toast = useToast();
  let { id } = useParams();

  const [initValue] = useState({
    title: "",
    body: "",
    scan_type: "condition",
    consultationId: id,
  });

  const onFormSubmit = (value) => {
    requestScan(value, toast, getSingleConsultations, id, null, history);
  };
  return (
    <AddRequest
      Schema={Schema}
      initValue={initValue}
      history={history}
      onFormSubmit={onFormSubmit}
      headerText={`Request Condition`}
      secondHeaderText="Condition"
      therdHeaderText="Request Conditions"
      image={conditionImage}
      feildsNames={{
        feild1: {
          name: "title",
          type: "text",
          placeholder: "Title",
        },
        feild2: {
          name: "body",
          type: "text",
          placeholder: "Note",
        },
      }}
      btnLoading={requestedSacn.loading}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    requestedSacn: state.requestedSacn,
  };
};
export default connect(mapStateToProps, {
  requestScan,
  getSingleConsultations,
})(RequestCondition);
