import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./textEditor.css";
import { useColorMode } from "@chakra-ui/react";

export const prepareDraft = (value) => {
  const draft = htmlToDraft(value);
  const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};
export const TextEditor = ({ value, setFieldValue, toDraft }) => {
  const { colorMode } = useColorMode();
  const localStorageHtml = localStorage.reportHtml;
  const [editorState, setEditorState] = useState(
    value ? prepareDraft(value) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setEditorState(editorState);
    localStorage.setItem("reportHtml", forFormik);
  };
  useEffect(() => {
    if (toDraft) {
      setFieldValue(localStorageHtml);
      setEditorState(toDraft);
    }
  }, [toDraft]);
  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="custom-wrapper"
        editorClassName="custom-editor"
        onEditorStateChange={onEditorStateChange}
        name="note"
        toolbarStyle={{
          color: "#000",
          background: colorMode === "dark" ? "gray" : "#fff",
        }}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "remove",
            "history",
          ],
        }}
      />
    </div>
  );
};
