import allergy from "../../../../../assets/medical-record/allergy.png";
import condition from "../../../../../assets/medical-record/condition.png";
import ctScan from "../../../../../assets/medical-record/ctScan.png";
import Egc from "../../../../../assets/medical-record/Egc.png";
import labResult from "../../../../../assets/medical-record/lab-result.png";
import medicalReport from "../../../../../assets/medical-record/medical-report.png";
import Xray from "../../../../../assets/medical-record/Xray.png";
import bloodPressure from "../../../../../assets/Readings/bloodPressure.png";
import bloodSugar from "../../../../../assets/Readings/bloodSugar.png";
import height from "../../../../../assets/Readings/height.png";
import temperature from "../../../../../assets/Readings/temperature.png";
import weight from "../../../../../assets/Readings/weight.png";
import Mri from "../../../../../assets/medical-record/ctScan.png";
// import Question from "../../../../../assets/svg-icons/Question.svg";
export const helperImageAndLinks = (item) => {
  const { consultation_request_id, id, scan_type, related_to_id } = item;
  switch (scan_type) {
    case "radiology-xray":
      return {
        link: `/consultation/${consultation_request_id}/medical-record/x-ray`,
        img: Xray,
      };

    case "radiology-mri":
      return {
        link: `/consultation/${consultation_request_id}/medical-record/mri-ct scan`,
        img: Mri,
      };

    case "allergy":
      return {
        link: `/consultation/${consultation_request_id}/medical-record/allergies`,
        img: allergy,
      };

    case "ecg":
      return {
        link: `/consultation/${consultation_request_id}/medical-record/ecg`,
        img: Egc,
      };

    case "lab_result":
      return {
        link: `/consultation/${consultation_request_id}/medical-record/lab-result`,
        img: labResult,
      };

    case "condition":
      return {
        link: `/consultation/${consultation_request_id}/medical-record/conditions`,
        img: condition,
      };

    case "new-radiology":
      return {
        link: `/consultation/${consultation_request_id}/medical-record/radiology`,
        img: ctScan,
      };

    case "medical_report":
      return {
        link: `/consultation/${consultation_request_id}/medical-record/medical-report`,
        img: medicalReport,
      };

    case "consultant-question":
      return {
        link: `/consultation/${consultation_request_id}/medical-record/question`,
        img: null,
        className: "far fa-question-circle",
      };

    case "reading-height":
      return {
        link: `/consultation/${consultation_request_id}/readings`,
        img: height,
      };

    case "reading-weight":
      return {
        link: `/consultation/${consultation_request_id}/readings`,
        img: weight,
      };

    case "reading-blood-pressure":
      return {
        link: `/consultation/${consultation_request_id}/readings`,
        img: bloodPressure,
      };

    case "reading-blood-sugar":
      return {
        link: `/consultation/${consultation_request_id}/readings`,
        img: bloodSugar,
      };

    case "reading-heart-rate":
      return {
        link: `/consultation/${consultation_request_id}/readings`,
        img: null,
        className: "fas fa-heartbeat",
      };

    case "reading-temperature":
      return {
        link: `/consultation/${consultation_request_id}/readings`,
        img: temperature,
      };

    default:
      return {
        link: `#`,
        img: "",
      };
  }
};
