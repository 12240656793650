import {
  Avatar,
  Box,
  Button,
  Circle,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import contact from "../../../../../assets/contact.svg";
import { motion } from "framer-motion";
import {
  pageTransition,
  pageVariants,
} from "../../../../global/Animations/Animations";
import { style } from "../../../../global/style";
import {
  checkBgColorMode,
  checkColorMode,
} from "../../../../global/changeColor.js";
import MeetingDetailsModal from "./MeetingDetailsModal.js";

const Appointment = ({ Consultation, history, appointment, patient }) => {
  const [EndTime] = useState(
    moment.unix(Consultation?.appointmentEndTime).format("YYYY-MM-DD HH:mm")
  );
  const [startTime] = useState(
    moment.unix(appointment?.appointment_time).format("YYYY-MM-DD HH:mm")
  );
  const [checkMeetingTime] = useState(moment().isSameOrAfter(startTime));

  const checkMeeting = () => {
    const checkMeetingEndTime = moment().isSameOrAfter(EndTime); //remove it
    if (checkMeetingEndTime) {
      return "";
    } else {
      return (
        <Box className="mt-3 d-flex justify-content-end">
          <Box className="w-auto text-center">
            <Button
              minW={"140.17px"}
              color="white"
              bg="secondary"
              _hover={{ bg: "secondary_hover", color: "white" }}
              size="lg"
              target="_blank"
              disabled={!checkMeetingTime}
              pointerEvents={checkMeetingTime === false ? "none" : ""}
            >
              <a
                className="text-white"
                href={
                  Consultation?.ConsultantAppointment?.online_meeting_data
                    ?.joinUrl
                }
                target="_blank"
              >
                Join appointment{" "}
              </a>
            </Button>
            <MeetingDetailsModal
              checkMeetingTime={checkMeetingTime}
              Consultation={Consultation}
              meetingData={
                Consultation.ConsultantAppointment.online_meeting_data
              }
            />
          </Box>
        </Box>
      );
    }
  };
  const { colorMode } = useColorMode();

  const renderView = () => {
    if (appointment) {
      return (
        <motion.div
          className=""
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <Box className="row mt-4">
            <Text
              fontSize="2xl"
              fontWeight="semibold"
              color={checkColorMode(colorMode)}
              className="col-12"
            >
              Appointment
            </Text>
            <Box className="col-12">
              <Box
                bg={checkBgColorMode(colorMode)}
                className="p-2 py-4 shadow-sm rounded row m-0 mt-4 justify-content-center"
              >
                <div className="col-md-5 col-12 my-2">
                  <Image
                    src={contact}
                    alt="...."
                    className="img-fluid w-100"
                  ></Image>
                </div>
                <div className="col-md-7 row m-0 align-items-center my-2">
                  <Text fontSize="2xl" fontWeight="semibold" className="col-12">
                    {moment
                      .unix(appointment.appointment_time)
                      .format("dddd MMM DD , yyyy - hh:mm A")}
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="semibold"
                    className="col-12 my-4"
                    color={style.smallTextGray}
                  >
                    Appointment
                  </Text>
                  {patient ? (
                    <Box className="col-12 row m-0 p-0 ">
                      <div className="col-md-6 row m-0 align-items-center p-0">
                        <Avatar
                          size="md"
                          src={patient.picture}
                          name={patient.fullName}
                          bg={style.primary}
                          className="p-0"
                          color={"#FFF"}
                        />
                        <div className="col-8">
                          <Text
                            fontSize="13px"
                            color={style.smallTextGray}
                            fontWeight="semibold"
                          >
                            Patient
                          </Text>
                          <Text fontSize="11px" fontWeight="semibold">
                            {patient.fullName}
                          </Text>
                        </div>
                      </div>
                      <div className="w-100">{checkMeeting()}</div>
                    </Box>
                  ) : (
                    ""
                  )}
                </div>
              </Box>
            </Box>
          </Box>
        </motion.div>
      );
    } else {
      return (
        <Box className="row mt-4">
          <Text
            fontSize="2xl"
            fontWeight="semibold"
            color={style.primary}
            className="col-12"
          >
            Appointment
          </Text>
          <Text className="mt-3" fontWeight="semibold" fontSize="2xl">
            There's no Appointment
          </Text>
        </Box>
      );
    }
  };
  return renderView();
};

export default connect()(Appointment);
