import { Box, Text, useColorMode } from "@chakra-ui/react";
import { InputControl, SelectControl } from "formik-chakra-ui";
import React from "react";
import { checkBgColorMode } from "../../global/changeColor";
import NmberInput from "../../global/NmberInput";
const AboutMe = ({ PatientTypes, consultationTypesList }) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="rounded shadow-sm p-3 row m-0 py-4 my-3"
    >
      <Text
        className="mb-3"
        color="secondary"
        fontSize="15px"
        fontWeight="semibold"
      >
        About Me
      </Text>

      <div className="col-lg-6 mt-3">
        <SelectControl
          label="Patient Type"
          name="patientTypeId"
          selectProps={{
            placeholder: "Patient Type",
            className: "chakra-input",
          }}
          color="gray.500"
        >
          {PatientTypes && PatientTypes.length
            ? PatientTypes.map((item, index) => (
                <option key={index} value={item.id} style={{ color: "#000" }}>
                  {item.name}
                </option>
              ))
            : null}
        </SelectControl>
      </div>

      <div className="col-lg-6 mt-3">
        <SelectControl
          label="Specialty"
          name="consultationTypes"
          selectProps={{
            placeholder: "Specialty",
            className: "chakra-input",
          }}
          color="gray.500"
        >
          {consultationTypesList && consultationTypesList.length
            ? consultationTypesList.map((item, index) => (
                <option key={index} value={item.id} style={{ color: "#000" }}>
                  {item.name}
                </option>
              ))
            : null}
        </SelectControl>
      </div>

      <div className="col-lg-6 mt-3">
        <NmberInput
          name="gmcNumber"
          label="GMC number"
          placeholder="GMC number"
        />
      </div>

      <div className="col-lg-6 mt-3">
        <InputControl
          name="currentEmployer"
          label="Current employer"
          inputProps={{ placeholder: "Current employer" }}
        />
      </div>

      <div className="col-lg-6 mt-3">
        <NmberInput
          name="yearsOfExperienceAsConsultant"
          label="Years of experience as Consultant"
          placeholder="Years of experience"
        />
      </div>

      <div className="col-lg-6 mt-3">
        <InputControl
          name="jobTitle"
          label="Job Title"
          inputProps={{ placeholder: "Job Title" }}
        />
      </div>
    </Box>
  );
};

export default AboutMe;
