import { types } from "../types/types";
const consultationsTypes = types.consultationsTypes;

const INIT_CONSULTATIONS_STATE = {
  loading: false,
  error: null,
  data: null,
};

export const getActiveConsultationsListReducer = (
  state = INIT_CONSULTATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultationsTypes.GET_ACTIVE_CONSULTATIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultationsTypes.GET_ACTIVE_CONSULTATIONS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultationsTypes.GET_ACTIVE_CONSULTATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const getSingleConsultationReducer = (
  state = INIT_CONSULTATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultationsTypes.GET_SINGLE_CONSULTATION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultationsTypes.GET_SINGLE_CONSULTATION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultationsTypes.GET_SINGLE_CONSULTATION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const acceptConsultationReducer = (
  state = INIT_CONSULTATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultationsTypes.ACCEPT_NEW_CONSULTATION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultationsTypes.ACCEPT_NEW_CONSULTATION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultationsTypes.ACCEPT_NEW_CONSULTATION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const rejectConsultationReducer = (
  state = INIT_CONSULTATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultationsTypes.REJECT_NEW_CONSULTATION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultationsTypes.REJECT_NEW_CONSULTATION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultationsTypes.REJECT_NEW_CONSULTATION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getQuestionListReducer = (
  state = INIT_CONSULTATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultationsTypes.GET_QUESTIONS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultationsTypes.GET_QUESTIONS_LIST_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultationsTypes.GET_QUESTIONS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getSingleQuestionReducer = (
  state = INIT_CONSULTATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultationsTypes.GET_SINGLE_QUESTION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultationsTypes.GET_SINGLE_QUESTION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultationsTypes.GET_SINGLE_QUESTION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const requestQuestionReducer = (
  state = INIT_CONSULTATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultationsTypes.REQUEST_QUESTION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultationsTypes.REQUEST_QUESTION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultationsTypes.REQUEST_QUESTION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const addedReportReducer = (
  state = INIT_CONSULTATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultationsTypes.ADD_MEDICAL_REPORT_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultationsTypes.ADD_MEDICAL_REPORT_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultationsTypes.ADD_MEDICAL_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const requestScanReducer = (
  state = INIT_CONSULTATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultationsTypes.REQUEST_SCAN_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultationsTypes.REQUEST_SCAN_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultationsTypes.REQUEST_SCAN_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
