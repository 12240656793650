import { Box, Text, useColorMode } from "@chakra-ui/react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { InputControl, SelectControl } from "formik-chakra-ui";
import React from "react";
import PasswordField from "../../global/passwordField";
import DateFnsUtils from "@date-io/date-fns";
import { ErrorMessage } from "formik";
import TelephoneInput from "../../global/phoneNumber";
import { checkBgColorMode } from "../../global/changeColor";
const MyInfo = ({ values, setFieldValue }) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="rounded shadow-sm p-3 row m-0 py-4 "
    >
      <Text
        className="mb-3"
        color="secondary"
        fontSize="15px"
        fontWeight="semibold"
      >
        My Info
      </Text>

      <div className="col-lg-6 mt-3">
        <InputControl
          name="name"
          label="Name"
          inputProps={{ placeholder: "Name" }}
        />
      </div>

      <div className="col-lg-6 mt-3">
        <InputControl
          name="email"
          label="E-Mail"
          inputProps={{ placeholder: "E-Mail" }}
        />
      </div>

      <div className="col-lg-6 mt-3">
        <PasswordField label="Password" />
      </div>

      <div className="col-lg-6 mt-3">
        <PasswordField label="Re-enter Password" name="passwordConfirmation" />
      </div>

      <div className="col-lg-6 mt-3">
        <SelectControl
          label="Gender"
          name="gender"
          selectProps={{
            placeholder: "Gender",
            className: "chakra-input",
          }}
          color="gray.500"
        >
          <option value="male" style={{ color: "#000" }}>
            Male
          </option>
          <option value="female" style={{ color: "#000" }}>
            Female
          </option>
        </SelectControl>
      </div>

      <div className="col-lg-6 mt-3">
        <label htmlFor="dateOfBirth" className="chakra-form__label">
          Date of birth
        </label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className="css-k7r2wc mt-2 w-100"
            autoOk={true}
            label=""
            format="dd/MM/yyyy"
            views={["year", "month", "date"]}
            value={values.dateOfBirth}
            onChange={(value) => {
              setFieldValue("dateOfBirth", value);
            }}
            variant="inline"
            inputVariant="outlined"
            size="small"
            inputProps={{
              placeholder: "Select Date",
              style: {
                color: colorMode === "dark" ? "#fff" : "#000",
              },
            }}
            keyboardIcon={
              <span
                className={
                  colorMode === "dark" ? "text-white" : "text-secondary"
                }
              >
                <i className="fas fa-calendar-week"></i>
              </span>
            }
            disableFuture
          />
        </MuiPickersUtilsProvider>
        <ErrorMessage
          name="dateOfBirth"
          component="div"
          className="text-danger"
          style={{ fontSize: "14px" }}
        />
      </div>
      <div className="col-lg-6 mt-2">
        <TelephoneInput name="mobile" />
        <ErrorMessage
          name="mobile"
          component="div"
          className="text-danger"
          style={{ fontSize: "14px" }}
        />
      </div>
    </Box>
  );
};

export default MyInfo;
