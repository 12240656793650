import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";

import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import AddReport from "./NotReport/addReport";
import ViewReport from "./ViewReport/viewReport";

const MedicalReport = ({ medicalReports, Consultation }) => {
  const [medicalReport, setMedicalReport] = useState(null);
  useEffect(() => {
    if (medicalReports.length) {
      setMedicalReport(medicalReports[medicalReports.length - 1]);
    }
  }, [medicalReports]);

  const { path } = useRouteMatch();
  const loaction = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={loaction} key={loaction.pathname}>
        <Route
          exact
          path={path}
          render={() => (
            <ViewReport
              medicalReport={medicalReport}
              Consultation={Consultation}
            />
          )}
        />
        <Route path={`${path}/add-report`} component={AddReport} />
      </Switch>
    </AnimatePresence>
  );
};

export default MedicalReport;
