import Question from "../../assets/svg-icons/Question.svg";
import Consultaion from "../../assets/svg-icons/Consultaion.svg";

export const LinksAndImages = (notic) => {
  switch (notic && notic.data.type) {
    case "question-reply":
      return {
        anchor: true,
        link: `/consultation/${notic.data.consultationRequestId}/medical-record/question/view-question/${notic.data.id}`,
        img: Question,
      };

    case "edit_appointment":
      return {
        anchor: true,
        link: `/profile`,
        img: "",
        className: "fas fa-calendar-alt",
      };

    case "schedule-expiry" || "schedule-expired":
      return {
        anchor: true,
        link: `/profile`,
        img: "",
        className: "fas fa-calendar-alt",
      };

    case "schedule-expired":
      return {
        anchor: true,
        link: `/profile`,
        img: "",
        className: "fas fa-calendar-alt",
      };
    case "appointment-requested":
      return {
        anchor: true,
        link: `/profile`,
        img: "",
        className: "fas fa-calendar-alt",
      };

    case "new_appointment":
      return {
        anchor: true,
        link: `/consultation/${notic.data.consultationRequestId}/appointment`,
        img: "",
        className: "fas fa-calendar-alt",
      };

    default:
      return {
        anchor: true,
        link: `#`,
        img: "",
      };
  }
};
