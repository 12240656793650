import { types } from "../types/types";

const { notificationsTypes } = types;
const INIT_NOTIFICATIONS_STATE = {
    loading: false,
    error: null,
    data: null,
};
// list
export const getPatientNotificationsReducer = (
    state = INIT_NOTIFICATIONS_STATE,
    { type, payload }
) => {
    switch (type) {
        case notificationsTypes.GET_NOTIFICATION_LIST_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                data: null,
            };
        case notificationsTypes.GET_NOTIFICATION_LIST_SUCSES:
            return {
                ...state,
                loading: false,
                error: null,
                data: payload,
            };
        case notificationsTypes.GET_NOTIFICATION_LIST_ERORR:
            return {
                ...state,
                loading: false,
                error: payload,
                data: null,
            };
        default:
            return state;
    }
};

// export const getPatientNotificationsForPaginationReducer = (
//     state = INIT_NOTIFICATIONS_STATE,
//     { type, payload }
// ) => {
//     switch (type) {
//         case notificationsTypes.GET_NOTIFICATIONS_LOADING:
//             return {
//                 ...state,
//                 loading: true,
//                 error: null,
//                 data: null,
//             };
//         case notificationsTypes.GET_NOTIFICATIONS_SUCSES:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 data: payload,
//             };
//         case notificationsTypes.GET_NOTIFICATIONS_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: payload,
//                 data: null,
//             };
//         default:
//             return state;
//     }
// };

// un read


export const getPatientUnReadNotificationsReducer = (
    state = INIT_NOTIFICATIONS_STATE,
    { type, payload }
) => {
    switch (type) {
        case notificationsTypes.GET_UNREAD_COUNT_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                data: null,
            };
        case notificationsTypes.GET_UNREAD_COUNT_SUCSES:
            return {
                ...state,
                loading: false,
                error: null,
                data: payload,
            };
        case notificationsTypes.GET_UNREAD_COUNT_ERORR:
            return {
                ...state,
                loading: false,
                error: payload,
                data: null,
            };
        default:
            return state;
    }
};

//mark all as read
export const markAllNotificationAssReadReducer = (
    state = INIT_NOTIFICATIONS_STATE,
    { type, payload }
) => {
    switch (type) {
        case notificationsTypes.MARK_ALL_AS_READ_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                data: null,
            };
        case notificationsTypes.MARK_ALL_AS_READ_SUCSES:
            return {
                ...state,
                loading: false,
                error: null,
                data: payload,
            };
        case notificationsTypes.MARK_ALL_AS_READ_ERORR:
            return {
                ...state,
                loading: false,
                error: payload,
                data: null,
            };
        default:
            return state;
    }
};

// mark one as read
export const markOneNotificationAssReadReducer = (
    state = INIT_NOTIFICATIONS_STATE,
    { type, payload }
) => {
    switch (type) {
        case notificationsTypes.MARK_ONE_AS_READ_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                data: null,
            };
        case notificationsTypes.MARK_ONE_AS_READ_SUCSES:
            return {
                ...state,
                loading: false,
                error: null,
                data: payload,
            };
        case notificationsTypes.MARK_ONE_AS_READ_ERORR:
            return {
                ...state,
                loading: false,
                error: payload,
                data: null,
            };
        default:
            return state;
    }
};
