import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSingleQuestion } from "../../../../../../../../store/actions";
import { useParams, useHistory } from "react-router-dom";
import View from "../../Repeated-Component/View";
import { useToast } from "@chakra-ui/react";

const ViewQuestion = ({ patient, getSingleQuestion, SingleQuestion }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Question, setQuestions] = useState(null);
  const { id, medicalId } = useParams();
  const toast = useToast();
  let history = useHistory();

  useEffect(() => {
    if (medicalId && patient) {
      getSingleQuestion(id, medicalId);
    }
  }, [getSingleQuestion]);

  useEffect(() => {
    if (SingleQuestion) {
      const { loading, error, data } = SingleQuestion;
      setLoading(loading);
      setError(error);
      if (data) {
        setQuestions(data);
      }
    }
  }, [SingleQuestion]);
  const renderView = () => {
    return (
      <View
        loading={loading}
        error={error}
        headerText="View Question"
        one={Question}
        history={history}
        question={true}
      />
    );
  };
  return renderView();
};

const mapStateToProps = (state) => {
  return {
    SingleQuestion: state.SingleQuestion,
  };
};
export default connect(mapStateToProps, { getSingleQuestion })(ViewQuestion);
