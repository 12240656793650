import React, { useEffect, useState } from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  getConsultantProfile,
  getQualificationsList,
  getSchedulesList,
} from "../../store/actions";
import MyInfo from "./My_info/myInfo";
import ProfileSideNav from "./sideNave/ProfileSideNav";
import ChangePassword from "./Change-Password/changePassword";
import "./profile.scss";
import { Box, Button, Text, useColorMode } from "@chakra-ui/react";
import NotFound from "../NotFound/NotFound";
import { checkBgColorMode, checkColorMode } from "../global/changeColor";
const Profile = ({
  getConsultantProfile,
  consultantProfile,
  getQualificationsList,
  QualificationsList,
  getSchedulesList,
  SchedulesList,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [qualifications, setQualifications] = useState([]);
  const [Schedules, setSchedules] = useState([]);
  const [openProfileNav, setOpenProfileNav] = useState(false);
  const { colorMode } = useColorMode();
  useEffect(() => {
    getConsultantProfile();
    getQualificationsList();
    getSchedulesList();
  }, [getConsultantProfile, getQualificationsList, getSchedulesList]);

  useEffect(() => {
    const { loading, error, data } = consultantProfile;
    setError(error);
    setLoading(loading);
    if (data) {
      setUser(data);
    }
  }, [consultantProfile]);

  useEffect(() => {
    const { data } = QualificationsList;

    if (data) {
      setQualifications(data);
    }
  }, [QualificationsList]);

  useEffect(() => {
    const { data } = SchedulesList;

    if (data) {
      setSchedules(data);
    }
  }, [SchedulesList]);

  const { path } = useRouteMatch();
  const renderView = () => {
    return (
      <div className="container profile pb-5">
        <div className="row position-relative">
          <div className="col-12 mb-3 d-flex align-items-center mt-5">
            <div className="d-xl-none d-lg-none d-xl-flex me-2">
              <Button
                color={checkColorMode(colorMode)}
                onClick={() => {
                  setOpenProfileNav(!openProfileNav);
                }}
              >
                <span>
                  <i className="fas fa-bars"></i>
                </span>
              </Button>
            </div>
            <Text fontWeight="semibold" fontSize="3xl" className="">
              My Account
            </Text>
          </div>
          <div className="col-12 row align-items-start m-0">
            <Box
              bg={checkBgColorMode(colorMode)}
              className="d-none d-lg-block col-3 rounded shadow-sm p-2 d-flex justify-content-center flex-wrap"
            >
              <ProfileSideNav />
            </Box>
            <Box
              w="260px"
              zIndex={200}
              top={0}
              bottom={0}
              left={openProfileNav ? "-24px" : "-1100px"}
              transition=".3s ease-in-out"
              className={`position-fixed ${
                openProfileNav ? "" : "rounded"
              } shadow-sm p-2 d-flex justify-content-center flex-wrap `}
              bg={checkBgColorMode(colorMode)}
            >
              <ProfileSideNav
                fromSmallScreen={true}
                openProfileNav={openProfileNav}
                setOpenProfileNav={setOpenProfileNav}
              />
            </Box>

            <div className="col-lg-9 ">
              <Switch>
                <Route
                  exact
                  path={`${path}`}
                  render={() => (
                    <MyInfo
                      loading={loading}
                      error={error}
                      consultantProfile={user}
                      qualifications={qualifications}
                      schedules={Schedules}
                    />
                  )}
                />
                <Route
                  path={`/profile/change_password`}
                  component={ChangePassword}
                />
                <Route path="*" exact={true} component={NotFound} />
              </Switch>
            </div>
          </div>
        </div>
        <Box
          className="position-absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg={"rgba(0,0,0,.2)"}
          display={openProfileNav ? "block" : "none"}
          onClick={() => {
            setOpenProfileNav(!openProfileNav);
          }}
        ></Box>
      </div>
    );
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    consultantProfile: state.consultant,
    QualificationsList: state.QualificationsList,
    SchedulesList: state.SchedulesList,
  };
};
export default connect(mapStateToProps, {
  getConsultantProfile,
  getQualificationsList,
  getSchedulesList,
})(Profile);
