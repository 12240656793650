import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { Box, Button, useToast, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import isAuthenticated from "../../utils/isAuthenticated";
import "./signUp.scss";
import * as Yup from "yup";

import { connect } from "react-redux";
import {
  getConsultationsTyeps,
  patientTypeList,
  signupUser,
  updateConsultantProfile,
} from "../../store/actions";

import moment from "moment";
// import AppointmentFieldArray from "./views/appointmentFieldArray";
import MyInfo from "./views/myInfo";
import MyAddress from "./views/myAddress";
import AboutMe from "./views/aboutMe";
import Qualifications from "./views/qualifications";

const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "Only alphabetic characters allowed"
    )
    .min(3, "must be at least 3 characters")
    .required("Required"),
  email: Yup.string().email().min(3).required("Required"),
  password: Yup.string().min(8).required("Required"),
  mobile: Yup.string().min(3).required("Required"),
  gmcNumber: Yup.string()
    .min(3, "must be at least 3 characters")
    .required("Required"),
  yearsOfExperienceAsConsultant: Yup.string().required("Required"),
  currentEmployer: Yup.string()
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "Only alphabetic characters allowed"
    )
    .min(3)
    .required("Required"),
  jobTitle: Yup.string()
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "Only alphabetic characters allowed"
    )
    .min(3)
    .required("Required"),
  patientTypeId: Yup.string().required("Required"),
  consultationTypes: Yup.string().required("Required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  gender: Yup.string().required("Required"),
  dateOfBirth: Yup.string().nullable().required("Required"),
  address: Yup.object().shape({
    country: Yup.string()
      .min(3, "must be at least 3 characters")
      .required("Required"),
    city: Yup.string()
      .min(3, "must be at least 3 characters")
      .required("Required"),
    street: Yup.string()
      .min(3, "must be at least 3 characters")
      .required("Required"),
    postel_code: Yup.string()
      .min(3, "must be at least 3 characters")
      .required("Required"),
  }),
  qualifications: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Required"),
      body: Yup.string().required("Required"),
    })
  ),
  // schedules: Yup.array().of(
  //   Yup.object().shape({
  //     from_time: Yup.string().nullable().required("Required"),
  //     to_time: Yup.string().nullable().required("Required"),
  //     day: Yup.string().required("Required"),
  //   })
  // ),
});
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const SignUp = ({
  getConsultationsTyeps,
  patientTypeList,
  patientTypes,
  ConsultationTypes,
  signupUser,
  updateConsultantProfile,
  updateProfile,
  registerUser,
}) => {
  const toast = useToast();
  let queryEdit = useQuery().get("edit");
  const history = useHistory();
  const [consultationTypesList, setConsultationTypesList] = useState([]);
  const [PatientTypes, setPatientTypes] = useState([]);

  const [initValue, setInitValue] = useState({
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    mobile: "",
    dateOfBirth: null,
    gender: "",
    gmcNumber: "",
    yearsOfExperienceAsConsultant: "",
    currentEmployer: "",
    jobTitle: "",
    patientTypeId: "",
    address: {
      country: "",
      city: "",
      street: "",
      postel_code: "",
    },
    consultationTypes: "",
    qualifications: [{ title: "", body: "" }],
    // schedules: [
    //   {
    //     from_time: null,
    //     to_time: null,
    //     day: "",
    //   },
    // ],
  });

  useEffect(() => {
    const prevValues = JSON.parse(localStorage.getItem("register"));
    if (prevValues) {
      setInitValue({
        ...prevValues,
      });
    }
  }, []);

  useEffect(() => {
    getConsultationsTyeps();
    patientTypeList();
  }, []);

  useEffect(() => {
    setPatientTypes(patientTypes);
    setConsultationTypesList(ConsultationTypes);
  }, [patientTypes, ConsultationTypes]);

  useEffect(() => {
    if (isAuthenticated()) {
      toast({
        title: "You are alredy logged in",
        status: "success",
        duration: 7000,
        isClosable: true,
      });
      history.push("/");
    }
  }, []);

  const convertTimeZone = (date) => {
    if (date) {
      const timeZon = moment(date).format("Z").replace(":", "");
      const time = moment(date).format("HH:mm:ss");
      const timeAndZone = `${time} ${timeZon}`;
      return timeAndZone;
    } else {
      return null;
    }
  };

  const onFormSubmit = (value) => {
    localStorage.setItem("register", JSON.stringify(value));

    // const times = JSON.parse(localStorage.getItem("times"));

    const consultationList = [];
    const userInfo = { ...value };
    const phoneNm = userInfo.mobile.replace(/\s/g, "");
    const tms = moment(userInfo.dateOfBirth).format("X");
    // if (times) {
    //   userInfo.schedules = times;
    // }
    if (userInfo.consultationTypes) {
      consultationList.push(Number(userInfo.consultationTypes));
    }
    // userInfo.schedules.map((item) => {
    //   if (item.from_time && item.to_time) {
    //     item.from_time = convertTimeZone(item.from_time);
    //     item.to_time = convertTimeZone(item.to_time);
    //   } else {
    //     item.from_time = null;
    //     item.to_time = null;
    //   }
    // });
    delete userInfo.passwordConfirmation;
    userInfo.dateOfBirth = Number(tms);
    userInfo.mobile = phoneNm;
    userInfo.consultationTypes = consultationList;
    userInfo.patientTypeId = Number(userInfo.patientTypeId);

    //send Data
    if (queryEdit) {
      updateConsultantProfile(userInfo, history, toast, "/verify_mobile");
    } else {
      signupUser(userInfo, history, toast);
    }
  };
  return (
    <Box className="container mt-5 pb-5">
      <Box className="row justify-content-center">
        <Box className="col-md-8">
          <Text className="mb-3" fontSize="15px" fontWeight="semibold">
            {" "}
            New Account
          </Text>
          <Formik
            initialValues={initValue}
            onSubmit={onFormSubmit}
            enableReinitialize
            validationSchema={SignUpSchema}
          >
            {({ values, setFieldValue, handleChange, handleBlur }) => (
              <Form className="">
                {/* MyInfo */}
                <MyInfo values={values} setFieldValue={setFieldValue} />

                {/* My Address */}
                <MyAddress
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />

                {/*  About Me*/}
                <AboutMe
                  PatientTypes={PatientTypes}
                  consultationTypesList={consultationTypesList}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
                {queryEdit ? (
                  ""
                ) : (
                  <>
                    {/* Qualifications */}
                    <Qualifications values={values} />

                    {/* Appointment */}
                    {/* <Box className="bg-white rounded shadow-sm p-3 row m-0 py-4 my-3">
                      <AppointmentFieldArray
                        values={values}
                        name={"schedules"}
                        setFieldValue={setFieldValue}
                      />
                    </Box> */}
                  </>
                )}

                <Box className="col-12 row justify-content-center m-0">
                  <Box className="col-md-6">
                    <Button
                      size={"lg"}
                      w={"100%"}
                      type="submit"
                      className="m-1"
                      bg="primary"
                      color="white"
                      _hover={{ bg: "primary_hover" }}
                      isLoading={registerUser.loading || updateProfile.loading}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>

                <Box className="col-12 row justify-content-center m-0">
                  <Box className="col-md-6">
                    <Button
                      size={"lg"}
                      w={"100%"}
                      as={Link}
                      to="/login"
                      type="button"
                      className="m-1"
                      bg="secondary"
                      color="white"
                      _hover={{ bg: "secondary_hover", color: "white" }}
                    >
                      Have account? Login
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    patientTypes: state.patientTypes.data,
    ConsultationTypes: state.consultationTypes.data,
    registerUser: state.signup,
    updateProfile: state.updateProfile,
  };
};
export default connect(mapStateToProps, {
  getConsultationsTyeps,
  patientTypeList,
  signupUser,
  updateConsultantProfile,
})(SignUp);
