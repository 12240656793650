import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EmptyMedicalReport from "../../../../../../assets/svg-icons/EmptyMedicalReport.svg";

import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import UploadComponent from "./uploadFiles/inputUpload";
import { Button, Text, Box, useToast, useColorMode } from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import { style } from "../../../../../global/style";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../../global/changeColor";
import { useHistory, useParams } from "react-router-dom";
import {
  addMedicalReport,
  getSingleConsultations,
} from "../../../../../../store/actions";
import {
  pageTransition,
  pageVariants,
} from "../../../../../global/Animations/Animations";
import { motion } from "framer-motion";
import { TextEditor, prepareDraft } from "./TextEditor";
const Schema = Yup.object().shape({
  note: Yup.string()
    .min(12, "Note must be at least 4 characters")
    .required("Required"),
  title: Yup.string()
    .min(4, "Title must be at least 4 characters")
    .required("Required"),
  files: Yup.mixed(),
});
const AddReport = ({
  addMedicalReport,
  addedReport,
  getSingleConsultations,
}) => {
  const { colorMode } = useColorMode();
  const { id } = useParams();
  const toast = useToast();
  const history = useHistory();
  const [File, setFile] = useState({});
  const [toDraft, setToDraft] = useState(null);
  const [initValue, setInitValue] = useState({
    note: "",
    title: "",
    files: null,
    consultationId: id,
  });
  const seeFiles = (file) => {
    if (file) {
      setFile(file[0]);
    }
  };
  const onFormSubmit = (value) => {
    addMedicalReport(value, history, toast, getSingleConsultations, id);
    getSingleConsultations(id);
    history.push(`/consultation/${id}/medical-report`);
  };

  const localStorageHtml = localStorage.reportHtml;
  useEffect(() => {
    if (localStorageHtml) {
      const toDraft = prepareDraft(localStorageHtml);
      setToDraft(toDraft);
    }
  }, [localStorageHtml]);

  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="row justify-content-center"
    >
      <div className="col-12 d-flex py-4 align-items-center">
        <Button
          onClick={() => history.goBack()}
          className="me-3 bg-light-gray rounded-circle d-flex align-items-center justify-content-center"
          style={{ width: "45px", height: "45px" }}
          color={style.secondary}
        >
          <i className="fas fa-chevron-left"></i>
        </Button>
        <Text
          fontSize={{ base: "18px", md: "2xl", lg: "2xl" }}
          fontWeight="semibold"
          color={style.secondary}
        >
          Add New Medical Report
        </Text>
      </div>

      <div className="form row justify-content-center">
        <Formik
          initialValues={initValue}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={onFormSubmit}
        >
          {({ values, setFieldValue, handleBlur, handleChange }) => (
            <Form>
              <Box
                bg={checkBgColorMode(colorMode)}
                className="col-12 p-4 rounded shadow  justify-content-center"
              >
                <div className="row justify-content-center">
                  <div className="w-100 d-flex justify-content-center mt-5">
                    <img src={EmptyMedicalReport} alt="logo" />
                  </div>

                  <div className="w-100 text-center py-5">
                    <Text fontSize={"2xl"} fontWeight="bold" className="mt-5">
                      Medical Report
                    </Text>
                    <Text
                      fontSize={"sm"}
                      className="mt-1"
                      color={style.smallTextGray}
                    >
                      Add your Medical Report
                    </Text>
                  </div>
                </div>

                <div className="w-100 row m-0 justify-content-center">
                  <div className="col-md-10 col-12">
                    <div className=" mt-2 ">
                      <InputControl
                        label="Title"
                        type="text"
                        name="title"
                        className="rounded"
                        inputProps={{
                          placeholder: "Give a title to your medical report",
                        }}
                      />
                    </div>

                    <Box className="mt-3">
                      <label
                        htmlFor="title"
                        id="field-44-label"
                        className="chakra-form__label css-2gx1h6"
                        data-invalid=""
                      >
                        Note
                      </label>
                      <TextEditor
                        setFieldValue={(val) => setFieldValue("note", val)}
                        value={values.note}
                        toDraft={toDraft}
                        name="note"
                      />
                      <Box
                        marginTop="3.75rem"
                        color={style.error}
                        fontSize="14px"
                      >
                        <ErrorMessage name="note" component={"div"} />
                      </Box>
                    </Box>
                  </div>
                </div>

                <Box
                  className="w-100 row  justify-content-center flex-wrap"
                  ml="0"
                  mt={["190px", "50px"]}
                >
                  <Text
                    className="col-md-10 col-12 fw-bold my-2"
                    color="gray.500"
                    fontSize="sm"
                  >
                    Add More Files
                  </Text>
                  <div className="col-md-10 col-12">
                    <UploadComponent
                      setFieldValue={setFieldValue}
                      name="files"
                      currntFiles={seeFiles}
                    />
                  </div>

                  <ErrorMessage
                    name="files"
                    component="div"
                    className="col-md-10 col-12"
                    style={{ color: "#d42c51", fontSize: "14px" }}
                  />
                </Box>

                <div className=" w-100 row m-0 justify-content-center">
                  <Box className="list-group col-md-9 col-12 p-0">
                    {File && File.name ? (
                      <Box
                        className="list-group-item my-2 bg-light-gray  rounded   d-flex justify-content-between align-items-center py-1"
                        style={{
                          borderRadius: "10px !important",
                        }}
                        lineHeight="4"
                        border="2px solid gray.300 "
                      >
                        <div className="fs-6 fw-bold d-flex  align-items-center">
                          <Box
                            color={style.primary}
                            fontSize={"20px"}
                            className="me-3"
                          >
                            <i className="fa-solid fa-image"></i>
                          </Box>
                          <div>
                            <Box fontSize={"14px"}>{File.name}</Box>
                            {File.size ? (
                              <span
                                className="text-secondary"
                                style={{ fontSize: "11px" }}
                              >
                                {File.size} Kb
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div>
                          <Button
                            onClick={() => {
                              seeFiles({});
                              values.files = "";
                            }}
                            background="none"
                            border="none"
                            color="red.400"
                            _focus={{
                              border: "none",
                              background: "none",
                            }}
                            _active={{ background: "red.100" }}
                            _hover={{ background: "red.50" }}
                          >
                            <i className="fas fa-times"></i>
                          </Button>
                        </div>
                      </Box>
                    ) : null}
                  </Box>
                </div>
              </Box>

              <div className="col-12 row justify-content-between m-0 mt-3 p-0">
                <div className="col-md-3 p-0 my-2">
                  <Button
                    onClick={() => history.goBack()}
                    colorScheme="red"
                    variant="outline"
                    w="100%"
                    size="lg"
                    fontWeight="semibold"
                    h="56px"
                    _hover={{ color: "red" }}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-md-3 p-0 my-2">
                  <Button
                    type="submit"
                    className="rounded"
                    fontWeight="semibold"
                    size="lg"
                    isLoading={addedReport.loading}
                    bg={checkColorMode(colorMode)}
                    color="#fff"
                    _hover={{ bg: checkColorModeHover(colorMode) }}
                    w="100%"
                    h="56px"
                  >
                    Save{" "}
                    <span className="ms-4">
                      <i className="fas fa-save"></i>
                    </span>
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};
const mapStateToProps = (state) => {
  return {
    addedReport: state.addedReport,
  };
};
export default connect(mapStateToProps, {
  addMedicalReport,
  getSingleConsultations,
})(AddReport);
