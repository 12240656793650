import axios from "axios";
import moment from "moment";
import { types } from "../types/types";
import { handeleError, handeleSuccess } from "./Toast/toast";

const consultantTypes = types.consultantTypes;
const API_URL = process.env.REACT_APP_API_URL;

// const convertTimeZone = (date) => {
//   if (date && date.includes("T")) {
//     const timeZon = moment(date).format("Z").replace(":", "");
//     const time = moment(date).format("HH:mm:ss");
//     const timeAndZone = `${time} ${timeZon}`;
//     return timeAndZone;
//   } else {
//     return date;
//   }
// };

export const getConsultantProfile = () => {
  return async (dispatch) => {
    dispatch({
      type: consultantTypes.GET_PROFILE_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultant/profile`,
      });
      dispatch({
        type: consultantTypes.GET_PROFILE_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: consultantTypes.GET_PROFILE_ERORR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const updateConsultantProfile = (
  data,
  history,
  toast,
  goTO,
  setEdite,
  getConsultantProfile
) => {
  return async (dispatch) => {
    dispatch({
      type: consultantTypes.UPDATE_PROFILE_LOADING,
    });
    try {
      const res = await axios({
        method: "PATCH",
        url: `${API_URL}/consultant/profile`,
        data: data,
      });
      if (res) {
        handeleSuccess("Your profile has been updated successfully", toast);
        if (goTO) history.push(goTO);
        if (setEdite) setEdite(false);
        if (getConsultantProfile) getConsultantProfile();
        dispatch({
          type: consultantTypes.UPDATE_PROFILE_SUCSES,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: consultantTypes.UPDATE_PROFILE_ERROR,
        payload: error.response,
      });
    }
  };
};

export const postConsultantProfilePicture = (data, toast) => {
  let formData = new FormData();
  if (data) {
    data.map((file) => formData.append("picture", file, file.name));
  }
  return async (dispatch) => {
    dispatch({
      type: consultantTypes.PROFILE_PICTURE_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/profile-picture`,
        data: formData,
      });
      handeleSuccess(
        "Your Profile Picture has been updated sucsessfuly",
        toast
      );
      dispatch({
        type: consultantTypes.PROFILE_PICTURE_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: consultantTypes.PROFILE_PICTURE_RORR,
        payload: error.response,
      });
    }
  };
};

export const changePassword = (data, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: consultantTypes.CHANGE_PASSWORD_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/change-password`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your password updated successfully", toast);
        history.push("/profile");
        dispatch({
          type: consultantTypes.CHANGE_PASSWORD_SUCSES,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: consultantTypes.CHANGE_PASSWORD_ERORR,
        payload: error.response,
      });
    }
  };
};

export const getQualificationsList = () => {
  return async (dispatch) => {
    dispatch({
      type: consultantTypes.GET_QUALIFICATIONS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultant/qualifications`,
      });
      dispatch({
        type: consultantTypes.GET_QUALIFICATIONS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: consultantTypes.GET_QUALIFICATIONS_ERORR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const getUserConsultations = () => {
  return async (dispatch) => {
    console.log("dispatching GET_CONSULTATIONS_LOADING");
    dispatch({
      type: consultantTypes.GET_CONSULTATIONS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultant/consultation-request`,
      });
      dispatch({
        type: consultantTypes.GET_CONSULTATIONS_SUCSES,
        payload: res.data?.data,
      });
    } catch (error) {
      dispatch({
        type: consultantTypes.GET_CONSULTATIONS_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
export const addQualification = (
  data,
  history,
  toast,
  goTO,
  setEdite,
  getQualificationsList
) => {
  return async (dispatch) => {
    dispatch({
      type: consultantTypes.ADD_QUALIFICATIONS_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/qualifications`,
        data: data,
      });
      if (res) {
        handeleSuccess(
          "Your Qualifications has been added successfully",
          toast
        );
        if (goTO) history.push(goTO);
        if (setEdite) setEdite(false);
        getQualificationsList();
        dispatch({
          type: consultantTypes.ADD_QUALIFICATIONS_SUCSES,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: consultantTypes.ADD_QUALIFICATIONS_ERORR,
        payload: error.response,
      });
    }
  };
};

export const updateQualification = (
  data,
  history,
  toast,
  goTO,
  setEdite,
  getQualificationsList,
  id
) => {
  return async (dispatch) => {
    dispatch({
      type: consultantTypes.UPDATE_QUALIFICATIONS_LOADING,
    });
    try {
      const res = await axios({
        method: "PATCH",
        url: `${API_URL}/consultant/qualifications/${id}`,
        data: data,
      });
      if (res) {
        handeleSuccess(
          "Your Qualification has been updated successfully",
          toast
        );
        if (goTO) history.push(goTO);
        if (setEdite) setEdite(false);
        getQualificationsList();
        dispatch({
          type: consultantTypes.UPDATE_QUALIFICATIONS_SUCSES,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: consultantTypes.UPDATE_QUALIFICATIONS_ERORR,
        payload: error.response,
      });
    }
  };
};

export const getSchedulesList = () => {
  return async (dispatch) => {
    dispatch({
      type: consultantTypes.GET_SCHEDULES_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultant/schedules`,
      });
      dispatch({
        type: consultantTypes.GET_SCHEDULES_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: consultantTypes.GET_SCHEDULES_ERORR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const updateSchedules = (
  history,
  toast,
  goTO,
  setEdite,
  getQualificationsList
) => {
  return async (dispatch) => {
    dispatch({
      type: consultantTypes.UPDATE_SCHEDULES_LOADING,
    });
    try {
      const times = JSON.parse(localStorage.getItem("renderTimes"));
      const deletedTimes = JSON.parse(localStorage.getItem("deletedItems"));
      const finalSend = [...times, ...deletedTimes];
      if (times) {
        finalSend.map(
          (item) => (item.from_time = moment(item.from_time).format("X"))
        );
        const res = await axios({
          method: "PATCH",
          url: `${API_URL}/consultant/schedules`,
          data: {
            schedules: finalSend,
          },
        });
        if (res) {
          localStorage.removeItem("deletedItems");
          localStorage.removeItem("renderTimes");
          handeleSuccess("Your schedule has been updated successfully", toast);
          if (goTO) history.push(goTO);
          if (setEdite) setEdite(false);
          getQualificationsList();
          dispatch({
            type: consultantTypes.UPDATE_SCHEDULES_SUCSES,
            payload: res.data,
          });
        }
      }
    } catch (error) {
      if (error.response.data.errors) {
        console.log(error.response.data.errors);
      } else {
        handeleError(error, toast);
      }
      dispatch({
        type: consultantTypes.UPDATE_SCHEDULES_ERORR,
        payload: error.response,
      });
    }
  };
};
