import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import {
  getSingleConsultations,
  getQuestionList,
  getConsultationsTyeps,
} from "../../store/actions";
import OverViewSideNav from "./overViewSideNav";
import { pageTransition, pageVariants } from "../global/Animations/Animations";
import OverView from "./OverView";

import { Box, useToast, Button, useColorMode } from "@chakra-ui/react";
import OverViewChild from "./overViewChild/overViewChild";
import { checkBgColorMode, checkColorMode } from "../global/changeColor";

const ConsultationOverView = ({
  getSingleConsultations,
  SingleConsultation,
  getConsultationsTyeps,
  consultationTypes,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [consultation, setConsultation] = useState();
  const [ConsultationType, setConsultationType] = useState([]);
  const [scanRequests, setScanRequests] = useState([]);
  const [appointment, setAppointment] = useState(null);
  const [appointmentEndTime, setAppointmentEndTime] = useState(null);
  const [medicalReports, setMedicalReports] = useState(null);
  const [readings, setReadings] = useState(null);
  const [patient, setPatient] = useState(null);
  const [smallSideNav, setSmallSideNav] = useState(false);
  const [overLay, setOverLay] = useState(true);
  const [patientHospitalReservation, setPatientHospitalReservation] =
    useState(null);

  let { id } = useParams();
  const { path } = useRouteMatch();
  const { colorMode } = useColorMode();
  const loaction = useLocation();
  let history = useHistory();
  let toast = useToast();
  const closeSideNave = (someData) => {
    setOverLay(!overLay);
    setSmallSideNav(!smallSideNav);
  };
  useEffect(() => {
    if (id) {
      getSingleConsultations(id, toast, history);
      getConsultationsTyeps();
    }
  }, [id]);
  ///
  useEffect(() => {
    if (consultationTypes && consultationTypes.data) {
      setConsultationType(consultationTypes.data);
    }
  }, [consultationTypes]);
  ////
  useEffect(() => {
    if (SingleConsultation) {
      const { loading, error, data } = SingleConsultation;
      setLoading(loading);
      setError(error);
      if (data) {
        setConsultation(data);
        const {
          ConsultantAppointment,
          appointmentEndTime,
          medicalReports,
          patientHospitalReservation,
          readings,
          scanRequests,
          patient,
        } = data;
        setAppointment(ConsultantAppointment);
        setAppointmentEndTime(appointmentEndTime);
        setMedicalReports(medicalReports);
        setPatientHospitalReservation(patientHospitalReservation);
        setReadings(readings);
        setScanRequests(scanRequests);
        setPatient(patient);
      }
    }
  }, [SingleConsultation]);

  const renderView = () => {
    if (loading) {
      return " ";
    }
    if (error) {
      return "Error";
    }
    if (consultation) {
      return (
        <div className="row">
          <Box
            bg={checkBgColorMode(colorMode)}
            className="d-none d-lg-block p-0 m-0 col-2"
          >
            <OverViewSideNav
              requestes={scanRequests}
              appointment={appointment}
            />
          </Box>
          <div className="d-xl-none d-lg-none d-xl-block mt-4">
            <Button
              color={checkColorMode(colorMode)}
              onClick={() => {
                setOverLay(!overLay);
                setSmallSideNav(!smallSideNav);
              }}
            >
              <i className="fas fa-bars"></i>
            </Button>
          </div>
          <Box className="col-lg-10 row flex-wrap m-0 align-items-start main-overView pb-5">
            <AnimatePresence exitBeforeEnter>
              <Switch location={loaction} key={loaction.pathname}>
                <Route
                  exact
                  path={path}
                  render={() => (
                    <OverView
                      Consultation={consultation}
                      ConsultationType={ConsultationType}
                      history={history}
                    />
                  )}
                />

                <Route
                  path={`${path}/:child`}
                  render={() => (
                    <OverViewChild
                      patient={patient}
                      Consultation={consultation}
                      requestes={scanRequests}
                      appointment={appointment}
                      appointmentEndTime={appointmentEndTime}
                      medicalReports={medicalReports}
                      readings={readings}
                      patientHospitalReservation={patientHospitalReservation}
                      history={history}
                    />
                  )}
                />
              </Switch>
            </AnimatePresence>
          </Box>
        </div>
      );
    } else {
      return "";
    }
  };
  return (
    <motion.div
      className="container-fluid "
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div
        className="overLay position-fixed "
        style={{
          left: 0,
          right: 0,
          top: 0,
          height: "100%",
          backgroundColor: "rgba(0,0,0,.2)",
          zIndex: "20",
          display: overLay ? "none" : "block",
        }}
        onClick={() => {
          closeSideNave();
        }}
      ></div>
      <div className="position-relative">
        {renderView()}
        <div
          className="position-fixed  p-0 m-0 small-side-nave"
          style={{
            top: 0,
            left: smallSideNav ? "-12px" : "-340px",
            zIndex: "30",
            bottom: 0,
          }}
        >
          <OverViewSideNav
            requestes={scanRequests}
            appointment={appointment}
            closeSideNave={closeSideNave}
          />
        </div>
      </div>
    </motion.div>
  );
};
const mapStateToProps = (state) => {
  return {
    SingleConsultation: state.SingleConsultation,
    consultationTypes: state.consultationTypes,
  };
};
export default connect(mapStateToProps, {
  getSingleConsultations,
  getQuestionList,
  getConsultationsTyeps,
})(ConsultationOverView);
