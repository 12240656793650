import React, { useState } from "react";
import { connect } from "react-redux";
import ques from "../../../../../../../../assets/medical-record/requests/ques.jpg";
import { useParams, useHistory } from "react-router-dom";

import {
  getSingleConsultations,
  requestQuestion,
  getQuestionList,
} from "../../../../../../../../store/actions";

import AddRequest from "../../Repeated-Component/request";

import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";

const Schema = Yup.object().shape({
  title: Yup.string().min(4).required("Required"),
  body: Yup.string().min(4).required("Required"),
});

const RequestQuestions = ({
  requestQuestion,
  RequestQuestion,
  getSingleConsultations,
  getQuestionList,
}) => {
  const history = useHistory();
  const toast = useToast();
  let { id } = useParams();

  const [initValue] = useState({
    title: "",
    body: "",
  });

  const onFormSubmit = (value) => {
    requestQuestion(
      id,
      value,
      history,
      toast,
      getSingleConsultations,
      getQuestionList
    );
  };
  return (
    <AddRequest
      Schema={Schema}
      initValue={initValue}
      history={history}
      onFormSubmit={onFormSubmit}
      headerText={`Ask Questions`}
      secondHeaderText="Questions"
      therdHeaderText="Ask Questions"
      image={ques}
      feildsNames={{
        feild1: {
          name: "title",
          type: "text",
          placeholder: "Title",
        },
        feild2: {
          name: "body",
          type: "text",
          placeholder: "Body",
        },
      }}
      btnLoading={RequestQuestion.loading}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    RequestQuestion: state.RequestQuestion,
  };
};
export default connect(mapStateToProps, {
  requestQuestion,
  getSingleConsultations,
  getQuestionList,
})(RequestQuestions);
