import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  InputGroup,
  Input,
  InputLeftElement,
  Text,
  Avatar,
  useToast,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  // Circle,
  useColorMode,
} from "@chakra-ui/react";
import moment from "moment";
import { motion } from "framer-motion";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { getReports } from "../../store/actions";
import Pagination from "../Pagination/Pagination";
import { pageTransition, pageVariants } from "../global/Animations/Animations";
import { check_status_string } from "../NewConsultations/NewConsulttationList";
import {
  checkBgColorMode,
  checkColorMode,
  checkWhiteAndBlackColor,
} from "../global/changeColor";
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ReportsList = ({ history, getReports, ReportsList }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [reports, setReports] = useState([]);
  const [filterReports, setFilterReports] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  const { colorMode } = useColorMode();
  let { url } = useRouteMatch();
  let query = useQuery().get("page");
  let toast = useToast();

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    getReports(query, toast);
  }, [query, getReports, toast]);

  useEffect(() => {
    const { loading, error, data } = ReportsList;
    setLoading(loading);
    setError(error);
    if (data && data.data) {
      const list = data.data;
      setReports(list);
      setFilterReports(list);
      if (data.meta) {
        setTotalResults(data.meta.total);
        setCuttentPage(data.meta.current_page);
      } else {
        setTotalResults(0);
      }
    }
  }, [ReportsList]);

  const listForFiltering = [
    { stutus: "medical-report-added", text: "Medical report uploaded" },
    { stutus: "has_appointment", text: "Appointment booked" },
    { stutus: "finished_appointment", text: "Appointment passed" },
    { stutus: "admin-contacted", text: "HK call" },
    { stutus: "done", text: "Done" },
  ];

  const filterStatus = (status) => {
    if (status) {
      const assignedData = reports.filter(
        (element) => element.status === status
      );
      setFilterReports(assignedData);
    }
  };

  const renderView = () => {
    if (loading) {
      return "";
    }
    if (error) {
      return <Box>{ReportsList.error ? ReportsList.error.message : ""}</Box>;
    }
    if (filterReports.length) {
      return (
        <>
          {filterReports.map((item, index) => (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              key={index}
              className="p-0"
            >
              <Box
                as={Link}
                to={`/consultation/${item.id}/medical-report`}
                className="m-0 mb-2 shadow-sm row align-items-center p-3 pe-0 rounded "
                bg={checkBgColorMode(colorMode)}
                _hover={{ color: checkWhiteAndBlackColor(colorMode) }}
              >
                {item.patient ? (
                  <div className="col-md-5  row align-items-center  my-2">
                    <div className="col-lg-2 col-md-3 col-sm-2 col-3">
                      <Avatar
                        style={{ width: "42px", height: "42px" }}
                        bg={checkColorMode(colorMode)}
                        size="md"
                        src={item.patient.picture}
                        name={`${item.patient.firstName}`}
                        color="#FFF"
                      />
                    </div>
                    <Box className="col-9 p-1">
                      <Text color="gray.400" fontSize="15px" fontWeight="600">
                        Patient
                      </Text>
                      <Text fontSize="13px" fontWeight="700">
                        {`${item.patient.fullName} `}
                      </Text>
                    </Box>
                  </div>
                ) : null}

                <div className="col-md-5 my-2">
                  <Text color="gray.400" fontSize="15px" fontWeight="600">
                    {"Date/Time"}
                  </Text>
                  <Text fontSize="13px" fontWeight="700" className="mt-2">
                    {moment.unix(item.createdAt).format("DD/MM/YYYY - hh:mm A")}
                  </Text>
                </div>

                <div className="col-md-2 col-9 my-2">
                  <div
                    className={`${
                      check_status_string(item.statusString, colorMode)
                        .className
                    } text-center rounded`}
                  >
                    {item.statusString}
                  </div>
                </div>
              </Box>
            </motion.div>
          ))}
          <Pagination
            pages={pagesNumber}
            nextPage={nextPage}
            prevPage={prevPage}
            specificPage={specificPage}
            currentPage={currentPage}
          />
        </>
      );
    } else {
      return "No Reports";
    }
  };

  return (
    <motion.div
      className="container"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="row ">
        <div className="h4 p-0 py-3 fw-bold d-flex flex-wrap align-items-center justify-content-between">
          <Text fontSize="2xl" className="my-4" fontWeight="semibold">
            Reports
          </Text>

          <div className="d-flex flex-wrap align-items-center">
            <div className="input-search me-2 my-2">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={
                    <Text
                      className="icon-search"
                      color={checkColorMode(colorMode)}
                    >
                      <i className="fas fa-search"></i>
                    </Text>
                  }
                />
                <Input
                  w="300px"
                  placeholder="Patient Name"
                  size="lg"
                  fontSize={"14px"}
                  className="ps-5"
                  bg={checkBgColorMode(colorMode)}
                  onChange={(e) => {
                    let term = e.target.value;
                    if (term.trim().length > 0) {
                      const filterD = reports.filter((one) =>
                        one.patient.fullName
                          .toLowerCase()
                          .includes(term.trim().toLowerCase())
                      );
                      setFilterReports(filterD);
                    } else {
                      setFilterReports(reports);
                    }
                  }}
                />
              </InputGroup>
            </div>
            <div className="my-2">
              <Menu>
                <MenuButton
                  as={Button}
                  color={checkColorMode(colorMode)}
                  bg={
                    colorMode === "dark" ? "secondaryOpacity" : "primaryOpacity"
                  }
                  _hover={{
                    bg:
                      colorMode === "dark"
                        ? "secondary_hover_Opacity"
                        : "primaryOpacity_hover",
                  }}
                  _active={{
                    bg:
                      colorMode === "dark"
                        ? "secondary_hover_Opacity"
                        : "primaryOpacity_hover",
                  }}
                  size="lg"
                >
                  <span className="me-2">
                    <i className="fas fa-filter"></i>
                  </span>{" "}
                  Filter
                </MenuButton>
                <MenuList className="shadow p-2 " minW={170}>
                  {listForFiltering.map((item, index) => (
                    <MenuItem
                      key={index}
                      p={2}
                      fontSize="17px"
                      className="text-center rounded"
                      color={checkColorMode(colorMode)}
                      _hover={{
                        backgroundColor:
                          colorMode === "dark"
                            ? "secondary_hover_Opacity"
                            : "primaryOpacity_hover",
                      }}
                      onClick={() => filterStatus(item.stutus)}
                    >
                      <Text fontWeight="semibold" w={"100%"}>
                        {item.text}
                      </Text>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>

        {renderView()}
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    ReportsList: state.ReportsList,
  };
};

export default connect(mapStateToProps, { getReports })(ReportsList);
