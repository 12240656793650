import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { NavLink, useHistory, Link } from "react-router-dom";
import { logIn } from "../../store/actions";
import { InputControl } from "formik-chakra-ui";
import PasswordField from "../global/passwordField";
import { Box, Button, useColorMode, useToast, Text } from "@chakra-ui/react";
import isAuthenticated from "../../utils/isAuthenticated";

import logo from "../../assets/logo.svg";
import darkLogo from "../../assets/darkLogo.svg";

import "./Login.scss";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../global/changeColor";

const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6).required("Required"),
});

const Login = ({ logIn, user }) => {
  const toast = useToast();
  const history = useHistory();
  const { colorMode } = useColorMode();
  const [initValue, setInitValue] = useState({
    email: "",
    password: "",
  });

  const onFormSubmit = async (value) => {
    logIn(value.email, value.password, history, toast);
  };

  useEffect(() => {
    if (isAuthenticated()) {
      toast({
        title: "You're already logged in",
        status: "success",
        duration: 7000,
        isClosable: true,
        position: "top-right",
      });
      history.push("/");
    }
  }, []);
  useEffect(() => {
    const loginEmail = localStorage.getItem("loginEmail");
    if (loginEmail) {
      setInitValue({
        ...initValue,
        email: loginEmail,
      });
    }
  }, []);

  return (
    <div className="row justify-content-center m-0 mt-5 pb-5">
      <div className="col-lg-5 col-md-7 col-12">
        <Formik
          initialValues={initValue}
          enableReinitialize
          validationSchema={SignInSchema}
          onSubmit={onFormSubmit}
        >
          <div>
            <p className="fw-bold mb-2">Login</p>
            <Form>
              <Box
                bg={checkBgColorMode(colorMode)}
                className="rounded shadow-sm p-5"
              >
                <div className="header row justify-content-center mb-5">
                  <img
                    src={colorMode === "dark" ? darkLogo : logo}
                    alt="logo"
                    className="w-75"
                  />
                </div>

                <div className="input-group mt-2">
                  <InputControl
                    name="email"
                    label="E-mail"
                    inputProps={{ placeholder: "E-mail" }}
                    size="lg"
                  />
                </div>

                <div className="input-group mt-2">
                  <PasswordField label="Password" />
                </div>

                <div className="buttonSubmit">
                  <Button
                    type="submit"
                    className="w-100 mt-3 fw-bold"
                    size="lg"
                    isLoading={user.loading}
                    bg={checkColorMode(colorMode)}
                    color="#fff"
                    _hover={{ bg: checkColorModeHover(colorMode) }}
                  >
                    Login
                  </Button>
                </div>

                <Text
                  as={NavLink}
                  to="/forgetpassword"
                  className="d-flex justify-content-center pt-4 fw-bold forget-password"
                  _hover={{ color: checkColorMode(colorMode) }}
                >
                  Forgot password?
                </Text>
              </Box>
            </Form>

            <div className="text-center  p-5 login-row">
              <Button
                as={Link}
                to="/signup"
                color="#fff"
                size="lg"
                bg="primary"
                _hover={{ bg: "primary_hover" }}
                className="w-100 nav-link text-white"
              >
                New Account? Register
              </Button>
            </div>
          </div>
        </Formik>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.login,
  };
};
export default connect(mapStateToProps, { logIn })(Login);
