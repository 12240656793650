import { Box, Text, color, useColorMode } from "@chakra-ui/react";
import { ErrorMessage } from "formik";
import { InputControl } from "formik-chakra-ui";
import React, { useEffect, useRef } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { checkBgColorMode } from "../../global/changeColor";
import NmberInput from "../../global/NmberInput";
const MyAddress = ({ values, handleChange, handleBlur, setFieldValue }) => {
  const { colorMode } = useColorMode();
  let numRef = useRef();
  useEffect(() => {
    if (numRef && numRef.current)
      numRef.current.addEventListener("keyup", (event) => {
        if (event.key === "e" || event.key === "E") {
          numRef.current.value = "";
        }
      });
  }, []);
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="rounded shadow-sm p-3 row m-0 py-4 my-3"
    >
      <Text
        className="mb-3"
        color="secondary"
        fontSize="15px"
        fontWeight="semibold"
      >
        My Address
      </Text>
      <div className="col-lg-6 mt-2">
        <label className="chakra-form__label mb-2" htmlFor="country">
          Country
        </label>
        <CountryDropdown
          classes={`${
            colorMode === "dark" ? "css-xpongc" : "css-1c6j008"
          } text-secondary mt-1 chakra-input`}
          name="address.country"
          defaultOptionLabel="Select a country"
          value={values.address.country}
          onChange={(_, e) => handleChange(e)}
          onBlur={(_, e) => handleBlur(e)}
        />

        <ErrorMessage
          name="address.country"
          component="div"
          className="text-danger"
          style={{ fontSize: "14px" }}
        />
      </div>
      <div className="col-lg-6 mt-2">
        <label className="chakra-form__label mb-2" htmlFor="country">
          City
        </label>
        <RegionDropdown
          classes={`${
            colorMode === "dark" ? "css-xpongc" : "css-1c6j008"
          } text-secondary mt-1 chakra-input`}
          name="address.city"
          blankOptionLabel="No country selected"
          defaultOptionLabel="Now select a City"
          country={values.address.country}
          value={values.address.city}
          onChange={(_, e) => handleChange(e)}
          onBlur={(_, e) => handleBlur(e)}
        />

        <ErrorMessage
          name="address.city"
          component="div"
          className="text-danger"
          style={{ fontSize: "14px" }}
        />
      </div>
      <div className="col-lg-6 mt-3">
        <InputControl
          name={"address.street"}
          label="Street"
          inputProps={{ placeholder: "Street" }}
        />
      </div>

      <div className="col-lg-6 mt-3">
        <NmberInput
          name="address.postel_code"
          label="Postal Code"
          placeholder="Postal Code"
        />
      </div>
    </Box>
  );
};

export default MyAddress;
