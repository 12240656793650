import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { style } from "../../../../../../global/style";
import moment from "moment";
import { Text, Box, useColorMode } from "@chakra-ui/react";
import {
  checkBgColorMode,
  checkColorMode,
} from "../../../../../../global/changeColor";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -2000, duration: 5, delay: 10 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000, duration: 5, delay: 3 },
    },
  },
};
const Card = ({ url, item }) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      as={motion.div}
      variants={variants}
      className="mb-2 shadow d-flex align-items-center rounded"
      bg={checkBgColorMode(colorMode)}
    >
      <Box as={Link} to={`${url}/${item.id}`} className="w-100 nav-link  p-3">
        <Text className="h5 fw-bold mb-0" color={checkColorMode(colorMode)}>
          {item.title ? item.title : item.value}
        </Text>
        <Text color={"gray.400"} fontSize="13px" className="mt-1">
          {moment
            .unix(item.updated_at || item.updatedAt)
            .format("DD/MM/YYYY hh:mm A")}
        </Text>
      </Box>
    </Box>
  );
};

export default Card;
