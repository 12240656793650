import { Box, Text, Button, useColorMode } from "@chakra-ui/react";
import { FieldArray } from "formik";
import { InputControl } from "formik-chakra-ui";
import React from "react";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../global/changeColor";
const Qualifications = ({ values }) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="rounded shadow-sm p-3 row m-0 py-4 my-3"
    >
      <FieldArray name="qualifications">
        {({ remove, push }) => (
          <>
            <Box className="d-flex flex-wrap align-items-center mb-3 justify-content-between">
              <Text
                className=""
                color="secondary"
                fontSize="15px"
                fontWeight="semibold"
              >
                Qualifications
              </Text>
              <div className="w-100 my-2 text-end">
                <Button
                  bg={checkColorMode(colorMode)}
                  color="white"
                  _hover={{ bg: checkColorModeHover(colorMode) }}
                  onClick={() => push({ title: "", body: "" })}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </div>
            </Box>
            {values.qualifications && values.qualifications.length
              ? values.qualifications.map((item, index) => (
                  <Box key={index} className="col-12 mt-3 row m-0 p-0 ">
                    <div className="col-lg-4 my-2">
                      <InputControl
                        name={`qualifications.${index}.title`}
                        label="Title"
                        inputProps={{ placeholder: "Title" }}
                      />
                    </div>
                    <div className="col-lg-7 my-2">
                      <InputControl
                        name={`qualifications.${index}.body`}
                        label="Body"
                        inputProps={{ placeholder: "Body" }}
                      />
                    </div>
                    {values.qualifications.length > 1 ? (
                      <div className="col-lg-1 col-12 text-end ps-2">
                        <Button
                          bg="error_Scheme"
                          color="error"
                          _hover={{
                            bg: "error_Scheme_hover",
                          }}
                          _active={{
                            bg: "error_Scheme",
                          }}
                          _focus={{
                            border: "none",
                          }}
                          onClick={() => remove(index)}
                        >
                          <i className="fas fa-times"></i>
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </Box>
                ))
              : ""}
          </>
        )}
      </FieldArray>
    </Box>
  );
};

export default Qualifications;
