import React, { useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  useToast,
  Box,
} from "@chakra-ui/react";
import moment from "moment";

const MeetingDetailsModal = ({
  checkMeetingTime,
  Consultation,
  meetingData,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box className="mt-2">
      <Button
        onClick={onOpen}
        color="white"
        bg="primary"
        _hover={{ bg: "primary_hover" }}
        disabled={!checkMeetingTime}
        width={["100%", "auto", "auto", "auto"]}
        className="py-4"
      >
        <p className="d-none d-sm-block">
          Don’t have a Microsoft Teams account?
        </p>
        <p className="d-block d-sm-none">
          Don’t have a Microsoft <br /> Teams account?
        </p>
      </Button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        autoFocus={false}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div className="p-4 d-flex justify-content-center align-items-center flex-wrap">
              <Box className="w-100 text-end">
                <Button color="error" onClick={() => onClose()}>
                  <i className="fas fa-times"></i>
                </Button>
              </Box>
              <Box
                className="w-100 mb-4 text-center fw-bold mt-3"
                color={"gray.400"}
                fontSize="xl"
              >
                “If you were asked to sign in when you join the meeting, Please
                use your Microsoft Teams account, If you don’t have an account
                please use the credentials below to sign in. “
              </Box>

              <Text className="w-100 mt-3" color={"gray.400"} fontSize="2xl">
                Microsoft Teams Account
              </Text>

              <Box className="w-100 d-flex align-items-center">
                <Text className="me-2" fontSize={"xl"} color={"secondary"}>
                  Username:{" "}
                </Text>
                <Text className="fw-bold" fontSize="">
                  {meetingData?.teamsAccount?.username}
                </Text>
              </Box>
              <Box className="w-100 d-flex align-items-center">
                <Text className="me-2" fontSize={"xl"} color={"secondary"}>
                  Password:{" "}
                </Text>
                <Text className="fw-bold" fontSize="">
                  {meetingData?.teamsAccount?.password}
                </Text>
              </Box>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default MeetingDetailsModal;
