import React from "react";
import { Box, Text, useColorMode } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../global/changeColor";
import emptyConsultationsSvg from "../../assets/svg-icons/empty-consultations.svg";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../global/Animations/Animations";

const EmptyActiveConsultations = () => {
  const { colorMode } = useColorMode();
  return (
    <motion.div
      className="container mt-5"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="row m-0 justify-content-center">
        <Box
          bg={checkBgColorMode(colorMode)}
          className="col-md-8 col-12 p-3 pb-5 rounded shadow m-0 "
        >
          <img
            className="mx-auto"
            width={250}
            src={emptyConsultationsSvg}
            alt="Empty Consultations"
          />

          <Text
            color={colorMode === "dark" ? "white" : "black"}
            fontWeight="bold"
            fontSize="xl"
            className="text-center mb-4"
          >
            There are no active consultations
          </Text>
          <Text
            color="smallTextGray"
            fontSize="sm"
            className="text-center mb-4"
          >
            There are no active consultations now, once you accept a
            consultation it will appear here.
          </Text>
          <Box className="col-12 text-center">
            <Button
              className="chakra-button rounded"
              w="60%"
              size="lg"
              bg={checkColorMode(colorMode)}
              color={"#fff"}
              _hover={{ color: "#fff", bg: checkColorModeHover(colorMode) }}
              as={Link}
              to={"/"}
            >
              View New Consultations
            </Button>
          </Box>
        </Box>
      </div>
    </motion.div>
  );
};

export default EmptyActiveConsultations;
