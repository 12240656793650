import React, { useState, useEffect } from "react";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useColorMode,
  Text,
} from "@chakra-ui/react";

import {
  gitNotificationList,
  markOneNotificationAsRead,
  getUnReadNotifications,
} from "../../store/actions";
import { connect } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { checkColorMode } from "../global/changeColor";
import { LinksAndImages } from "./helperImageAndLinkes";

const MenuComponent = ({
  item,
  query,
  gitNotificationList,
  markOneNotificationAsRead,
  getUnReadNotifications,
}) => {
  const [markAsRead, setMarkAsRead] = useState(false);
  const { url } = useRouteMatch();
  const { colorMode } = useColorMode();
  useEffect(() => {
    if (item.readAt) {
      setMarkAsRead(true);
    }
  }, [item]);

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton as={Button} variant="ghost">
            <i className="fas fa-ellipsis-h"></i>
          </MenuButton>
          <MenuList className="menu-condition shadow">
            <MenuItem
              isDisabled={markAsRead}
              onClick={() => {
                markOneNotificationAsRead(
                  item.id,
                  gitNotificationList,
                  query,
                  getUnReadNotifications
                );
              }}
            >
              <Link
                to={`${url}${query ? `?page=${query}` : ""}`}
                className="nav-link p-0 w-100 "
                style={{ cursor: markAsRead ? "not-allowed" : "pointer" }}
              >
                <span className="me-3 text-light-primary">
                  <i
                    className={markAsRead ? "fas fa-check" : "fas fa-question"}
                  ></i>
                </span>
                {markAsRead ? "Readed" : "Mark as read"}
              </Link>
            </MenuItem>
            <MenuItem>
              {LinksAndImages(item).anchor ? (
                <Link
                  to={LinksAndImages(item).link}
                  className="nav-link p-0 w-100 d-flex"
                >
                  <Text className="me-3 " color={checkColorMode(colorMode)}>
                    <i className="fas fa-eye"></i>
                  </Text>
                  View
                </Link>
              ) : (
                <a
                  href={LinksAndImages(item).link}
                  target={LinksAndImages(item).target || null}
                  className="nav-link p-0 w-100 text-dark"
                >
                  <span className="me-3 " color={checkColorMode(colorMode)}>
                    <i className="fas fa-eye"></i>
                  </span>
                  View
                </a>
              )}
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default connect(null, {
  markOneNotificationAsRead,
  gitNotificationList,
  getUnReadNotifications,
})(MenuComponent);
