import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  useToast,
  Textarea,
  useColorMode,
  Box,
} from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import { style } from "../../../../global/style";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  requestScan,
  getSingleConsultations,
} from "../../../../../store/actions";
import {
  checkColorMode,
  checkColorModeHover,
} from "../../../../global/changeColor";

const ReadingSchema = Yup.object().shape({
  title: Yup.string().required(),
  body: Yup.string().required(),
});

const ReadingModal = ({
  requestScan,
  getSingleConsultations,
  requestedSacn,
  feildName,
  name,
  img,
  icon,
  SingleConsultation,
}) => {
  let { id } = useParams();
  let toast = useToast();
  let { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [initValue] = useState({
    title: "",
    body: "",
    scan_type: `reading-${feildName}`,
    consultationId: id,
  });
  const onFormSubmit = (value) => {
    requestScan(value, toast, getSingleConsultations, id, onClose);
  };
  return (
    <div>
      <Button
        onClick={onOpen}
        color={style.white}
        bg={checkColorMode(colorMode)}
        _hover={{ bg: checkColorModeHover(colorMode) }}
        disabled={
          SingleConsultation?.status === "done" ||
          SingleConsultation?.status === "finished_appointment" ||
          SingleConsultation?.status === "new"
        }
        pointerEvents={
          SingleConsultation?.status === "done" ||
          SingleConsultation?.status === "finished_appointment" ||
          SingleConsultation?.status === "new"
            ? "none"
            : ""
        }
      >
        <span className="me-2">
          <i className="fas fa-plus"></i>
        </span>{" "}
        Request
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered autoFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div className="d-flex justify-content-center mt-5 flex-wrap">
              <Box
                className=" rounded-circle d-flex justify-content-center align-items-center text-white"
                style={{
                  width: "80px",
                  height: "80px",
                }}
                bg={checkColorMode(colorMode)}
              >
                <img src={img} alt={name} />
              </Box>

              <Text className="w-100 text-center my-2">Request {name}</Text>
              <Formik
                initialValues={initValue}
                enableReinitialize
                validationSchema={ReadingSchema}
                onSubmit={onFormSubmit}
              >
                {({ values, setFieldValue, handleBlur, handleChange }) => (
                  <Form className="w-75">
                    <div className=" my-4 mt-5 helperText">
                      <InputControl
                        size={"lg"}
                        className="w-100"
                        name="title"
                        inputProps={{
                          placeholder: `Title`,
                        }}
                      />
                    </div>
                    <div className=" my-2  helperText">
                      <Textarea
                        className="mt-3 rounded"
                        value={values.body}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                        name="body"
                        placeholder="Note"
                        size="sm"
                      />
                      <ErrorMessage
                        component="div"
                        name="body"
                        style={{ color: "#d42c51", fontSize: "14px" }}
                        className="mt-1"
                      />
                    </div>
                    <div className="my-3 d-flex justify-content-center">
                      <Button
                        w="35%"
                        type="submit"
                        color="#fff"
                        bg={checkColorMode(colorMode)}
                        _hover={{ bg: checkColorModeHover(colorMode) }}
                        size="lg"
                      >
                        Request
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    requestedSacn: state.requestedSacn,
  };
};
export default connect(mapStateToProps, {
  requestScan,
  getSingleConsultations,
})(ReadingModal);
