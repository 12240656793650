import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import FirstView from "../../Repeated-Component/FirstView";
import XrayImage from "../../../../../../../../assets/medical-record/requests/Xray.png";
import { getXrayList } from "../../../../../../../../store/actions";
import { useToast } from "@chakra-ui/react";
import { connect } from "react-redux";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const ListingXray = ({ patient, getXrayList, XrayList }) => {
  let query = useQuery().get("page");
  const toast = useToast();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Xray, setXray] = useState([]);
  const [filterXray, setFilterXray] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    getXrayList(toast, history, patient.id, query);
  }, [getXrayList, query]);

  useEffect(() => {
    if (XrayList) {
      const { loading, error, data } = XrayList;
      setLoading(loading);
      setError(error);
      if (data) {
        setXray(data.data);
        setFilterXray(data.data);
        setTotalResults(data.meta.total);
        setCuttentPage(data.meta.current_page);
      }
    }
  }, [XrayList]);

  return (
    <div>
      <FirstView
        loading={loading}
        error={error}
        headerName="X Ray"
        buttonLink={`${url}/request-x-ray`}
        cardLink={`${url}/view-x-ray`}
        data={Xray}
        filterData={filterXray}
        setFilter={setFilterXray}
        conditionImage={XrayImage}
        pages={pagesNumber}
        nextPage={nextPage}
        prevPage={prevPage}
        specificPage={specificPage}
        currentPage={currentPage}
        totalResults={totalResults}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    XrayList: state.XrayList,
  };
};
export default connect(mapStateToProps, { getXrayList })(ListingXray);
