import React from "react";
import { useParams } from "react-router-dom";

import NotFound from "../../NotFound/NotFound";
import Appointment from "./Children/Appointment/Appointment";
import MedicalRecord from "./Children/MedicalRecords/MedicalRecord";
import MedicalReport from "./Children/MedicalReport/MedicalReport";
import Readings from "./Children/Readings/Readings";
import Requests from "./Children/Requests/Requests";

const OverViewChild = ({
  Consultation,
  requestes,
  appointment,
  appointmentEndTime,
  medicalReports,
  readings,
  patientHospitalReservation,
  history,
  patient,
}) => {
  let { child } = useParams();
  const renderComponent = () => {
    switch (child) {
      case "requestes":
        return <Requests history={history} requestes={requestes} />;
      case "medical-report":
        return (
          <MedicalReport
            history={history}
            medicalReports={medicalReports}
            Consultation={Consultation}
          />
        );
      case "appointment":
        return (
          <Appointment
            Consultation={Consultation}
            history={history}
            appointment={appointment}
            patient={patient}
          />
        );
      case "readings":
        return (
          <Readings
            history={history}
            readings={readings}
            requestes={requestes}
          />
        );
      case "medical-record":
        return <MedicalRecord patient={patient} Consultation={Consultation} />;
      default:
        return <NotFound />;
    }
  };

  return <div>{renderComponent()}</div>;
};

export default OverViewChild;
