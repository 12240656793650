import React, { useEffect, useState } from "react";

import { Box, Button, Text, useColorMode, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateSchedules,
  getSchedulesList,
} from "../../../../../store/actions";
import { style } from "../../../../global/style";

import moment from "moment";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../global/changeColor";

import ProfileFieldArray from "./profileFieldArray";
const SubmitSchema = Yup.object().shape({
  schedules: Yup.array().of(
    Yup.object().shape({
      from_time: Yup.date()
        .min(new Date(), "Date can't be in the past")
        .required("Required")
        .nullable(),
    })
  ),
});

const converDate = (date) => {
  if (date) {
    const now = moment.unix(date).format();
    // const fullDate = `${now} ${date}`;
    // const final = moment(fullDate).format();
    return now;
  }
};

const AppointmentTime = ({ schedules, updateSchedules, getSchedulesList }) => {
  const toast = useToast();
  const history = useHistory();
  const { colorMode } = useColorMode();
  const [edite, setEdite] = useState(false);

  const [initValue, setInitValue] = useState({
    schedules: [{ id: null, from_time: null }],
  });

  const onFormSubmit = (value) => {
    localStorage.setItem("renderTimes", JSON.stringify(value.schedules));
    updateSchedules(history, toast, null, setEdite, getSchedulesList);
  };

  useEffect(() => {
    if (schedules && schedules.length) {
      schedules.map((item) => {
        item.from_time = converDate(item.from_time);
      });
      const notBookedAppointment = schedules.filter(
        (value) => !value.consultant_appointment_id
      );
      const bookedAppointment = schedules.filter(
        (value) => value.consultant_appointment_id
      );

      if (bookedAppointment.length) {
        localStorage.setItem(
          "bookedToReadOnly",
          JSON.stringify(bookedAppointment)
        );
      }
      setInitValue({
        ...initValue,
        schedules: notBookedAppointment,
      });
    }
  }, [schedules]);

  const renderView = () => {
    if (edite) {
      return (
        <Formik
          initialValues={initValue}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={SubmitSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ProfileFieldArray
                values={values}
                setFieldValue={setFieldValue}
                // deletedAppointments={setDeleted}
              />
              <div className="col-12 d-flex justify-content-between align-items-center mt-5">
                <div>
                  <Text fontSize="lg" color="secondary" className="fw-bold">
                    {" "}
                  </Text>
                </div>
                <div>
                  <Button
                    width={"50px"}
                    height="50px"
                    bg="#f3e9eb"
                    _hover={{ bg: "#f3d3da" }}
                    fontSize="20px"
                    color="#D42C51"
                    className="me-2"
                    onClick={() => setEdite(false)}
                    type="button"
                  >
                    <i className="fas fa-times"></i>
                  </Button>

                  <Button
                    width={"50px"}
                    height="50px"
                    bg="secondary"
                    _hover={{ bg: "secondary_hover" }}
                    fontSize="20px"
                    type="submit"
                    color="#fff"
                    // isLoading={updateProfile.loading}
                  >
                    <i className="fas fa-save"></i>
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    } else {
      return (
        <>
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Text fontSize="lg" color={"secondary"} className="fw-bold">
              {" "}
              Appointment Time
            </Text>
            <Button
              width={"50px"}
              height="50px"
              bg={checkColorMode(colorMode)}
              _hover={{ bg: checkColorModeHover(colorMode) }}
              fontSize="17px"
              color={"white"}
              onClick={() => {
                setEdite(true);
              }}
            >
              <i className="fas fa-pen"></i>
            </Button>
          </div>
          {schedules && schedules.length
            ? schedules.map((item, index) => (
                <div
                  key={index}
                  className="col-12 d-flex flex-wrap align-items-center"
                >
                  {/* <div className="col-md-4 col-12 p-0 mt-3">
                    <Text fontWeight="semibold" color={"smallTextGray"}>
                      Day
                    </Text>
                    <Text fontSize="sm" className="fw-primary">
                      {item.day}
                    </Text>
                  </div> */}
                  <div className="col-md-4  col-12 p-0 mt-3 ">
                    <Text fontWeight="semibold" color={"smallTextGray"}>
                      From Time
                    </Text>
                    <Text fontSize="sm" color={checkColorMode(colorMode)}>
                      {/* {moment(item.from_time)
                        .add(30, "minute")
                        .isSameOrAfter(moment())
                        ? "yes"
                        : "no"} */}
                      {moment(item.from_time).format("DD/MM/YYYY - hh:mm a")}
                    </Text>
                  </div>
                  {item.consultant_appointment_id ? (
                    <Box
                      className="rounded mt-3 p-1 px-2 text-success fw-bold"
                      bg={"rgba(34,187,51,.2)"}
                    >
                      <span className="me-1">
                        <i className="fa-solid fa-check"></i>
                      </span>{" "}
                      booked
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* <div className="col-md-4  col-12 p-0 mt-3 ">
                    <Text fontWeight="semibold" color={"smallTextGray"}>
                      To
                    </Text>
                    <Text fontSize="sm" className="fw-primary">
                      {moment(item.to_time).format("hh:mm a")}
                    </Text>
                  </div> */}
                </div>
              ))
            : ""}
        </>
      );
    }
  };

  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="MyInfo rounded shadow-sm p-4  row m-0 flex-wrap mb-4"
    >
      {renderView()}
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    updatedSchedule: state.updatedSchedule,
  };
};
export default connect(mapStateToProps, { updateSchedules, getSchedulesList })(
  AppointmentTime
);
