import axios from "axios";
import { types } from "../types/types";
// import { handeleError } from "./Toast/toast";

const notificationsTypes = types.notificationsTypes;
const API_URL = process.env.REACT_APP_API_URL;

// Notification List
export const gitNotificationList = (pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: notificationsTypes.GET_NOTIFICATION_LIST_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultant/notifications?page=${pageNumber}`,
      });
      if (res) {
        dispatch({
          type: notificationsTypes.GET_NOTIFICATION_LIST_SUCSES,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: notificationsTypes.GET_NOTIFICATION_LIST_ERORR,
        payload: err,
      });
    }
  };
};

// UNREAD COUNT
export const getUnReadNotifications = () => {
  return async (dispatch) => {
    dispatch({
      type: notificationsTypes.GET_UNREAD_COUNT_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultant/notifications/unread-count`,
      });
      dispatch({
        type: notificationsTypes.GET_UNREAD_COUNT_SUCSES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: notificationsTypes.GET_UNREAD_COUNT_ERORR,
        payload: err.response,
      });
    }
  };
};
/// MARK ALL AS READ

export const markAllNotificationsAsRead = (
  gitNotificationList,
  query,
  setMarkAll,
  getUnReadNotifications
) => {
  return async (dispatch) => {
    dispatch({
      type: notificationsTypes.MARK_ALL_AS_READ_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/notifications/mark-all-as-read`,
      });
      if (res) {
        if (gitNotificationList) gitNotificationList(query ? query : 1);
        if (setMarkAll) setMarkAll(false);
        if (getUnReadNotifications) {
          getUnReadNotifications();
        }
      }
      dispatch({
        type: notificationsTypes.MARK_ALL_AS_READ_LOADING,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: notificationsTypes.MARK_ALL_AS_READ_ERORR,
        payload: err.response,
      });
    }
  };
};
/// MARK ONE AS READ
export const markOneNotificationAsRead = (
  id,
  getAllNotifications,
  query,
  getUnReadNotifications
) => {
  return async (dispatch) => {
    dispatch({
      type: notificationsTypes.MARK_ONE_AS_READ_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/notifications/${id}/mark-as-read`,
      });
      if (res) {
        getAllNotifications(query);
        getUnReadNotifications();
      }
      dispatch({
        type: notificationsTypes.MARK_ONE_AS_READ_SUCSES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: notificationsTypes.MARK_ONE_AS_READ_ERORR,
        payload: err.response,
      });
    }
  };
};
