import axios from "axios";
import { types } from "../types/types";

const API_URL = process.env.REACT_APP_API_URL;

const commonTypes = types.commonTypes;

const authConfige = {
    "Content-Type": "application/json",
    "X-Api-Key": "du7lik773nspohl0",
};

export const getConsultationsTyeps = () => {
    return async (dispatch) => {
        dispatch({
            type: commonTypes.GET_CONSULTATION_TYPE_LOADING,
        });
        try {
            const res = await axios({
                method: "GET",
                url: `${API_URL}/consultation-type/`,
                headers: authConfige
            });
            dispatch({
                type: commonTypes.GET_CONSULTATION_TYPE_SUCSES,
                payload: res.data,
            });
        } catch (error) {
            dispatch({
                type: commonTypes.GET_CONSULTATION_TYPE_ERROR,
                payload: error.response ? error.response.data : error,
            });
        }
    };
};
export const patientTypeList = () => {
    return async (dispatch) => {
        dispatch({
            type: commonTypes.GET_PATIENT_TYPE_LOADING,
        });
        try {
            const res = await axios({
                method: "GET",
                url: `${API_URL}/patient-type/`,
                headers: authConfige,
            });
            dispatch({
                type: commonTypes.GET_PATIENT_TYPE_SUCSES,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: commonTypes.GET_PATIENT_TYPE_ERROR,
                payload: err,
            });
        }
    };
};