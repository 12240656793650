import axios from "axios";
import { types } from "../types/types";
import { handeleError } from "./Toast/toast";

const appointmentTypes = types.appointmentTypes;

const API_URL = process.env.REACT_APP_API_URL;

export const getAppointments = (pageNumber = 1, month) => {
  return async (dispatch) => {
    dispatch({
      type: appointmentTypes.GET_APPOINTMENTS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${
          month
            ? `${API_URL}/consultant/appointments?monthStartTime=${month}`
            : `${API_URL}/consultant/appointments?page=${pageNumber}`
        } `,
      });
      dispatch({
        type: appointmentTypes.GET_APPOINTMENTS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error);
      dispatch({
        type: appointmentTypes.GET_APPOINTMENTS_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
