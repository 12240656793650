import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch, useLocation, useHistory, Link } from "react-router-dom";
import {
  gitNotificationList,
  markAllNotificationsAsRead,
  getUnReadNotifications,
} from "../../store/actions";
import { LinksAndImages } from "./helperImageAndLinkes";
import { Circle, Box, Text, Image, useColorMode } from "@chakra-ui/react";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import MenuComponent from "./menu.Component";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../global/Animations/Animations";
import {
  checkColorMode,
  checkBgColorMode,
  checkWhiteAndBlackColor,
} from "../global/changeColor";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Notifications = ({
  gitNotificationList,
  markAllNotificationsAsRead,
  notificationsList,
  getUnReadNotifications,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Notifications, setNotifications] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  const [markAll, setMarkAll] = useState(false);

  const { url } = useRouteMatch();
  const { colorMode } = useColorMode();

  let query = useQuery().get("page");

  useEffect(() => {
    gitNotificationList(query);
  }, [query]);

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    const { loading, error, data } = notificationsList;
    setLoading(loading);
    setError(error);
    if (data && data.data) {
      const list = data.data;
      list.map((item) => {
        if (item.readAt == null) {
          setMarkAll(true);
        }
      });
      setNotifications(list);
      setTotalResults(data.meta.total);
      setCuttentPage(data.meta.current_page);
    }
  }, [notificationsList]);

  const renderView = () => {
    if (loading) {
      return "";
    }
    if (error) {
      return "error";
    }
    if (Notifications.length) {
      return Notifications.map((item, index) => (
        <Box
          as={motion.div}
          key={index}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{ duration: 0.6, delay: index * 0.1 }}
          className="col-12 row m-0 shadow-sm mb-2 p-4 rounded  align-items-center"
          bg={checkBgColorMode(colorMode)}
        >
          <Text
            as={Link}
            to={LinksAndImages(item).link}
            className="col-md-9 col-12 row m-0"
            _hover={{ color: checkWhiteAndBlackColor(colorMode) }}
          >
            <div className="col-md-7 col-12 d-flex align-items-center m-0 ps-0 my-2">
              <Circle
                size="40px"
                bg={checkColorMode(colorMode)}
                color="#fff"
                className="me-3"
              >
                {LinksAndImages(item).img ? (
                  <Image
                    maxW={"80%"}
                    maxH="70%"
                    className="image-fluid"
                    src={LinksAndImages(item).img}
                    alt={item.data.type ? item.data.type : "no"}
                  />
                ) : (
                  <i className={LinksAndImages(item).className}></i>
                )}
              </Circle>
              <Box className="">
                <Text color="smallTextGray" fontSize="15px" fontWeight="600">
                  Activity
                </Text>
                <Text fontSize="13px" fontWeight="700">
                  {item.data && item.data.title ? item.data.title : "null"}
                </Text>
              </Box>
            </div>
            <Box className="col-md-5 col-12 my-2">
              <Box className="">
                <Text color="smallTextGray" fontSize="15px" fontWeight="600">
                  Time
                </Text>
                <Text fontSize="13px" fontWeight="700">
                  {item.updatedAt
                    ? moment.unix(item.updatedAt).format("DD/MM/yyyy hh:mm a")
                    : "null"}
                </Text>
              </Box>
            </Box>
          </Text>

          <Box className="col-md-3 text-end">
            <MenuComponent
              item={item}
              LinksAndImages={LinksAndImages}
              query={query}
            />
          </Box>
        </Box>
      ));
    } else {
      return "";
    }
  };

  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="container pb-5"
    >
      <div className="row m-0">
        <Box className="col-12 row my-4 m-0 align-items-center">
          <Text fontSize="3xl" fontWeight="semibold" className="col-md-6">
            My Activity
          </Text>
          <Box className="col-md-6 row m-0 justify-content-end">
            <Text
              w="auto"
              fontSize="xl"
              fontWeight="semibold"
              color={checkColorMode(colorMode)}
              transition=".3s"
              _hover={{
                color: "primary_hover",
                bg: "primaryOpacity_hover",
              }}
              cursor={markAll ? "pointer" : "not-allowed"}
              className="rounded p-1 px-2"
              onClick={() => {
                markAllNotificationsAsRead(
                  gitNotificationList,
                  query,
                  setMarkAll,
                  getUnReadNotifications
                );
              }}
              pointerEvents={markAll ? "normal" : "none"}
              opacity={markAll ? 1 : 0.5}
            >
              <span className="me-3" style={{ color: "#30B8BA" }}>
                <i className={markAll ? "fas fa-question" : "fas fa-check"}></i>{" "}
              </span>
              Mark All As Read
            </Text>
          </Box>
        </Box>

        {renderView()}

        {totalResults > 15 && !loading ? (
          <Pagination
            pages={pagesNumber}
            nextPage={nextPage}
            prevPage={prevPage}
            specificPage={specificPage}
            currentPage={currentPage}
          />
        ) : (
          ""
        )}
      </div>
    </motion.div>
  );
};

const mapStateToprops = (state) => {
  return {
    notificationsList: state.NotificationList,
    unRead: state.unReadCount,
    markAll: state.markAll,
    markOne: state.markOne,
  };
};

export default connect(mapStateToprops, {
  gitNotificationList,
  markAllNotificationsAsRead,
  getUnReadNotifications,
})(Notifications);
