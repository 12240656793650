import heartRate from "../../../../../assets/ReadingsImage/heartRate.png";
import bloodPressure from "../../../../../assets/ReadingsImage/bloodPressure.png";
import bloodSugar from "../../../../../assets/ReadingsImage/bloodSugar.png";
import height from "../../../../../assets/ReadingsImage/height.png";
import temperature from "../../../../../assets/ReadingsImage/temperature.png";
import weight from "../../../../../assets/ReadingsImage/weight.png";

export const checkReaddingType = (text) => {
  if (text === "height") {
    return {
      text: "CM",
      img: height,
    };
  }
  if (text === "temperature") {
    return {
      text: "C",
      img: temperature,
    };
  }
  if (text === "weight") {
    return {
      text: "KG",
      img: weight,
    };
  }
  if (text === "blood-pressure") {
    return {
      text: "mmHg",
      img: bloodPressure,
    };
  }
  if (text === "heart-rate") {
    return {
      text: "BPM",
      img: heartRate,
    };
  }
  if (text === "blood-sugar") {
    return {
      text: "mg/dL",
      img: bloodSugar,
    };
  }
};
