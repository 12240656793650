import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./header.scss";
import logo from "../../assets/logo.svg";
import darkLogo from "../../assets/darkLogo.svg";
import {
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Divider,
  Text,
  Circle,
  Image,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import { checkBgColorMode, checkColorMode } from "../global/changeColor";
import { navList } from "./navList";
import { connect } from "react-redux";
import {
  getUnReadNotifications,
  gitNotificationList,
  logOut,
  markAllNotificationsAsRead,
} from "../../store/actions";
import { LinksAndImages } from "../Notifications/helperImageAndLinkes";
import DrawerComponent from "./Drawer";

import moment from "moment";

const Header = ({
  isAuth,
  setIsAuth,
  consultant,
  getUnReadNotifications,
  unReadCount,
  gitNotificationList,
  notificationList,
  markAllNotificationsAsRead,
  logOut,
}) => {
  const [doctorToken] = useState(localStorage.doctorToken);
  const [ConsultantProfile, setConsultantProfile] = useState();
  const [unReadNotificationCount, setUnReadNotificationCount] = useState();
  const [NotificationsList, setNotificationsList] = useState([]);
  const [menuOpen, setMenuOpen] = useState();
  const { colorMode, toggleColorMode } = useColorMode();

  const history = useHistory();
  useEffect(() => {
    if (doctorToken) {
      getUnReadNotifications();
      gitNotificationList();
    }
  }, [doctorToken, getUnReadNotifications, gitNotificationList]);

  useEffect(() => {
    if (consultant) {
      setConsultantProfile(consultant.data);
    }
  }, [consultant]);

  useEffect(() => {
    setUnReadNotificationCount(unReadCount);
    setNotificationsList(notificationList);
  }, [unReadCount, notificationList]);

  useEffect(() => {
    if (menuOpen) {
      markAllNotificationsAsRead(
        gitNotificationList,
        null,
        null,
        getUnReadNotifications
      );
    }
  }, [menuOpen, markAllNotificationsAsRead]);

  const navLinkes = () => {
    return (
      <>
        <div className="d-none d-lg-flex col-sm-10 col-12 row align-items-center justify-content-between m-0">
          <ul className="col-7 navbar-nav flex-row m-auto justify-content-around">
            <li className="nav-item ">
              <Text
                as={NavLink}
                exact
                to={"/"}
                activeClassName={
                  colorMode === "dark"
                    ? "header-links-active-dark"
                    : "header-links-active"
                }
                className={`m-1 header-links ${
                  colorMode === "dark" ? "header-links-dark" : ""
                }`}
                fontSize={{ base: "14px" }}
                fontWeight="semibold"
              >
                New Consultations
              </Text>
            </li>
            {navList.map((link, index) => (
              <li className="nav-item " key={index}>
                <Text
                  as={NavLink}
                  to={link.linkTo}
                  activeClassName={
                    colorMode === "dark"
                      ? "header-links-active-dark"
                      : "header-links-active"
                  }
                  className={`m-1 header-links ${
                    colorMode === "dark" ? "header-links-dark" : ""
                  }`}
                  fontSize={{ base: "14px" }}
                  fontWeight="semibold"
                >
                  {link.text}
                </Text>
              </li>
            ))}
          </ul>
          <div className="col-5 d-flex justify-content-center">
            <Menu>
              {({ isOpen }) => {
                if (isOpen) setMenuOpen(isOpen);
                return (
                  <>
                    <MenuButton
                      className="me-2"
                      as={Button}
                      variant="ghost"
                      color={"gray.500"}
                    >
                      <Box className="btnNotification">
                        <i className="far fa-bell"></i>

                        {unReadNotificationCount &&
                        unReadNotificationCount.count > 0 ? (
                          <span className="badge">
                            <Circle
                              color={"#fff"}
                              size={"17px"}
                              fontSize={"10px"}
                              bg="error"
                            >
                              {unReadNotificationCount.count}
                            </Circle>
                          </span>
                        ) : null}
                      </Box>
                    </MenuButton>

                    <MenuList
                      className="menu-notification  shadow rounded row m-0"
                      maxH="275"
                      maxW="275"
                      style={{ overflowY: "scroll" }}
                    >
                      {NotificationsList && NotificationsList.data
                        ? NotificationsList.data.map((notic, index) => (
                            <span key={index} className="p-0">
                              <MenuItem className="col-12 ">
                                {LinksAndImages(notic).anchor ? (
                                  <Link
                                    to={LinksAndImages(notic).link}
                                    className={`nav-link p-0 w-100 ${
                                      colorMode === "dark"
                                        ? "text-white"
                                        : "text-dark"
                                    }  d-flex align-items-center`}
                                  >
                                    <Circle
                                      size="35px"
                                      bg={checkColorMode(colorMode)}
                                      color="#fff"
                                      className="me-2"
                                    >
                                      {LinksAndImages(notic).img ? (
                                        <Image
                                          maxW={"80%"}
                                          maxH="70%"
                                          className="image-fluid"
                                          src={LinksAndImages(notic).img}
                                          alt={
                                            notic.data.type
                                              ? notic.data.type
                                              : "no"
                                          }
                                        />
                                      ) : (
                                        <i
                                          className={
                                            LinksAndImages(notic).className
                                          }
                                        ></i>
                                      )}
                                    </Circle>
                                    <div>
                                      <Text fontSize="sm" fontWeight="semibold">
                                        {notic.data.body}
                                      </Text>
                                      <Text
                                        color="smallTextGray"
                                        fontSize="11px"
                                      >
                                        {moment
                                          .unix(notic.updatedAt)
                                          .format("DD/MM/yyyy - hh:mm A")}
                                      </Text>
                                    </div>
                                  </Link>
                                ) : (
                                  <a
                                    href={LinksAndImages(notic).link}
                                    className="nav-link p-0 w-100 text-dark d-flex align-items-center"
                                    target={
                                      LinksAndImages(notic).target || null
                                    }
                                  >
                                    <Circle
                                      size="35px"
                                      bg={checkColorMode(colorMode)}
                                      color="#fff"
                                      className="me-2"
                                    >
                                      {LinksAndImages(notic).img ? (
                                        <Image
                                          maxW={"80%"}
                                          maxH="70%"
                                          className="image-fluid"
                                          src={LinksAndImages(notic).img}
                                          alt={
                                            notic.data.type
                                              ? notic.data.type
                                              : "no"
                                          }
                                        />
                                      ) : (
                                        <i
                                          className={
                                            LinksAndImages(notic).className
                                          }
                                        ></i>
                                      )}
                                    </Circle>
                                    <div>
                                      <Text fontSize="sm" fontWeight="semibold">
                                        {notic.data.body}
                                      </Text>
                                      <Text
                                        color="smallTextGray"
                                        fontSize="11px"
                                      >
                                        {moment
                                          .unix(notic.updatedAt)
                                          .format(
                                            "DD/MM/yyyy , yyyy - hh:mm A"
                                          )}
                                      </Text>
                                    </div>
                                  </a>
                                )}
                              </MenuItem>
                              <div className="col-12 px-3 py-1">
                                <Divider color="smallTextGrayLight" />
                              </div>
                            </span>
                          ))
                        : null}
                    </MenuList>
                  </>
                );
              }}
            </Menu>

            <Menu>
              {({ isOpen }) => {
                if (isOpen) setMenuOpen(isOpen);
                return (
                  <>
                    <MenuButton as={Button} variant="ghost">
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="me-1">
                          <Avatar
                            size="sm"
                            name={
                              ConsultantProfile ? ConsultantProfile.name : null
                            }
                            src={
                              ConsultantProfile
                                ? ConsultantProfile.picture
                                : null
                            }
                            color="#FFF"
                          />
                        </div>
                        <div className="ms-1 bg-d">
                          <Text
                            fontSize={{
                              base: "12px",
                              md: "13px",
                              lg: "14px",
                              xl: "16px",
                            }}
                          >
                            {ConsultantProfile ? ConsultantProfile.name : ""}
                          </Text>
                          <Text
                            fontSize="13px"
                            color="gray.400"
                            className="text-start userType"
                          >
                            {localStorage.getItem("userType")
                              ? localStorage.getItem("userType")
                              : ""}
                          </Text>
                        </div>
                      </div>
                    </MenuButton>
                    <MenuList className="menu-notification p-1 shadow">
                      <MenuItem className="d-flex flex-wrap" maxW="250px">
                        <div className="w-100">
                          <Avatar
                            size="md"
                            name={
                              ConsultantProfile ? ConsultantProfile.name : null
                            }
                            src={
                              ConsultantProfile
                                ? ConsultantProfile.picture
                                : null
                            }
                          />
                        </div>
                        <Text
                          fontWeight="semibold"
                          color="secondary"
                          className="w-100 my-3"
                        >
                          {ConsultantProfile ? ConsultantProfile.name : null}
                        </Text>
                      </MenuItem>

                      <MenuItem
                        as={Link}
                        to="/profile"
                        className="p-3 nav-link p-0 w-100  d-flex justify-content-between "
                      >
                        <div className="d-flex">
                          <Text className="me-3" color="#979797">
                            <i className="fa fa-user"></i>
                          </Text>
                          <Text
                            fontSize="15px"
                            className="me-1"
                            color="#979797"
                          >
                            My Info
                          </Text>
                        </div>
                        <Text color="#979797">
                          <i className="fas fa-chevron-right"></i>
                        </Text>
                      </MenuItem>

                      <MenuItem
                        as={Link}
                        to="/profile/change_password"
                        className="p-3 nav-link p-0 w-100  d-flex justify-content-between"
                      >
                        <div className="d-flex">
                          <Text className="me-3" color="#979797">
                            <i className="fa fa-unlock-alt"></i>
                          </Text>
                          <Text
                            fontSize="15px"
                            className="me-1"
                            color="#979797"
                          >
                            Change Password
                          </Text>
                        </div>
                        <Text color="#979797">
                          <i className="fas fa-chevron-right"></i>
                        </Text>
                      </MenuItem>

                      <MenuItem
                        className="p-3"
                        onClick={() => {
                          logOut(history);
                          setIsAuth(false);
                        }}
                      >
                        <Link
                          to="#"
                          className="nav-link p-0 w-100  d-flex justify-content-between"
                        >
                          <div className="d-flex">
                            <Text className="me-3" color="#979797">
                              <i className="fas fa-sign-out-alt"></i>{" "}
                            </Text>
                            <Text
                              fontSize="15px"
                              className="me-1"
                              color="#979797"
                            >
                              Logout
                            </Text>
                          </div>
                          <Text color="#979797">
                            <i className="fas fa-chevron-right"></i>
                          </Text>
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </>
                );
              }}
            </Menu>
            <Button
              className="ms-2"
              maxW="20px"
              onClick={() => toggleColorMode()}
            >
              {colorMode === "dark" ? (
                <span>
                  <i className="fas fa-moon"></i>
                </span>
              ) : (
                <span>
                  <i className="fas fa-moon"></i>
                </span>
              )}
            </Button>
          </div>
        </div>

        <div className="d-xl-none d-lg-none d-xl-flex col-5 p-0 text-end me-2 ">
          <Menu>
            {({ isOpen }) => {
              if (isOpen) setMenuOpen(isOpen);
              return (
                <>
                  <MenuButton
                    className="me-2"
                    as={Button}
                    variant="ghost"
                    color={"gray.500"}
                  >
                    <Box className="btnNotification">
                      <i className="far fa-bell"></i>

                      {unReadNotificationCount &&
                      unReadNotificationCount.count > 0 ? (
                        <span className="badge">
                          <Circle
                            color={"#fff"}
                            size={"17px"}
                            fontSize={"10px"}
                            bg="error"
                          >
                            {unReadNotificationCount.count}
                          </Circle>
                        </span>
                      ) : null}
                    </Box>
                  </MenuButton>

                  <MenuList
                    className="menu-notification  shadow rounded row m-0"
                    maxH="275"
                    maxW="275"
                    style={{ overflowY: "scroll" }}
                  >
                    {NotificationsList && NotificationsList.data
                      ? NotificationsList.data.map((notic, index) => (
                          <span key={index} className="p-0">
                            <MenuItem className="col-12 ">
                              {LinksAndImages(notic).anchor ? (
                                <Link
                                  to={LinksAndImages(notic).link}
                                  className={`nav-link p-0 w-100 ${
                                    colorMode === "dark"
                                      ? "text-white"
                                      : "text-dark"
                                  }  d-flex align-items-center`}
                                >
                                  <Circle
                                    size="35px"
                                    bg={checkColorMode(colorMode)}
                                    color="#fff"
                                    className="me-2"
                                  >
                                    {LinksAndImages(notic).img ? (
                                      <Image
                                        maxW={"80%"}
                                        maxH="70%"
                                        className="image-fluid"
                                        src={LinksAndImages(notic).img}
                                        alt={
                                          notic.data.type
                                            ? notic.data.type
                                            : "no"
                                        }
                                      />
                                    ) : (
                                      <i
                                        className={
                                          LinksAndImages(notic).className
                                        }
                                      ></i>
                                    )}
                                  </Circle>
                                  <div>
                                    <Text fontSize="sm" fontWeight="semibold">
                                      {notic.data.body}
                                    </Text>
                                    <Text color="smallTextGray" fontSize="11px">
                                      {moment
                                        .unix(notic.updatedAt)
                                        .format("DD/MM/yyyy - hh:mm A")}
                                    </Text>
                                  </div>
                                </Link>
                              ) : (
                                <a
                                  href={LinksAndImages(notic).link}
                                  className="nav-link p-0 w-100 text-dark d-flex align-items-center"
                                  target={LinksAndImages(notic).target || null}
                                >
                                  <Circle
                                    size="35px"
                                    bg={checkColorMode(colorMode)}
                                    color="#fff"
                                    className="me-2"
                                  >
                                    {LinksAndImages(notic).img ? (
                                      <Image
                                        maxW={"80%"}
                                        maxH="70%"
                                        className="image-fluid"
                                        src={LinksAndImages(notic).img}
                                        alt={
                                          notic.data.type
                                            ? notic.data.type
                                            : "no"
                                        }
                                      />
                                    ) : (
                                      <i
                                        className={
                                          LinksAndImages(notic).className
                                        }
                                      ></i>
                                    )}
                                  </Circle>
                                  <div>
                                    <Text fontSize="sm" fontWeight="semibold">
                                      {notic.data.body}
                                    </Text>
                                    <Text color="smallTextGray" fontSize="11px">
                                      {moment
                                        .unix(notic.updatedAt)
                                        .format("DD/MM/yyyy , yyyy - hh:mm A")}
                                    </Text>
                                  </div>
                                </a>
                              )}
                            </MenuItem>
                            <div className="col-12 px-3 py-1">
                              <Divider color="smallTextGrayLight" />
                            </div>
                          </span>
                        ))
                      : null}
                  </MenuList>
                </>
              );
            }}
          </Menu>
          <DrawerComponent setIsAuth={setIsAuth} />
        </div>
      </>
    );
  };

  const renderView = () => {
    if (isAuth) {
      return navLinkes();
    } else {
      return (
        <Button maxW="20px" onClick={() => toggleColorMode()}>
          {colorMode === "dark" ? (
            <span>
              <i className="fas fa-moon"></i>
            </span>
          ) : (
            <span>
              <i className="fas fa-moon"></i>
            </span>
          )}
        </Button>
      );
    }
  };

  return (
    <div className="container-fluid NavBar shadow p-0">
      <Box
        bg={checkBgColorMode(colorMode)}
        className="row justify-content-between align-items-center p-2 m-0"
      >
        <div className="col-lg-2 col-6 me-auto">
          <NavLink to="/" className="">
            <img src={colorMode === "dark" ? darkLogo : logo} alt="logo" />
          </NavLink>
        </div>
        <>{renderView()}</>
      </Box>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    consultant: state.consultant,
    unReadCount: state.unReadCount.data,
    notificationList: state.NotificationList.data,
  };
};
export default connect(mapStateToProps, {
  getUnReadNotifications,
  gitNotificationList,
  markAllNotificationsAsRead,
  logOut,
})(Header);
