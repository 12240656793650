import React from "react";
import {
  Text,
  Box,
  Circle,
  Button,
  useToast,
  Avatar,
  useColorMode,
} from "@chakra-ui/react";
import { checkBgColorMode, checkColorMode } from "../global/changeColor";
import chest from "../../assets/svg-icons/chest.svg";
import { motion } from "framer-motion";
import {
  getSingleConsultations,
  acceptNewConsultation,
  rejectNewConsultation,
} from "../../store/actions";
import { pageTransition, pageVariants } from "../global/Animations/Animations";
import moment from "moment";
import { connect } from "react-redux";
import { GetSpecialityIcon } from "../global/GetSpecialityIcon.js";

const OverView = ({
  Consultation,
  ConsultationType,
  getSingleConsultations,
  acceptNewConsultation,
  rejectNewConsultation,
  history,
}) => {
  let toast = useToast();
  const { colorMode } = useColorMode();
  return (
    <div className="col-12 row m-0 mt-4">
      <Box
        as={motion.div}
        initial="out"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className=" col-12 p-2 py-4 shadow-sm rounded row m-0"
        bg={checkBgColorMode(colorMode)}
      >
        <div className="col-12 p-2 px-3">
          <Text fontSize="2xl" color="secondary" fontWeight="bold">
            {Consultation.symptoms}
          </Text>
          <Text fontSize="xs" color="smallTextGray">
            {moment.unix(Consultation.createdAt).format("DD/MM/YYYY hh:mm A")}
          </Text>
        </div>
        <div className="col-12 row flex-wrap m-0 my-3 justify-content-between">
          <div className="col-lg-6 row m-0 p-0 my-2">
            <div className="col-6 d-flex align-items-center m-0 p-0">
              <Circle size="40px" bg={checkColorMode(colorMode)}>
                {Consultation.consultationTypeId ? (
                  <img
                    className="image-fluid"
                    src={GetSpecialityIcon(
                      Number(Consultation.consultationTypeId)
                    )}
                    alt="chest"
                  />
                ) : (
                  ""
                )}
              </Circle>

              <Box className="p-0 ms-2">
                <Text color="gray.400" fontSize="13px" fontWeight="600">
                  Speciality
                </Text>
                <Text fontSize="12px" fontWeight="700">
                  {ConsultationType.length
                    ? ConsultationType.find(
                        (element) =>
                          element.id === Consultation.consultationTypeId
                      ).name
                    : ""}
                </Text>
              </Box>
            </div>
          </div>

          <div className="col-lg-2 text-end p-0 my-2">
            <div className={`pending text-center rounded`}>
              {Consultation.statusString}
            </div>
          </div>
        </div>
        <div className="col-12 rounded">
          <Box
            boxShadow="xs"
            className="rounded p-3"
            bg="smallTextGrayLight"
            border={`1px solid`}
            borderColor="smallTextGrayLightBorder"
          >
            {Consultation.symptomsExplained}
          </Box>
        </div>
        {Consultation.status === "new" && (
          <div className="d-flex flex-row-reverse p-3">
            <Button
              className="ms-3"
              bg="error"
              color="white"
              _hover={{ opacity: 0.8 }}
              onClick={() => {
                rejectNewConsultation(Consultation.id, toast, {
                  getSingleConsultations,
                  history,
                });
              }}
            >
              <i className="fas fa-xmark"></i>
              <Text className="ms-3" color="white">
                Reject
              </Text>
            </Button>
            <Button
              bg={"secondary"}
              color={"white"}
              _hover={{ opacity: 0.8 }}
              onClick={() => {
                acceptNewConsultation(Consultation.id, toast, {
                  getSingleConsultations,
                });
              }}
            >
              <i className="fas fa-check"></i>
              <Text className="ms-3" color="white">
                Accept
              </Text>
            </Button>
          </div>
        )}
      </Box>

      {Consultation.patient ? (
        <Box
          as={motion.div}
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
          className="col-12 mt-3 p-2 py-3 shadow-sm rounded row m-0"
          bg={checkBgColorMode(colorMode)}
        >
          <div className="col-12 px-3">
            <Text fontSize="2xl" color="secondary" fontWeight="bold">
              Patient Info
            </Text>
          </div>
          <div className="col-12 row flex-wrap  m-0 my-3 justify-content-between">
            <div className="col-md-6 row m-0 p-0 my-2">
              <div className="col-12 d-flex align-items-center m-0 p-0">
                <Avatar
                  size="md"
                  name={
                    Consultation.patient ? Consultation.patient.fullName : null
                  }
                  src={
                    Consultation.patient ? Consultation.patient.picture : null
                  }
                />
                <Box className="p-0 ms-2">
                  <Text color="gray.400" fontSize="13px" fontWeight="600">
                    Patient
                  </Text>
                  <Text fontSize="12px" fontWeight="700">
                    {Consultation.patient.fullName}
                  </Text>
                </Box>
              </div>
            </div>
            {/* 
            
            // */}
            <div className="d-flex flex-wrap col-lg-5 col-md-6 m-0 text-end p-0 my-2 justify-content-between">
              <div className="d-flex text-start my-2">
                <Circle className="me-2" size="40px" bg="orange">
                  <i
                    style={{ color: "#FFF" }}
                    className="fas fa-thin fa-face-smile"
                  ></i>
                </Circle>
                <div>
                  <Text color="gray.400" fontSize="13px" fontWeight="600">
                    Gender
                  </Text>
                  <Text
                    fontSize="12px"
                    fontWeight="700"
                    textTransform="capitalize"
                  >
                    {Consultation.patient.gender}
                  </Text>
                </div>
              </div>

              <div className="d-flex text-start my-2">
                <Circle className="me-2" size="40px" bg={"secondary"}>
                  <i
                    style={{ color: "#FFF" }}
                    className="fas fa-thin fa-calendar"
                  ></i>
                </Circle>
                <div>
                  <Text color="gray.400" fontSize="13px" fontWeight="600">
                    Date of Birth
                  </Text>
                  <Text fontSize="12px" fontWeight="700">
                    {moment(Consultation.patient.dateOfBirth * 1000).format(
                      "LL"
                    )}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </Box>
      ) : null}
    </div>
  );
};

export default connect(null, {
  getSingleConsultations,
  acceptNewConsultation,
  rejectNewConsultation,
})(OverView);
