import React from "react";
import { Formik, Form, ErrorMessage } from "formik";

import { Button, Text, Box, useColorMode } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import { style } from "../../../../../../global/style";
import { motion } from "framer-motion";
import {
  pageVariants,
  pageTransition,
} from "../../../../../../global/Animations/Animations";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../../../global/changeColor";

const AddRequest = ({
  initValue,
  Schema,
  onFormSubmit,
  headerText,
  secondHeaderText,
  therdHeaderText,
  image,
  feildsNames,
  history,
  btnLoading,
}) => {
  const { colorMode } = useColorMode();
  return (
    <motion.div
      className="container"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="row justify-content-center">
        <div className="col-12 d-flex py-4 align-items-center">
          {history ? (
            <Button
              onClick={() => history.goBack()}
              className="nav-link h5 me-4 bg-light-gray rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: "45px", height: "45px" }}
              color={checkColorMode(colorMode)}
              _hover={{ color: checkColorModeHover(colorMode) }}
            >
              <i className="fas fa-chevron-left"></i>
            </Button>
          ) : null}

          <Text
            fontSize={{ base: "19px", md: "2xl", lg: "3xl" }}
            className="h4 fw-bold"
            color={checkColorMode(colorMode)}
          >
            {headerText}
          </Text>
        </div>

        <div className="">
          <div className="form row justify-content-center">
            <Formik
              initialValues={initValue}
              enableReinitialize
              validationSchema={Schema}
              onSubmit={onFormSubmit}
            >
              {({ values, setFieldValue, handleBlur, handleChange }) => (
                <Form>
                  <Box
                    bg={checkBgColorMode(colorMode)}
                    className="col-12 p-5  rounded shadow  justify-content-center"
                  >
                    <div className="row justify-content-center">
                      <div className="w-100 d-flex justify-content-center">
                        <img src={image} alt="logo" />
                      </div>

                      <div className="w-100 text-center py-5">
                        <Text
                          className="h3 fw-bold"
                          color={checkColorMode(colorMode)}
                        >
                          {" "}
                          {secondHeaderText}
                        </Text>
                        <Text className="fw-200 light-gray" color="gray.400">
                          {therdHeaderText}
                        </Text>
                      </div>
                    </div>

                    <div className="w-100 d-flex justify-content-center">
                      <div className="col-md-10 col-12">
                        <div className=" mt-2 ">
                          <InputControl
                            type={feildsNames.feild1.type}
                            name={feildsNames.feild1.name}
                            className="rounded"
                            inputProps={{
                              placeholder: feildsNames.feild1.placeholder,
                            }}
                          />
                        </div>
                        <div className="">
                          <Textarea
                            className="mt-3 rounded"
                            value={
                              values.note ? values.note : values.description
                            }
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleBlur(e)}
                            name={feildsNames.feild2.name}
                            placeholder={feildsNames.feild2.placeholder}
                            size="sm"
                          />
                          <ErrorMessage
                            component="div"
                            name={feildsNames.feild2.name}
                            style={{ color: "#d42c51", fontSize: "14px" }}
                            className="mt-1"
                          />
                        </div>
                      </div>
                    </div>
                  </Box>

                  <div className="col-12 m-0 mt-3  row justify-content-between">
                    <div className="col-md-3">
                      <Button
                        onClick={() => history.goBack()}
                        colorScheme="red"
                        variant="outline"
                        w="100%"
                        size="lg"
                        fontWeight="semibold"
                        h="56px"
                        _hover={{ color: "red" }}
                        className="my-2"
                      >
                        Cancel
                      </Button>
                    </div>

                    <div className="col-md-3">
                      <Button
                        type="submit"
                        className="rounded my-2"
                        fontWeight="semibold"
                        size="lg"
                        isLoading={btnLoading}
                        bg={checkColorMode(colorMode)}
                        color="#fff"
                        _hover={{ bg: checkColorModeHover(colorMode) }}
                        w="100%"
                        h="56px"
                      >
                        Save{" "}
                        <span className="ms-4">
                          <i className="fas fa-save"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AddRequest;
