import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Text,
  border,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateConsultantProfile,
  getConsultantProfile,
} from "../../../../../store/actions";
import { InputControl, SelectControl } from "formik-chakra-ui";
import TelephoneInput from "../../../../global/phoneNumber";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dateYYY_dd_mm } from "../../../../global/ConverDate";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../global/changeColor";

const SubmitSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  mobile: Yup.string().min(10).required("Required"),
  dateOfBirth: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
});

const MyInfoChild = ({
  consultantProfile,
  updateConsultantProfile,
  getConsultantProfile,
  updateProfile,
}) => {
  const toast = useToast();
  const history = useHistory();
  const { colorMode } = useColorMode();
  const [edite, setEdite] = useState(false);
  const [initValue, setInitValue] = useState({
    email: "",
    mobile: "",
    dateOfBirth: null,
    gender: "",
  });

  const onFormSubmit = (value) => {
    const dateStimp = new Date(value.dateOfBirth).getTime() / 1000;
    const data = {
      name: consultantProfile.name,
      gmcNumber: consultantProfile.gmcNumber,
      yearsOfExperienceAsConsultant:
        consultantProfile.yearsOfExperienceAsConsultant,
      currentEmployer: consultantProfile.currentEmployer,
      jobTitle: consultantProfile.jobTitle,
      patientTypeId: consultantProfile.patientTypeId,
      consultationTypes: consultantProfile.consultationTypes,
      address: consultantProfile.address,

      email: value.email,
      mobile: value.mobile,
      dateOfBirth: dateStimp,
      gender: value.gender,
    };
    updateConsultantProfile(
      data,
      history,
      toast,
      null,
      setEdite,
      getConsultantProfile
    );
  };
  useEffect(() => {
    if (consultantProfile) {
      setInitValue({
        ...initValue,
        email: consultantProfile.email,
        mobile: consultantProfile.mobile,
        dateOfBirth: dateYYY_dd_mm(consultantProfile.dateOfBirth),
        gender: consultantProfile.gender,
      });
    }
  }, [consultantProfile]);
  const renderView = () => {
    if (edite) {
      return (
        <Formik
          initialValues={initValue}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={SubmitSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                <div>
                  <Text fontSize="lg" color={"secondary"} fontWeight="semibold">
                    {" "}
                    My Info
                  </Text>
                </div>
                <div className="w-100 d-flex justify-content-end my-2">
                  <Button
                    width={"50px"}
                    height="50px"
                    bg="#f3e9eb"
                    _hover={{ bg: "#f3d3da" }}
                    fontSize="20px"
                    color="#D42C51"
                    className="me-2"
                    onClick={() => setEdite(false)}
                    type="button"
                  >
                    <i className="fas fa-times"></i>
                  </Button>

                  <Button
                    width={"50px"}
                    height="50px"
                    bg="secondary"
                    _hover={{ bg: "secondary_hover" }}
                    fontSize="20px"
                    type="submit"
                    color="#fff"
                    isLoading={updateProfile.loading}
                  >
                    <i className="fas fa-save"></i>
                  </Button>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-2">
                  <InputControl
                    name="email"
                    label="E-Mail"
                    inputProps={{ placeholder: "E-Mail" }}
                  />
                </div>
                <div className="col-md-6  ">
                  <div className="pt-2">
                    <TelephoneInput name="mobile" />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="text-danger errorMsg"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <SelectControl
                    label="Gender"
                    name="gender"
                    selectProps={{ placeholder: "Gender" }}
                    className="pt-3"
                  >
                    <option value="male">Male</option>
                    <option value="femail">Female</option>
                  </SelectControl>
                </div>

                <div className="col-md-6 mt-3">
                  <label htmlFor="dateOfBirth" className="chakra-form__label">
                    Date of Birth
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={`${
                        colorMode === "dark" ? "css-1ipn0iq" : "css-k7r2wc"
                      }  mt-2`}
                      autoOk={true}
                      label=""
                      format="dd/MM/yyyy"
                      views={["year", "month", "date"]}
                      value={values.dateOfBirth}
                      onChange={(value) => {
                        setFieldValue("dateOfBirth", value);
                      }}
                      variant="inline"
                      inputVariant="outlined"
                      size="small"
                      inputProps={{
                        placeholder: "Select Date",
                        style: {
                          color: colorMode === "dark" ? "#fff" : "#000",
                        },
                      }}
                      keyboardIcon={
                        <span
                          className={
                            colorMode === "dark"
                              ? "text-white"
                              : "text-secondary"
                          }
                        >
                          <i className="fas fa-calendar-week"></i>
                        </span>
                      }
                      disableFuture
                    />
                  </MuiPickersUtilsProvider>
                  <ErrorMessage
                    name="dateOfBirth"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "14px" }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    } else {
      return (
        <>
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Text fontSize="lg" color={"secondary"} fontWeight="semibold">
              {" "}
              My Info
            </Text>
            <Button
              width={"50px"}
              height="50px"
              bg={checkColorMode(colorMode)}
              _hover={{ bg: checkColorModeHover(colorMode) }}
              fontSize="17px"
              color="white"
              onClick={() => setEdite(true)}
            >
              <i className="fas fa-pen"></i>
            </Button>
          </div>

          <div className="col-12 d-flex flex-wrap">
            <div className="col-md-4 col-12 p-0 mt-3">
              <Text fontWeight="semibold" color={"smallTextGray"}>
                E-Mail
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {consultantProfile
                  ? `${consultantProfile.email.substr(0, 25)}`
                  : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0 mt-3">
              <Text fontWeight="semibold" color={"smallTextGray"}>
                Phone Number
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {consultantProfile ? consultantProfile.mobile : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0  mt-3">
              <Text fontWeight="semibold" color={"smallTextGray"}>
                Gender
              </Text>
              <Text
                fontSize="sm"
                color={checkColorMode(colorMode)}
                textTransform="capitalize"
              >
                {consultantProfile ? consultantProfile.gender : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0  mt-4">
              <Text fontWeight="semibold" color={"smallTextGray"}>
                Date of Birth
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {consultantProfile
                  ? moment
                      .unix(consultantProfile.dateOfBirth)
                      .format("DD/MM/YYYY")
                  : "null"}
              </Text>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="MyInfo rounded shadow-sm p-4  row m-0 flex-wrap mb-4"
    >
      {renderView()}
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    updateProfile: state.updateProfile,
  };
};
export default connect(mapStateToProps, {
  getConsultantProfile,
  updateConsultantProfile,
})(MyInfoChild);
