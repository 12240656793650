import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import NotFound from "../../../../../../NotFound/NotFound";
import ListingRadiology from "./Children/listingRadiology";
import RequestRadiology from "./Children/requestRadiology";
import ViewOneRadiology from "./Children/viewOneRadiology";

const Radiology = ({ patient }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <ListingRadiology patient={patient} />}
      />
      <Route
        exact
        path={`${path}/request-radiology`}
        component={RequestRadiology}
      />
      <Route
        path={`${path}/view-radiology/:medicalId`}
        render={() => <ViewOneRadiology patient={patient} />}
      />
      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};

export default Radiology;
