import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { Circle, Image, Text, useColorMode } from "@chakra-ui/react";
import { style } from "../../../../../../global/style";

import condition from "../../../../../../../assets/medical-record/condition.png";
import allergy from "../../../../../../../assets/medical-record/allergy.png";
import ctScan from "../../../../../../../assets/medical-record/ctScan.png";
import Egc from "../../../../../../../assets/medical-record/Egc.png";
import labResult from "../../../../../../../assets/medical-record/lab-result.png";
import medicalReport from "../../../../../../../assets/medical-record/medical-report.png";
import XRay from "../../../../../../../assets/medical-record/Xray.png";
import Question from "../../../../../../../assets/svg-icons/Question.svg";

export const navLinks = [
  {
    navImg: condition,
    navName: "Conditions",
    linkTo: "conditions",
  },
  {
    navImg: medicalReport,
    navName: "Medical Reports",
    linkTo: "medical-report",
  },
  {
    navImg: labResult,
    navName: "Lab Results",
    linkTo: "lab-result",
  },
  {
    navImg: allergy,
    navName: "Allergies",
    linkTo: "allergies",
  },
  {
    navImg: ctScan,
    navName: "MRI / CT Scan",
    linkTo: "mri-ct scan",
  },
  {
    navImg: Egc,
    navName: "ECG",
    linkTo: "ecg",
  },
  {
    navImg: XRay,
    navName: "X Ray",
    linkTo: "x-ray",
  },
];

const NavLinks = ({ consultation }) => {
  const { url } = useRouteMatch();
  const { colorMode } = useColorMode();
  return (
    <div className="col-12 ps-0 ">
      <ul className="navbar-nav flex-row flex-wrap mt-4">
        {navLinks.map((item, index) => (
          <li className="nav-item p-0" key={index}>
            <NavLink
              activeClassName={
                colorMode === "dark" ? "midical-active-dark" : "midical-active"
              }
              to={`${url}/${item.linkTo}`}
              className={`pt-4 pb-3 pe-1 midical-nav ${
                colorMode === "dark" ? "midical-nav-dark" : ""
              }`}
            >
              <Circle
                size="30px"
                color="#fff"
                className="me-2 circle"
                bg={style.smallTextGray}
                transition="0.2s ease-in-out"
              >
                {item.navImg ? (
                  <Image src={item.navImg} alt={item.navName} maxW="50%" />
                ) : (
                  <i className={item.navIcon}></i>
                )}
              </Circle>
              <Text>{item.navName}</Text>
            </NavLink>
          </li>
        ))}
        {consultation?.status === "new" ? null : (
          <li className="nav-item p-0">
            <NavLink
              activeClassName={
                colorMode === "dark" ? "midical-active-dark" : "midical-active"
              }
              to={`${url}/question`}
              className={`pt-4 pb-3 pe-1 midical-nav ${
                colorMode === "dark" ? "midical-nav-dark" : ""
              }`}
            >
              <Circle
                size="30px"
                color="#fff"
                className="me-2 circle"
                bg={style.smallTextGray}
                transition="0.2s ease-in-out"
              >
                <Image src={Question} alt={"Question"} maxW="50%" />
              </Circle>
              <Text>Question</Text>
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};

export default NavLinks;
