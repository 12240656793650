import React, { useState } from "react";
import { Box, Button, Text, useColorMode, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getConsultantProfile,
  getQualificationsList,
  addQualification,
} from "../../../../../store/actions";
import { InputControl } from "formik-chakra-ui";
import OneQualification from "./oneQualifications";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../global/changeColor";
const SubmitSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  body: Yup.string().required("Required"),
});

const Qualifications = ({
  qualifications,
  getQualificationsList,
  addQualification,
  addedQualifications,
}) => {
  const toast = useToast();
  const history = useHistory();
  const { colorMode } = useColorMode();
  const [edite, setEdite] = useState(false);
  const [initValue] = useState({
    title: "",
    body: "",
  });

  const onFormSubmit = (value) => {
    addQualification(
      value,
      history,
      toast,
      null,
      setEdite,
      getQualificationsList
    );
  };

  const renderView = () => {
    if (edite) {
      return (
        <Formik
          initialValues={initValue}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={SubmitSchema}
        >
          <Form>
            <div className="col-12 row m-0 p-0">
              <Box className="d-flex flex-wrap align-items-center m-0 p-0 mb-3 justify-content-between ">
                <Text fontSize="lg" color="secondary" fontWeight="semibold">
                  {" "}
                  Qualifications
                </Text>
                <div className="w-100 text-end my-2">
                  <Button
                    width={"50px"}
                    height="50px"
                    bg="#f3e9eb"
                    _hover={{ bg: "#f3d3da" }}
                    fontSize="20px"
                    color="#D42C51"
                    className="me-2"
                    onClick={() => setEdite(false)}
                    type="button"
                  >
                    <i className="fas fa-times"></i>
                  </Button>

                  <Button
                    width={"50px"}
                    height="50px"
                    bg="secondary"
                    _hover={{ bg: "secondary_hover" }}
                    fontSize="20px"
                    type="submit"
                    color="#fff"
                    isLoading={addedQualifications.loading}
                  >
                    <i className="fas fa-save"></i>
                  </Button>
                </div>
              </Box>

              <Box className="mt-3 row p-0 m-0">
                <div className="col-md-4 my-2">
                  <InputControl
                    name={`title`}
                    label="Title"
                    inputProps={{ placeholder: "Title" }}
                  />
                </div>
                <div className="col-md-7 my-2">
                  <InputControl
                    name={`body`}
                    label="Body"
                    inputProps={{ placeholder: "Body" }}
                  />
                </div>
              </Box>
            </div>
          </Form>
        </Formik>
      );
    } else {
      return (
        <>
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Text fontSize="lg" color={"secondary"} fontWeight="semibold">
              {" "}
              Qualifications
            </Text>
            <Button
              width={"50px"}
              height="50px"
              bg={checkColorMode(colorMode)}
              _hover={{ bg: checkColorModeHover(colorMode) }}
              fontSize="17px"
              color="white"
              onClick={() => setEdite(true)}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </div>

          {qualifications && qualifications.length
            ? qualifications.map((item, index) => (
                <div
                  key={index}
                  className="col-12 d-flex flex-wrap align-items-center"
                >
                  <OneQualification item={item} />
                </div>
              ))
            : ""}
        </>
      );
    }
  };
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="MyInfo rounded shadow-sm p-4  row m-0 flex-wrap mb-4"
    >
      {renderView()}
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    addedQualifications: state.addQualifications,
  };
};
export default connect(mapStateToProps, {
  getConsultantProfile,
  getQualificationsList,
  addQualification,
})(Qualifications);
