import React, { useEffect, useRef } from "react";
import {
  Text,
  Button,
  Box,
  Image,
  Circle,
  useColorMode,
} from "@chakra-ui/react";

import PDF from "../../../../../../../assets/svg-icons/pdf.svg";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import {
  pageVariants,
  pageTransition,
} from "../../../../../../global/Animations/Animations";
import { style } from "../../../../../../global/style";
import moment from "moment";
import { checkBgColorMode } from "../../../../../../global/changeColor";
const View = ({
  loading,
  error,
  headerText,
  one,
  history,
  viwer,
  question,
}) => {
  const { colorMode } = useColorMode();
  const reportRef = useRef();
  useEffect(() => {
    if (reportRef && reportRef.current && one) {
      reportRef.current.innerHTML = "";
      reportRef.current.insertAdjacentHTML(
        "beforeend",
        one.note ? one.note : one.description
      );
    }
  }, [reportRef, one]);
  const checkExt = (file) => {
    const fileName = file.fileName;
    const generatedFile = file.generatedFile;
    // const fileLocation = file.fileLocation;
    if (fileName || generatedFile) {
      const startIndex = fileName
        ? fileName.lastIndexOf(".", fileName.length - 1) + 1
        : generatedFile.lastIndexOf(".", generatedFile.length - 1) + 1;
      const checkExt = fileName
        ? fileName.substring(startIndex)
        : generatedFile.substring(startIndex);
      if (checkExt === "pdf") {
        if (file.generatedFile || file.fileLocation) {
          return (
            <Box
              justifyContent="center"
              alignItems="center"
              p="5px"
              display="flex"
              flexWrap="wrap"
              boxSize="170px"
              border={`1px solid ${style.smallTextGrayLightBorder}`}
              className="rounded"
              position="relative"
            >
              <Box
                w="100%"
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <Image boxSize="130px" src={PDF} alt={"PDF"} />
              </Box>
              {one.fileName ? (
                <Text
                  fontSize="14px"
                  fontWeight="semibold"
                  color={style.secondary}
                >
                  {one.fileName.substring(0, 15) + "..." || "null"}
                </Text>
              ) : null}
              <Box
                position="absolute"
                top="0"
                bottom="0"
                right="0"
                left="0"
                bg="gray.100"
                opacity=".5"
                transition=".5s"
                _hover={{ opacity: ".8" }}
              >
                <a
                  href={file.generatedFile || file.fileLocation}
                  target="_blank"
                  className="nav-link w-100 h-100 d-flex align-items-center justify-content-center"
                >
                  <Text
                    fontWeight="bold"
                    fontSize="2xl"
                    className="text-center"
                    color="primary"
                  >
                    View <br />
                    <span>
                      <i className="fas fa-eye"></i>
                    </span>
                  </Text>
                </a>
              </Box>
            </Box>
          );
        }
      } else if (checkExt === "png" || checkExt === "jpg") {
        return (
          <Image
            src={one.fileLocation}
            alt={one.fileName}
            border={`1px solid ${style.smallTextGrayLightBorder}`}
            className="rounded"
            boxSize="170px"
          />
        );
      } else {
        return null;
      }
    }
  };
  const showDownloadBtn = (file) => {
    const fileName = file.fileName;
    const generatedFile = file.generatedFile;
    ////////

    ///////////

    if (fileName || generatedFile) {
      /////
      const startIndex = fileName
        ? fileName.lastIndexOf(".", fileName.length - 1) + 1
        : generatedFile.lastIndexOf(".", generatedFile.length - 1) + 1;
      const checkExt = fileName
        ? fileName.substring(startIndex)
        : generatedFile.substring(startIndex);
      ////////
      if (checkExt === "pdf") {
        return (
          <Button
            className="me-2"
            color={style.secondary}
            variant="ghost"
            _hover={{
              color: style.secondary,
              background: style.secondary_hover_Opacity,
            }}
          >
            <a
              href={file.fileLocation || file.generatedFile}
              target="_blank"
              style={{ color: style.secondary }}
            >
              <span className="me-3">
                <i className="fas fa-download"></i>
              </span>
              Download
            </a>
          </Button>
        );
      }
    }
  };

  const renderView = () => {
    if (loading) {
      return " ";
    }
    if (error) {
      return "Error";
    }
    if (one) {
      return (
        <motion.div
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <div className="col-12 d-flex py-4 align-items-center ps-0 justify-content-between">
            <div className="d-flex col-12 align-items-center">
              <Circle
                onClick={() => history.goBack()}
                size="45px"
                bg={
                  colorMode === "dark"
                    ? style.smallTextGrayLight
                    : style.bg_Light_Gray
                }
                _hover={{
                  background:
                    colorMode === "dark" ? style.smallTextGrayLight : "",
                  color: style.secondary,
                }}
                transition=".2s ease-in-out"
                color={style.secondary}
                marginRight="4"
                cursor="pointer"
              >
                <i className="fas fa-chevron-left"></i>
              </Circle>
              <Text
                fontSize={{ base: "2xl", md: "3xl", lg: "3xl" }}
                className="fw-bold"
                color={style.secondary}
              >
                {headerText}
              </Text>
              {viwer ? (
                <Button
                  size="lg"
                  className="ms-auto"
                  bg={style.primary}
                  color={style.white}
                  _hover={{ bg: style.primary_hover }}
                  fontSize="md"
                >
                  <a href={one.viewer_url} target="_blank" className="nav-link">
                    <span className="me-2">
                      <i className="fas fa-eye"></i>
                    </span>{" "}
                    Open medical viewer
                  </a>
                </Button>
              ) : null}
            </div>
            <div className="col-md-6 col-12 ps-0 text-end">
              {showDownloadBtn(one)}
            </div>
          </div>
          <Box bg={checkBgColorMode(colorMode)} className="col-12 p-4 rounded ">
            <div className="header row m-0 align-items-center">
              <Box className="col-md-6 ">
                <Text className="h4 fw-bold mb-0" color={style.secondary}>
                  {one.title ? one.title : one.value}
                </Text>
                <Text color={style.smallTextGray} fontSize="12px">
                  {moment
                    .unix(one.updatedAt || one.updated_at)
                    .format("DD/MM/YYYY hh:mm A")}
                </Text>
              </Box>
              {/* {one.consultantId ? null : (
                <Box className="col-md-6 text-end d-flex justify-content-end align-items-center">
                  <Text
                    marginRight="5"
                    color={style.smallTextGray}
                    fontSize="13px"
                  >
                    Added By{" "}
                  </Text>
                  <Avatar
                    marginRight="2"
                    src={patient ? patient.picture : null}
                    size="md"
                    name={patient ? patient.firstName : null}
                  />
                  <Text fontSize="15px" fontWeight="semibold">
                    You{" "}
                  </Text>
                </Box>
              )} */}
            </div>

            <Box
              boxShadow="xs"
              className="rounded p-3 mt-3"
              bg={style.smallTextGrayLight}
              border={`1px solid ${style.smallTextGrayLightBorder}`}
            >
              {question ? (
                <Box>
                  <Text>
                    <span className="h6">Body:</span> {one.body}
                  </Text>
                  <Text className="mt-1">
                    <span className="h6">Answer:</span> {one.reply}
                  </Text>
                </Box>
              ) : (
                <p ref={reportRef}></p>
              )}
            </Box>
            <Box className="p-y mt-3">{checkExt(one)}</Box>
          </Box>
        </motion.div>
      );
    } else {
      return "noting";
    }
  };
  return renderView();
};

export default connect()(View);
