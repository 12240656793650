import React from "react";
import MyInfoChild from "./Children/my_Info/my_info";
import AboutMe from "./Children/about_Me/aboutMe";
import Address from "./Children/address/address";
import Qualifications from "./Children/qualifications/Qualifications";
import AppointmentTime from "./Children/appointment_Time/appointmentTime";
const MyInfo = ({ consultantProfile, qualifications, schedules }) => {
  return (
    <div className="">
      {/* done */}
      <MyInfoChild consultantProfile={consultantProfile} />
      {/* done */}
      <Address consultantProfile={consultantProfile} />
      {/* done */}
      <AboutMe consultantProfile={consultantProfile} />
      {/* done */}
      <Qualifications
        consultantProfile={consultantProfile}
        qualifications={qualifications}
      />

      <AppointmentTime
        consultantProfile={consultantProfile}
        schedules={schedules.data}
      />
    </div>
  );
};

export default MyInfo;
