import axios from "axios";
import { types } from "../types/types";
import { handeleError, handeleSuccess } from "./Toast/toast";
const API_URL = process.env.REACT_APP_API_URL;

const authTypes = types.AuthTypes;

const authConfige = {
  "Content-Type": "application/json",
  "X-Api-Key": "du7lik773nspohl0",
};

// login
export const logIn = (email, password, history, toast) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.LOGIN_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/login`,
        headers: authConfige,
        data: {
          email: email,
          password: password,
        },
      });
      if (res) {
        localStorage.clear();
        localStorage.setItem("doctorToken", res.data.token);
        localStorage.setItem("doctorId", res.data.id);
        localStorage.setItem("userType", "Doctor");
        handeleSuccess("You’ve successfully logged in", toast);
        history.push("/");
        dispatch({
          type: authTypes.LOGIN_SUCSES,
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: authTypes.LOGIN_ERROR,
        payload: err,
      });
    }
  };
};

export const logOut = (history) => {
  return (dispatch) => {
    dispatch({
      type: authTypes.LOGOUT_LOADING,
    });
    try {
      localStorage.clear();
      history.push("/login");
      dispatch({
        type: authTypes.LOGIN_SUCSES,
        payload: "You are logged out sucsessfuly",
      });
    } catch (err) {
      dispatch({
        type: authTypes.LOGOUT_ERROR,
        payload: "Something Went Wrong",
      });
    }
  };
};

export const signupUser = (data, history, toast) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.REGISTER_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant`,
        headers: authConfige,
        data: data,
      });
      if (res) {
        localStorage.setItem("registrUser", JSON.stringify(data));
        localStorage.setItem("registrDoctorToken", res.data.token);
        handeleSuccess("Your account created successfully", toast);
        history.push(`/verify_mobile`);
        dispatch({
          type: authTypes.REGISTER_SUCSES,
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: authTypes.REGISTER_ERROR,
        payload: err,
      });
    }
  };
};

export const postConsultantVerifyMobile = (data, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.VERIFY_MOBILE_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/verify-mobile`,
        data: data,
      });
      handeleSuccess("Successful Code", toast);
      const user = JSON.parse(localStorage.getItem("register"));
      localStorage.clear();
      localStorage.setItem("loginEmail", user.email);
      history.push("/login");
      dispatch({
        type: authTypes.VERIFY_MOBILE_SUCSES,
        payload: res.data,
      });
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: authTypes.VERIFY_MOBILE_ERROR,
        payload: err.response,
      });
    }
  };
};
export const resendConsultantVerifyMobile = (toast) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.RESEND_VERIFY_MOBILE_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/resend-mobile-verification-code`,
      });
      if (res) {
        handeleSuccess("Code was Sent successfully", toast);
        dispatch({
          type: authTypes.RESEND_VERIFY_MOBILE_SUCSES,
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: authTypes.RESEND_VERIFY_MOBILE_ERROR,
        payload: err.response,
      });
    }
  };
};

export const forgetPassword = (email, setCounter, toast) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.FORGET_PASSWORD_LOADING,
    });
    try {
      let res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/forgot-password`,
        headers: authConfige,
        data: {
          email: email,
        },
      });
      if (res) {
        handeleSuccess("Code sent successfully", toast);
        setCounter(2);
        dispatch({
          type: authTypes.FORGET_PASSWORD_SUCSES,
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: authTypes.FORGET_PASSWORD_ERROR,
        payload: err,
      });
    }
  };
};
