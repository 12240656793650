import React from "react";

import { Switch, Route, useLocation } from "react-router-dom";
import NewConsultations from "../components/NewConsultations/NewConsultations";
import Login from "../components/Login/Login";
import Profile from "../components/profile/profile";
import ProtectedRoute from "./ProtectedRoute";
import ActiveConsultations from "../components/ActiveConsultations/ActiveConsultations";
import Appointments from "../components/Appointments/Appointments";
import Reports from "../components/reports/Reports";
import NotFound from "../components/NotFound/NotFound";
import SignUp from "../components/Sign-Up/SignUp";
import ForgetPassword from "../components/ForgetPassword/ForgetPassword";
import VerifyMobile from "../components/Sign-Up/VerifyMobile";
import Notifications from "../components/Notifications/Notifications";
import ConsultationOverView from "../components/ConsultationOverView/ConsultationOverView";
import { AnimatePresence } from "framer-motion";

const RenderRoute = () => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch key={location.pathname} location={location}>
        <ProtectedRoute path="/" exact component={NewConsultations} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={SignUp} />
        <Route path="/verify_mobile" component={VerifyMobile} />
        <Route path="/forgetpassword" component={ForgetPassword} />
        <ProtectedRoute
          path="/active_consultations"
          exact
          component={ActiveConsultations}
        />
        <ProtectedRoute path="/appointments" component={Appointments} />
        <ProtectedRoute path="/reports" component={Reports} />
        <ProtectedRoute path="/profile" component={Profile} />
        <ProtectedRoute path="/profile" component={Profile} />

        <ProtectedRoute path="/my_Activity" component={Notifications} />
        <ProtectedRoute
          path="/consultation/:id"
          component={ConsultationOverView}
        />
        <Route path="*" exact={true} component={NotFound} />
      </Switch>
    </AnimatePresence>
  );
};
export default RenderRoute;
