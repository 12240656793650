import { types } from "../types/types";
const authTypes = types.AuthTypes;

const AUTH_IINT_STATE = {
  loading: false,
  error: null,
  user: null,
};

export const loginReducer = (state = AUTH_IINT_STATE, { type, payload }) => {
  switch (type) {
    case authTypes.LOGIN_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case authTypes.LOGIN_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        user: payload,
      };
    case authTypes.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const logOutReducer = (state = "", { type, payload }) => {
  switch (type) {
    case authTypes.LOGOUT_LOADING:
      return state;
    case authTypes.LOGOUT_SUCSES:
      return (state = payload);
    case authTypes.LOGOUT_ERROR:
      return (state = payload);
    default:
      return state;
  }
};

export const signupReducer = (state = AUTH_IINT_STATE, { type, payload }) => {
  switch (type) {
    case authTypes.REGISTER_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case authTypes.REGISTER_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        user: payload,
      };
    case authTypes.REGISTER_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const consultantVerifyMobileReducer = (
  state = AUTH_IINT_STATE,
  { type, payload }
) => {
  switch (type) {
    case authTypes.VERIFY_MOBILE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case authTypes.VERIFY_MOBILE_SUCSES:
      return {
        ...state,
        loading: false,
        error: "",
        user: payload,
      };
    case authTypes.VERIFY_MOBILE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const resendConsultantVerifyMobileReducer = (
  state = AUTH_IINT_STATE,
  { type, payload }
) => {
  switch (type) {
    case authTypes.RESEND_VERIFY_MOBILE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case authTypes.RESEND_VERIFY_MOBILE_SUCSES:
      return {
        ...state,
        loading: false,
        error: "",
        user: payload,
      };
    case authTypes.RESEND_VERIFY_MOBILE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const forgetPasswordReducer = (
  state = AUTH_IINT_STATE,
  { type, payload }
) => {
  switch (type) {
    case authTypes.FORGET_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case authTypes.FORGET_PASSWORD_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        user: payload,
      };
    case authTypes.FORGET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
