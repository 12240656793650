import { types } from "../types/types";

const { consultantTypes } = types;

const INIT_PROFILE_STATE = {
  loading: false,
  error: null,
  data: null,
};

export const getConsultantProfileReducer = (
  state = INIT_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultantTypes.GET_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultantTypes.GET_PROFILE_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultantTypes.GET_PROFILE_ERORR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const updateConsultantProfileReducer = (
  state = INIT_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultantTypes.UPDATE_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultantTypes.UPDATE_PROFILE_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultantTypes.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const updateConsultantProfilePictureReducer = (
  state = INIT_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultantTypes.PROFILE_PICTURE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultantTypes.PROFILE_PICTURE_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultantTypes.PROFILE_PICTURE_RORR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const changePasswordReducer = (
  state = INIT_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultantTypes.CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultantTypes.CHANGE_PASSWORD_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultantTypes.CHANGE_PASSWORD_ERORR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getQualificationsListReducer = (
  state = INIT_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultantTypes.GET_QUALIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultantTypes.GET_QUALIFICATIONS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultantTypes.GET_QUALIFICATIONS_ERORR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getSchedulesListReducer = (
  state = INIT_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultantTypes.GET_SCHEDULES_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultantTypes.GET_SCHEDULES_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultantTypes.GET_SCHEDULES_ERORR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const addQualificationsReducer = (
  state = INIT_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultantTypes.ADD_QUALIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultantTypes.ADD_QUALIFICATIONS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultantTypes.ADD_QUALIFICATIONS_ERORR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const updateQualificationsReducer = (
  state = INIT_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultantTypes.UPDATE_QUALIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultantTypes.UPDATE_QUALIFICATIONS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultantTypes.UPDATE_QUALIFICATIONS_ERORR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const updateSchedulesReducer = (
  state = INIT_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultantTypes.UPDATE_SCHEDULES_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultantTypes.UPDATE_SCHEDULES_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultantTypes.UPDATE_SCHEDULES_ERORR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getConsultationsListReducer = (
  state = INIT_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case consultantTypes.GET_CONSULTATIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case consultantTypes.GET_CONSULTATIONS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultantTypes.GET_CONSULTATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
