import { types } from "../types/types";

const commonTypes = types.commonTypes;

const INIT_COMMON_STATE = {
    loading: false,
    error: null,
    data: [],
};
export const getConsultationTypeListReducer = (
    state = INIT_COMMON_STATE,
    { type, payload }
) => {
    switch (type) {
        case commonTypes.GET_CONSULTATION_TYPE_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case commonTypes.GET_CONSULTATION_TYPE_SUCSES:
            return {
                ...state,
                loading: false,
                error: null,
                data: payload,
            };
        case commonTypes.GET_CONSULTATION_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};

export const getPatientTypesReducer = (
    state = INIT_COMMON_STATE,
    { type, payload }
) => {
    switch (type) {
        case commonTypes.GET_PATIENT_TYPE_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case commonTypes.GET_PATIENT_TYPE_SUCSES:
            return {
                ...state,
                loading: false,
                error: null,
                data: payload,
            };
        case commonTypes.GET_PATIENT_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};