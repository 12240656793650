import React, { useState, useEffect } from "react";
import { Button, Text, useToast, useColorMode } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputControl } from "formik-chakra-ui";
import { connect } from "react-redux";
import {
  getQualificationsList,
  updateQualification,
} from "../../../../../store/actions";
import { useHistory } from "react-router-dom";

import { checkColorMode } from "../../../../global/changeColor";

const SubmitSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  body: Yup.string().required("Required"),
});
const OneQualification = ({
  item,
  updateQualification,
  getQualificationsList,
  updatedQualification,
}) => {
  const history = useHistory();
  const toast = useToast();
  const [edit, setEdit] = useState(false);
  const { colorMode } = useColorMode();
  const [initValue, setInitValue] = useState({
    title: "",
    body: "",
  });
  useEffect(() => {
    setInitValue(item);
  }, [item]);
  const onFormSubmit = (value) => {
    updateQualification(
      value,
      history,
      toast,
      null,
      setEdit,
      getQualificationsList,
      value.id
    );
  };
  return (
    <>
      {edit ? (
        <Formik
          initialValues={initValue}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={SubmitSchema}
        >
          <Form className="col-12 row m-0 align-items-center mt-3">
            <div className="col-md-3 my-2">
              <InputControl
                name={`title`}
                label="Title"
                inputProps={{ placeholder: "Title" }}
              />
            </div>
            <div className="col-md-7 my-2">
              <InputControl
                name={`body`}
                label="Body"
                inputProps={{ placeholder: "Body" }}
              />
            </div>
            <div className="col-md-2  d-flex my-2 justify-content-end">
              <Button
                variant="ghost"
                bg="#f3e9eb"
                _hover={{ bg: "#f3d3da" }}
                fontSize="20px"
                color="#D42C51"
                className="me-2"
                onClick={() => setEdit(false)}
              >
                <i className="fas fa-times"></i>
              </Button>
              <Button
                variant="ghost"
                bg="secondary"
                _hover={{ bg: "secondary_hover" }}
                fontSize="20px"
                type="submit"
                color="#fff"
                onClick={() => setEdit(true)}
                isLoading={updatedQualification.loading}
              >
                <i className="fas fa-save"></i>
              </Button>
            </div>
          </Form>
        </Formik>
      ) : (
        <>
          <div className="col-md-4 col-12 p-0 mt-3">
            <Text fontWeight="semibold" color={"smallTextGray"}>
              Title
            </Text>
            <Text fontSize="sm" color={checkColorMode(colorMode)}>
              {item.title}
            </Text>
          </div>
          <div className="col-md-6 me-auto col-12 p-0 mt-3 ">
            <Text fontWeight="semibold" color={"smallTextGray"}>
              Body
            </Text>
            <Text fontSize="sm" color={checkColorMode(colorMode)}>
              {item.body}
            </Text>
          </div>
          <div className="col-md-2 col-12 text-end">
            <Button
              variant="ghost"
              color={checkColorMode(colorMode)}
              onClick={() => setEdit(true)}
            >
              <i className="fas fa-pen"></i>
            </Button>
          </div>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    updatedQualification: state.updatedQualification,
  };
};
export default connect(mapStateToProps, {
  updateQualification,
  getQualificationsList,
})(OneQualification);
