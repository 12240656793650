import React from "react";
import { Button } from "@chakra-ui/react";

const Pagination = ({
  pages,
  currentPage,
  specificPage,
  nextPage,
  prevPage,
}) => {
  const pageLinkes = [];
  const prevP = currentPage > 1;
  const nextP = currentPage < pages;
  for (let i = 1; i <= pages; i++) {
    let active = currentPage == i ? "active" : "";
    pageLinkes.push(
      <Button
        className="mx-1 my-1"
        bg="white"
        color="gray.500"
        isActive={active}
        key={i}
        onClick={() => specificPage(i)}
      >
        {i}
      </Button>
    );
  }
  return (
    <div className="d-flex flex-wrap justify-content-end align-items-center mt-3">
      <Button color="gray.500" bg="white" onClick={prevPage} disabled={!prevP}>
        <i className="fas fa-arrow-left"></i>
      </Button>{" "}
      {pageLinkes}{" "}
      <Button color="gray.500" bg="white" onClick={nextPage} disabled={!nextP}>
        <i className="fas fa-arrow-right"></i>
      </Button>
    </div>
  );
};

export default Pagination;
