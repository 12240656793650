export const style = {
  appBackground: "#D8D8D8",
  mainText: "#000", // black
  secondary: "#30B8BA", //green
  secondary_hover: "rgba(48, 184, 186, .8)", //green hover
  secondary_hover_Opacity: "rgba(48, 184, 186, .1)", //green hover
  error: "#D42C51", //red
  error_hover: "rgb(212 44 81 / 4%)",
  error_Scheme: "rgb(212 44 81 / 9%)",
  error_Scheme_hover: "rgb(212 44 81 / 11%)",
  primary: "#21118D", // move
  primary_hover: "rgba(33,17,141,.8)", // move
  primaryOpacity: "rgba(33,17,141,.2)", //move opacity
  primaryOpacity_hover: "rgb(33 17 141 / 4%)", //move opacity
  smallTextGray: "#8F9698", // gray text
  smallTextGrayLight: "rgba(151,151,151,.3)", // gray text
  smallTextGrayLightBorder: "rgba(151,151,151,.5)", // gray text
  info: "#FFB100", // yellow
  infoOpacity: "rgba(255,177,0,.2)", // yellow opacity
  white: "#fff",
  transition: ".2s ease-in-out",
  bg_Light_Gray: "#e9ecef",
  bg_Light_Gray_Hover: "#dbdbdb",
  orange: "#FFB100",
  green: "#42B79E",
};
