import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  InputGroup,
  Input,
  InputLeftElement,
  Text,
  Avatar,
  Circle,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import moment from "moment";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import chest from "../../assets/svg-icons/chest.svg";
import {
  getUserConsultations,
  acceptNewConsultation,
  rejectNewConsultation,
} from "../../store/actions";

import EmptyConsultations from "./EmptyConsultations.js";
import MyAvtivityHome from "./MyAvtivity/MyAvtivity";
import {
  checkColorMode,
  checkBgColorMode,
  checkColorModeHover,
} from "../global/changeColor";
import { GetSpecialityIcon } from "../global/GetSpecialityIcon.js";

export const check_status_string = (status, colorMode) => {
  switch (status) {
    case "Awaiting to be assigned":
      return { string: "Pending for test", className: "pending" };
    case "Done":
      return {
        string: "Done",
        className: `done ${colorMode === "dark" ? "done-dark" : ""}`,
      };

    default:
      return {
        string: status,
        className: "pending",
      };
  }
};

const NewConsultationList = ({
  consultationList,
  consultationTypes,
  getUserConsultations,
  acceptNewConsultation,
  rejectNewConsultation,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [consultation, setConsultation] = useState([]);
  const [filterConsultation, setFilterConsultation] = useState([]);
  const [ConsultationType, setConsultationType] = useState([]);
  const toast = useToast();
  const { colorMode } = useColorMode();
  useEffect(() => {
    if (consultationTypes && consultationTypes.data) {
      setConsultationType(consultationTypes.data);
    }
  }, [consultationTypes]);

  useEffect(() => {
    const { loading, error, data } = consultationList;
    setLoading(loading);
    setError(error);

    if (data) {
      const notDone = data.filter((item) => item.status !== "done");
      setConsultation(notDone);
      setFilterConsultation(notDone);
    }
  }, [consultationList]);
  const renderView = () => {
    if (loading) {
      return " ";
    }
    if (error) {
      return "Error";
    }
    if (consultation.length) {
      return (
        <motion.div className="container" initial="out" animate="in" exit="out">
          <div className="row">
            <div className="h4 py-3 fw-bold d-flex flex-wrap align-items-center justify-content-between">
              <Text
                fontSize="2xl"
                className="col-md-4 my-4"
                fontWeight="semibold"
              >
                New Consultations
              </Text>

              <div className="d-flex align-items-center col-md-5 col-12">
                <div className="me-3 input-search w-100">
                  <InputGroup className="w-100">
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <Text
                          fontSize="20px"
                          color={checkColorMode(colorMode)}
                          className="icon-search"
                        >
                          <i className="fas fa-search"></i>
                        </Text>
                      }
                    />
                    <Input
                      w="300px"
                      placeholder="Search"
                      size="lg"
                      className="ps-5  w-100"
                      bg={checkBgColorMode(colorMode)}
                      onChange={(e) => {
                        let term = e.target.value;
                        if (term.trim().length > 0) {
                          const filterD = consultation.filter(
                            (one) =>
                              one.symptoms
                                .toLowerCase()
                                .includes(term.trim().toLowerCase()) ||
                              one.patient.fullName
                                .toLowerCase()
                                .includes(term.trim().toLowerCase())
                          );
                          setFilterConsultation(filterD);
                        } else {
                          setFilterConsultation(consultation);
                        }
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>

            {filterConsultation.map((item, index) => (
              <Box
                key={item.id}
                bg={checkBgColorMode(colorMode)}
                className="row m-0 mb-2 shadow-sm p-3 pe-0 text-dark rounded align-items-center"
              >
                <Link
                  key={index}
                  to={`/consultation/${item.id}`}
                  className="row col-md-11 col-12 align-items-center text-dark"
                >
                  <div className="col-lg-3 col-12 my-2">
                    <Text
                      fontSize="lg"
                      fontWeight={700}
                      color={checkColorMode(colorMode)}
                    >
                      {item.symptoms}
                    </Text>
                    <Text color="smallTextGray" fontSize="12px">
                      {moment(item.createdAt * 1000).format(
                        "MM/DD/YYYY - hh:mm A"
                      )}
                    </Text>
                  </div>

                  <div className="col-lg-3 col-12 d-flex align-items-center m-0 me-auto my-2">
                    <div className="me-2">
                      <Circle size="48px" bg={checkColorMode(colorMode)}>
                        {item.consultationTypeId ? (
                          <img
                            className="image-fluid"
                            src={GetSpecialityIcon(
                              Number(item.consultationTypeId)
                            )}
                            alt="chest"
                          />
                        ) : (
                          ""
                        )}
                      </Circle>
                    </div>

                    <Box className="">
                      <Text
                        color="smallTextGray"
                        fontSize="15px"
                        fontWeight="600"
                      >
                        Speciality
                      </Text>
                      <Text
                        fontSize="13px"
                        fontWeight="700"
                        color={checkColorMode(colorMode)}
                      >
                        {ConsultationType.length
                          ? ConsultationType.find(
                              (element) =>
                                element.id === item.consultationTypeId
                            ).name
                          : ""}
                      </Text>
                    </Box>
                  </div>

                  {item.patient.fullName || item.patient.firstName ? (
                    <div className="col-lg-4 col-12 d-flex align-items-center m-0 my-2 overflow-hidden">
                      <div className=" me-2">
                        <Avatar
                          bg={checkColorMode(colorMode)}
                          size="md"
                          src={item.patient.picture}
                          name={item.patient.fullName || item.patient.firstName}
                          color="#FFF"
                        />
                      </div>
                      <Box className="">
                        <Text
                          color="smallTextGray"
                          fontSize="15px"
                          fontWeight="600"
                        >
                          Patient
                        </Text>
                        <Text
                          fontSize="13px"
                          fontWeight="700"
                          color={checkColorMode(colorMode)}
                        >
                          {item.patient.fullName.length > 35 ? (
                            <span>
                              {item.patient.fullName.substring(0, 35) ||
                                item.patient.firstName.substring(0, 35)}{" "}
                              ...
                            </span>
                          ) : (
                            <span>
                              {item.patient.fullName || item.patient.firstName}{" "}
                            </span>
                          )}
                        </Text>
                      </Box>
                    </div>
                  ) : null}

                  <div className="col-lg-2 col-5 my-2">
                    <div
                      className={`${
                        check_status_string(item.statusString).className
                      } text-center rounded`}
                    >
                      {item.status}
                    </div>
                  </div>
                </Link>
                <div className="col-lg-1">
                  <Box className="text-end">
                    <Menu>
                      <MenuButton as={Button} variant="ghost">
                        <i className="fas fa-ellipsis-h"></i>
                      </MenuButton>
                      <MenuList className="menu-condition shadow">
                        <MenuItem color={checkColorMode(colorMode)}>
                          {
                            <Link
                              to={`/consultation/${item.id}`}
                              className="nav-link p-0 w-100 "
                            >
                              <span className="me-3 ">
                                <i className="fas fa-eye"></i>
                              </span>
                              View
                            </Link>
                          }
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            acceptNewConsultation(item.id, toast, {
                              getUserConsultations,
                            });
                          }}
                          color={checkColorMode(colorMode)}
                        >
                          {
                            <p className="nav-link p-0 w-100">
                              <span className="me-3 ">
                                <i className="fas fa-check"></i>
                              </span>
                              Accept
                            </p>
                          }
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            rejectNewConsultation(item.id, toast, {
                              getUserConsultations,
                            });
                          }}
                          color={colorMode === "dark" ? "error" : "black"}
                        >
                          {
                            <p className="nav-link p-0 w-100 ">
                              <span
                                className="me-3 "
                                style={{ color: "#D42C51" }}
                              >
                                <i className="fas fa-xmark"></i>
                              </span>
                              Reject
                            </p>
                          }
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </div>
              </Box>
            ))}
            <MyAvtivityHome />
          </div>
        </motion.div>
      );
    } else {
      return (
        <motion.div className="container" initial="out" animate="in" exit="out">
          <div className="row">
            <EmptyConsultations />
            <MyAvtivityHome />
          </div>
        </motion.div>
      );
    }
  };
  return renderView();
};
const mapStateToProps = (state, props) => {
  return {
    consultationList: state.ConsultationsList,
    consultationTypes: state.consultationTypes,
  };
};
export default connect(mapStateToProps, {
  getUserConsultations,
  acceptNewConsultation,
  rejectNewConsultation,
})(NewConsultationList);
