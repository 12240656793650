import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  InputGroup,
  Input,
  InputLeftElement,
  Text,
  Avatar,
  useToast,
  // Circle,
  useColorMode,
} from "@chakra-ui/react";
import moment from "moment";
import { motion } from "framer-motion";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import patientTest from "../../assets/patientTest.png";
import {
  checkBgColorMode,
  checkColorMode,
  checkWhiteAndBlackColor,
} from "../global/changeColor";
import { getAppointments } from "../../store/actions";
import Pagination from "../Pagination/Pagination";
import FilterByMonth from "./FilterByMonth";
import { pageTransition, pageVariants } from "../global/Animations/Animations";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const AppointmentList = ({ getAppointments, appointmentList, history }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [filterAppointments, setFilterAppointments] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  const { colorMode } = useColorMode();
  let { url } = useRouteMatch();
  let query = useQuery().get("page");
  let toast = useToast();
  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    getAppointments(query);
  }, [query]);

  useEffect(() => {
    const { loading, error, data } = appointmentList;
    setLoading(loading);
    setError(error);
    if (data && data.data) {
      const list = data.data;
      setAppointments(list);
      setFilterAppointments(list);
      if (data.meta) {
        setTotalResults(data.meta.total);
        setCuttentPage(data.meta.current_page);
      } else {
        setTotalResults(0);
      }
    }
  }, [appointmentList]);

  const renderView = () => {
    if (loading) {
      return "";
    }
    if (error) {
      return "Error";
    }
    if (appointments.length) {
      return (
        <>
          {filterAppointments.map((item, index) => (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              key={index}
              className="p-0"
            >
              <Box
                as={Link}
                to={`/consultation/${item.patient_consultation_request_id}/appointment`}
                className="m-0 mb-2 shadow-sm row align-items-center p-3 pe-0 rounded "
                bg={checkBgColorMode(colorMode)}
                _hover={{ color: checkWhiteAndBlackColor(colorMode) }}
              >
                <div className="col-md-5 my-2">
                  <Text fontSize="lg" fontWeight={700}>
                    {"Appointment Time"}
                  </Text>
                  <Text color="smallTextGray" fontSize="13px" className="mt-2">
                    {moment
                      .unix(item.appointment_time)
                      .format("DD/MM/YYYY - hh:mm A")}
                  </Text>
                </div>

                {item.patient ? (
                  <div className="col-md-5 my-2  row align-items-center m-0">
                    <div className="col-2 ps-0">
                      <Avatar
                        style={{ width: "42px", height: "42px" }}
                        bg={checkColorMode(colorMode)}
                        size="md"
                        src={
                          item.patient.picture
                            ? `https://api.staging.pat-rec.com/storage/${item.patient.picture}`
                            : null
                        }
                        name={`${item.patient.first_name} ${item.patient.last_name}`}
                        color="#FFF"
                      />
                    </div>
                    <Box className="col-9 p-1">
                      <Text
                        color="smallTextGray"
                        fontSize="15px"
                        fontWeight="600"
                      >
                        Patient
                      </Text>
                      <Text fontSize="13px" fontWeight="700">
                        {`${item.patient.full_name} `}
                      </Text>
                    </Box>
                  </div>
                ) : null}

                {/* <div className="col-2 ">
                  <div
                    className={`${
                      check_status_string(item.statusString).className
                    } text-center rounded`}
                  >
                    {item.status}
                  </div>
                </div> */}
              </Box>
            </motion.div>
          ))}
          <Pagination
            pages={pagesNumber}
            nextPage={nextPage}
            prevPage={prevPage}
            specificPage={specificPage}
            currentPage={currentPage}
          />
        </>
      );
    } else {
      return "No Appointments Found";
    }
  };
  return (
    <motion.div
      className="container pb-5"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="row ">
        <div className="h4 p-0 py-3 fw-bold d-flex flex-wrap align-items-center justify-content-between">
          <Text fontSize="2xl" className="my-4" fontWeight="semibold">
            Appointments
          </Text>

          <div className="d-flex flex-wrap align-items-center">
            <div className="input-search my-2 me-2">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={
                    <Text
                      className="icon-search"
                      color={checkColorMode(colorMode)}
                    >
                      <i className="fas fa-search"></i>
                    </Text>
                  }
                />
                <Input
                  w="300px"
                  placeholder="Patient Name"
                  size="lg"
                  fontSize={"14px"}
                  className="ps-5"
                  bg={checkBgColorMode(colorMode)}
                  onChange={(e) => {
                    let term = e.target.value;
                    if (term.trim().length > 0) {
                      const filterD = appointments.filter((one) =>
                        one.patient.full_name
                          .toLowerCase()
                          .includes(term.trim().toLowerCase())
                      );
                      setFilterAppointments(filterD);
                    } else {
                      setFilterAppointments(appointments);
                    }
                  }}
                />
              </InputGroup>
            </div>
            <FilterByMonth />
          </div>
        </div>

        {renderView()}
      </div>
    </motion.div>
  );
};
const mapStateToProps = (state) => {
  return {
    appointmentList: state.AppointmentList,
  };
};
export default connect(mapStateToProps, { getAppointments })(AppointmentList);
