export const navList = [
  {
    text: "Active Consultations",
    linkTo: "/active_consultations",
  },
  {
    text: "Appointments",
    linkTo: "/appointments",
  },
  {
    text: "Reports",
    linkTo: "/reports",
  },
];
