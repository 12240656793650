import React from "react";
import { useColorMode } from "@chakra-ui/react";
import { ErrorMessage, Field } from "formik";

const NmberInput = ({ name, label, placeholder }) => {
  let { colorMode } = useColorMode();
  return (
    <>
      <label htmlFor={name} className="chakra-form__label mb-1">
        {label}
      </label>
      {name === "yearsOfExperienceAsConsultant" ? (
        <Field
          id={name}
          className={`chakra-input  ${
            colorMode === "dark" ? "css-xpongc" : "css-1c6j008"
          }`}
          type="text"
          onInput={(event) => {
            event.target.value = event.target.value.replace(/[^0-9.\+]/g, "");
          }}
          name={name}
          placeholder={placeholder}
        />
      ) : (
        <Field
          id={name}
          className={`chakra-input  ${
            colorMode === "dark" ? "css-xpongc" : "css-1c6j008"
          }`}
          type="text"
          onInput={(event) => {
            event.target.value = event.target.value.replace(/[^0-9\+]/g, "");
          }}
          name={name}
          placeholder={placeholder}
        />
      )}

      <ErrorMessage
        component={"div"}
        name={name}
        className="text-danger"
        style={{ fontSize: "14px" }}
      />
    </>
  );
};

export default NmberInput;
