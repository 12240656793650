export const types = {
  commonTypes: {
    //GET_CONSULTATION_TYPE
    GET_CONSULTATION_TYPE_LOADING: "GET_CONSULTATION_TYPE_LOADING",
    GET_CONSULTATION_TYPE_SUCSES: "GET_CONSULTATION_TYPE_SUCSES",
    GET_CONSULTATION_TYPE_ERROR: "GET_CONSULTATION_TYPE_ERROR",

    //GET_PATIENT_TYPE
    GET_PATIENT_TYPE_LOADING: "GET_PATIENT_TYPE_LOADING",
    GET_PATIENT_TYPE_SUCSES: "GET_PATIENT_TYPE_SUCSES",
    GET_PATIENT_TYPE_ERROR: "GET_PATIENT_TYPE_ERROR",
  },
  AuthTypes: {
    //// logIn
    LOGIN_LOADING: "LOGIN_LOADING",
    LOGIN_SUCSES: "LOGIN_SUCSES",
    LOGIN_ERROR: "LOGIN_ERROR",
    // logOut
    LOGOUT_LOADING: "LOGOUT_LOADING",
    LOGOUT_SUCSES: "LOGOUT_SUCSES",
    LOGOUT_ERROR: "LOGOUT_ERROR",
    // register
    REGISTER_LOADING: "REGISTER_LOADING",
    REGISTER_SUCSES: "REGISTER_SUCSES",
    REGISTER_ERROR: "REGISTER_ERROR",
    // verify
    VERIFY_MOBILE_LOADING: "VERIFY_MOBILE_LOADING",
    VERIFY_MOBILE_SUCSES: "VERIFY_MOBILE_SUCSES",
    VERIFY_MOBILE_ERROR: "VERIFY_MOBILE_ERROR",
    // resend verify
    RESEND_VERIFY_MOBILE_LOADING: "RESEND_VERIFY_MOBILE_LOADING",
    RESEND_VERIFY_MOBILE_SUCSES: "RESEND_VERIFY_MOBILE_SUCSES",
    RESEND_VERIFY_MOBILE_ERROR: "RESEND_VERIFY_MOBILE_ERROR",
    // forget password
    FORGET_PASSWORD_LOADING: "FORGET_PASSWORD_LOADING",
    FORGET_PASSWORD_SUCSES: "FORGET_PASSWORD_SUCSES",
    FORGET_PASSWORD_ERROR: "FORGET_PASSWORD_ERROR",
  },
  consultantTypes: {
    GET_PROFILE_LOADING: "GET_PROFILE_LOADING",
    GET_PROFILE_SUCSES: "GET_PROFILE_SUCSES",
    GET_PROFILE_ERORR: "GET_PROFILE_ERORR",
    // update profile
    UPDATE_PROFILE_LOADING: "UPDATE_PROFILE_LOADING",
    UPDATE_PROFILE_SUCSES: "UPDATE_PROFILE_SUCSES",
    UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",
    // add picture
    PROFILE_PICTURE_LOADING: "PROFILE_PICTURE_LOADING",
    PROFILE_PICTURE_SUCSES: "PROFILE_PICTURE_SUCSES",
    PROFILE_PICTURE_RORR: "PROFILE_PICTURE_ERORR",
    // change password
    CHANGE_PASSWORD_LOADING: "CHANGE_PASSWORD_LOADING",
    CHANGE_PASSWORD_SUCSES: "CHANGE_PASSWORD_SUCSES",
    CHANGE_PASSWORD_ERORR: "CHANGE_PASSWORD_ERORR",
    // QUALIFICATIONS
    GET_QUALIFICATIONS_LOADING: "GET_QUALIFICATIONS_LOADING",
    GET_QUALIFICATIONS_SUCSES: "GET_QUALIFICATIONS_SUCSES",
    GET_QUALIFICATIONS_ERORR: "GET_QUALIFICATIONS_ERORR",
    // QUALIFICATIONS
    GET_SCHEDULES_LOADING: "GET_SCHEDULES_LOADING",
    GET_SCHEDULES_SUCSES: "GET_SCHEDULES_SUCSES",
    GET_SCHEDULES_ERORR: "GET_SCHEDULES_ERORR",

    // ADD QUALIFICATIONS
    ADD_QUALIFICATIONS_LOADING: "ADD_QUALIFICATIONS_LOADING",
    ADD_QUALIFICATIONS_SUCSES: "ADD_QUALIFICATIONS_SUCSES",
    ADD_QUALIFICATIONS_ERORR: "ADD_QUALIFICATIONS_ERORR",

    // UPDATE QUALIFICATIONS
    UPDATE_QUALIFICATIONS_LOADING: "UPDATE_QUALIFICATIONS_LOADING",
    UPDATE_QUALIFICATIONS_SUCSES: "UPDATE_QUALIFICATIONS_SUCSES",
    UPDATE_QUALIFICATIONS_ERORR: "UPDATE_QUALIFICATIONS_ERORR",

    // UPDATE QUALIFICATIONS
    UPDATE_SCHEDULES_LOADING: "UPDATE_SCHEDULES_LOADING",
    UPDATE_SCHEDULES_SUCSES: "UPDATE_SCHEDULES_SUCSES",
    UPDATE_SCHEDULES_ERORR: "UPDATE_SCHEDULES_ERORR",
    // get consultations
    GET_CONSULTATIONS_LOADING: "GET_CONSULTATIONS_LOADING",
    GET_CONSULTATIONS_SUCSES: "GET_CONSULTATIONS_SUCSES",
    GET_CONSULTATIONS_ERROR: "GET_CONSULTATIONS_ERROR",
  },
  notificationsTypes: {
    /// LIST
    GET_NOTIFICATION_LIST_LOADING: "GET_NOTIFICATION_LIST_LOADING",
    GET_NOTIFICATION_LIST_SUCSES: "GET_NOTIFICATION_LIST_SUCSES",
    GET_NOTIFICATION_LIST_ERORR: "GET_NOTIFICATION_LIST_ERORR",
    //unread count
    GET_UNREAD_COUNT_LOADING: "GET_UNREAD_COUNT_LOADING",
    GET_UNREAD_COUNT_SUCSES: "GET_UNREAD_COUNT_SUCSES",
    GET_UNREAD_COUNT_ERORR: "GET_UNREAD_COUNT_ERORR",
    //mark all ass read
    MARK_ALL_AS_READ_LOADING: "MARK_ALL_AS_READ_LOADING",
    MARK_ALL_AS_READ_SUCSES: "MARK_ALL_AS_READ_SUCSES",
    MARK_ALL_AS_READ_ERORR: "MARK_ALL_AS_READ_ERORR",
    // mark one as read
    MARK_ONE_AS_READ_LOADING: "MARK_ONE_AS_READ_LOADING",
    MARK_ONE_AS_READ_SUCSES: "MARK_ONE_AS_READ_SUCSES",
    MARK_ONE_AS_READ_ERORR: "MARK_ONE_AS_READ_ERORR",
  },
  consultationsTypes: {
    // avtive consultations
    GET_ACTIVE_CONSULTATIONS_LOADING: "GET_ACTIVE_CONSULTATIONS_LOADING",
    GET_ACTIVE_CONSULTATIONS_SUCSES: "GET_ACTIVE_CONSULTATIONS_SUCSES",
    GET_ACTIVE_CONSULTATIONS_ERROR: "GET_ACTIVE_CONSULTATIONS_ERROR",
    // single consultation
    GET_SINGLE_CONSULTATION_LOADING: "GET_SINGLE_CONSULTATION_LOADING",
    GET_SINGLE_CONSULTATION_SUCSES: "GET_SINGLE_CONSULTATION_SUCSES",
    GET_SINGLE_CONSULTATION_ERROR: "GET_SINGLE_CONSULTATION_ERROR",
    // Questions
    GET_QUESTIONS_LIST_LOADING: "GET_QUESTIONS_LIST_LOADING",
    GET_QUESTIONS_LIST_SUCSES: "GET_QUESTIONS_LIST_SUCSES",
    GET_QUESTIONS_LIST_ERROR: "GET_QUESTIONS_LIST_ERROR",
    // single Question
    GET_SINGLE_QUESTION_LOADING: "GET_SINGLE_QUESTION_LOADING",
    GET_SINGLE_QUESTION_SUCSES: "GET_SINGLE_QUESTION_SUCSES",
    GET_SINGLE_QUESTION_ERROR: "GET_SINGLE_QUESTION_ERROR",
    // accept consultations
    ACCEPT_NEW_CONSULTATION_LOADING: "ACCEPT_NEW_CONSULTATION_LOADING",
    ACCEPT_NEW_CONSULTATION_SUCSES: "ACCEPT_NEW_CONSULTATION_SUCSES",
    ACCEPT_NEW_CONSULTATION_ERROR: "ACCEPT_NEW_CONSULTATION_ERROR",
    // reject consultations
    REJECT_NEW_CONSULTATION_LOADING: "REJECT_NEW_CONSULTATION_LOADING",
    REJECT_NEW_CONSULTATION_SUCSES: "REJECT_NEW_CONSULTATION_SUCSES",
    REJECT_NEW_CONSULTATION_ERROR: "REJECT_NEW_CONSULTATION_ERROR",
    // request Question
    REQUEST_QUESTION_LOADING: "REQUEST_QUESTION_LOADING",
    REQUEST_QUESTION_SUCSES: "REQUEST_QUESTION_SUCSES",
    REQUEST_QUESTION_ERROR: "REQUEST_QUESTION_ERROR",
    // request Scan
    REQUEST_SCAN_LOADING: "REQUEST_SCAN_LOADING",
    REQUEST_SCAN_SUCSES: "REQUEST_SCAN_SUCSES",
    REQUEST_SCAN_ERROR: "REQUEST_SCAN_ERROR",
    // add medical report
    ADD_MEDICAL_REPORT_LOADING: "ADD_MEDICAL_REPORT_LOADING",
    ADD_MEDICAL_REPORT_SUCSES: "ADD_MEDICAL_REPORT_SUCSES",
    ADD_MEDICAL_REPORT_ERROR: "ADD_MEDICAL_REPORT_ERROR",
  },
  appointmentTypes: {
    // GET APPOINTMENTS lIST
    GET_APPOINTMENTS_LOADING: "GET_APPOINTMENTS_LOADING",
    GET_APPOINTMENTS_SUCSES: "GET_APPOINTMENTS_SUCSES",
    GET_APPOINTMENTS_ERROR: "GET_APPOINTMENTS_ERROR",
  },
  reportTypes: {
    //GET reports
    GET_REPORTS_LOADING: "GET_REPORTS_LOADING",
    GET_REPORTS_SUCSES: "GET_REPORTS_SUCSES",
    GET_REPORTS_ERROR: "GET_REPORTS_ERROR",
  },
  medicalRecord: {
    // allergy
    GET_SINGLE_ALLERGY_LOADING: "GET_SINGLE_ALLERGY_LOADING",
    GET_SINGLE_ALLERGY_SUCSES: "GET_SINGLE_ALLERGY_SUCSES",
    GET_SINGLE_ALLERGY_ERROR: "GET_SINGLE_ALLERGY_ERROR",
    // condition
    GET_SINGLE_CONDITION_LOADING: "GET_SINGLE_CONDITION_LOADING",
    GET_SINGLE_CONDITION_SUCSES: "GET_SINGLE_CONDITION_SUCSES",
    GET_SINGLE_CONDITION_ERROR: "GET_SINGLE_CONDITION_ERROR",
    // ctScan
    GET_SINGLE_CTSCAN_LOADING: "GET_SINGLE_CTSCAN_LOADING",
    GET_SINGLE_CTSCAN_SUCSES: "GET_SINGLE_CTSCAN_SUCSES",
    GET_SINGLE_CTSCAN_ERROR: "GET_SINGLE_CTSCAN_ERROR",
    // ECG
    GET_SINGLE_ECG_LOADING: "GET_SINGLE_ECG_LOADING",
    GET_SINGLE_ECG_SUCSES: "GET_SINGLE_ECG_SUCSES",
    GET_SINGLE_ECG_ERROR: "GET_SINGLE_ECG_ERROR",
    // Lab result
    GET_SINGLE_LABRESULT_LOADING: "GET_SINGLE_LABRESULT_LOADING",
    GET_SINGLE_LABRESULT_SUCSES: "GET_SINGLE_LABRESULT_SUCSES",
    GET_SINGLE_LABRESULT_ERROR: "GET_SINGLE_LABRESULT_ERROR",
    // MEDICAL REPORT
    GET_SINGLE_MEDICALREPORT_LOADING: "GET_SINGLE_MEDICALREPORT_LOADING",
    GET_SINGLE_MEDICALREPORT_SUCSES: "GET_SINGLE_MEDICALREPORT_SUCSES",
    GET_SINGLE_MEDICALREPORT_ERROR: "GET_SINGLE_MEDICALREPORT_ERROR",
    // MRI
    GET_SINGLE_MRI_LOADING: "GET_SINGLE_MRI_LOADING",
    GET_SINGLE_MRI_SUCSES: "GET_SINGLE_MRI_SUCSES",
    GET_SINGLE_MRI_ERROR: "GET_SINGLE_MRI_ERROR",
    // XRAY
    GET_SINGLE_XRAY_LOADING: "GET_SINGLE_XRAY_LOADING",
    GET_SINGLE_XRAY_SUCSES: "GET_SINGLE_XRAY_SUCSES",
    GET_SINGLE_XRAY_ERROR: "GET_SINGLE_XRAY_ERROR",
    // SINGLE RADIOLOGY
    GET_SINGLE_RADIOLOGY_LOADING: "GET_SINGLE_RADIOLOGY_LOADING",
    GET_SINGLE_RADIOLOGY_SUCSES: "GET_SINGLE_RADIOLOGY_SUCSES",
    GET_SINGLE_RADIOLOGY_ERROR: "GET_SINGLE_RADIOLOGY_ERROR",
    //RADIOLOGY list
    GET_RADIOLOGY_LOADING: "GET_RADIOLOGY_LOADING",
    GET_RADIOLOGY_SUCSES: "GET_RADIOLOGY_SUCSES",
    GET_RADIOLOGY_ERROR: "GET_RADIOLOGY_ERROR",
    //allergy list
    GET_ALLERGY_LOADING: "GET_ALLERGY_LOADING",
    GET_ALLERGY_SUCSES: "GET_ALLERGY_SUCSES",
    GET_ALLERGY_ERROR: "GET_ALLERGY_ERROR",
    //CONDITIONS list
    GET_CONDITIONS_LOADING: "GET_CONDITIONS_LOADING",
    GET_CONDITIONS_SUCSES: "GET_CONDITIONS_SUCSES",
    GET_CONDITIONS_ERROR: "GET_CONDITIONS_ERROR",
    //MEDICAL REPORTS list
    GET_MEDICAL_REPORTS_LOADING: "GET_MEDICAL_REPORTS_LOADING",
    GET_MEDICAL_REPORTS_SUCSES: "GET_MEDICAL_REPORTS_SUCSES",
    GET_MEDICAL_REPORTS_ERROR: "GET_MEDICAL_REPORTS_ERROR",
    //X RAY list
    GET_XRAYS_LOADING: "GET_XRAYS_LOADING",
    GET_XRAYS_SUCSES: "GET_XRAYS_SUCSES",
    GET_XRAYS_ERROR: "GET_XRAYS_ERROR",
    //LAB RESULT list
    GET_LAB_RESULTS_LOADING: "GET_LAB_RESULTS_LOADING",
    GET_LAB_RESULTS_SUCSES: "GET_LAB_RESULTS_SUCSES",
    GET_LAB_RESULTS_ERROR: "GET_LAB_RESULTS_ERROR",
    //ECG list
    GET_ECGS_LOADING: "GET_ECGS_LOADING",
    GET_ECGS_SUCSES: "GET_ECGS_SUCSES",
    GET_ECGS_ERROR: "GET_ECGS_ERROR",
  },
};
