import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import FirstView from "../../Repeated-Component/FirstView";
import MRI from "../../../../../../../../assets/medical-record/requests/MRI.png";
import { connect } from "react-redux";
import { getRadiologyList } from "../../../../../../../../store/actions";
import { useToast } from "@chakra-ui/react";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const ListingRadiology = ({ patient, getRadiologyList, RadiologyList }) => {
  const { url } = useRouteMatch();
  let query = useQuery().get("page");
  const toast = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Radiology, setRadiology] = useState([]);
  const [filterRadiology, setFilterRadiology] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    getRadiologyList(toast, history, patient.id, query);
  }, [getRadiologyList, query]);

  useEffect(() => {
    if (RadiologyList) {
      const { loading, error, data } = RadiologyList;
      setLoading(loading);
      setError(error);
      if (data) {
        setRadiology(data.data);
        setFilterRadiology(data.data);
        setTotalResults(data.meta.total);
        setCuttentPage(data.meta.current_page);
      }
    }
  }, [RadiologyList]);
  return (
    <div>
      <FirstView
        loading={loading}
        error={error}
        headerName="MRI / CT Scan"
        buttonLink={`${url}/request-radiology`}
        cardLink={`${url}/view-radiology`}
        data={Radiology}
        filterData={filterRadiology}
        setFilter={setFilterRadiology}
        conditionImage={MRI}
        pages={pagesNumber}
        nextPage={nextPage}
        prevPage={prevPage}
        specificPage={specificPage}
        currentPage={currentPage}
        totalResults={totalResults}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    RadiologyList: state.RadiologyList,
  };
};
export default connect(mapStateToProps, { getRadiologyList })(ListingRadiology);
