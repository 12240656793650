import React from "react";

import { Text, Button, Box, useColorMode } from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { style } from "../../../../../../global/style";
import {
  pageTransition,
  pageVariants,
} from "../../../../../../global/Animations/Animations";
import { motion } from "framer-motion";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../../../global/changeColor";

const EmptyMedical = ({
  name,
  image,
  LinkTo,
  SingleConsultation,
  question,
}) => {
  const { colorMode } = useColorMode();
  return (
    <motion.div
      className="my-3"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Text
        fontSize="2xl"
        fontWeight="semibold"
        color={checkColorMode(colorMode)}
      >
        {name}
      </Text>
      <Box
        bg={checkBgColorMode(colorMode)}
        className="col-md-12 p-3 rounded row m-0 my-3 align-items-center justify-content-center"
      >
        <Box className="d-flex justify-content-center mt-5">
          <img src={image} alt={name} />
        </Box>

        <Box className="text-center">
          <Text fontSize={"2xl"} fontWeight="bold" className="mt-5">
            {name}
          </Text>
          <Text fontSize={"sm"} className="mt-1" color={style.smallTextGray}>
            {question ? "Ask a question" : `Request ${name}`}
          </Text>
        </Box>
        <Button
          as={Link}
          to={`${LinkTo}`}
          bg={checkColorMode(colorMode)}
          color="white"
          _hover={{ bg: checkColorModeHover(colorMode), color: "white" }}
          size="lg"
          className="mb-5 mt-4"
          disabled={
            SingleConsultation.status === "done" ||
            SingleConsultation.status === "finished_appointment" ||
            SingleConsultation.status === "new"
          }
          pointerEvents={
            SingleConsultation.status === "done" ||
            SingleConsultation.status === "finished_appointment" ||
            SingleConsultation.status === "new"
              ? "none"
              : ""
          }
        >
          <span className="me-2">
            <i className="fas fa-plus"></i>
          </span>{" "}
          Request
        </Button>
      </Box>
    </motion.div>
  );
};

export default EmptyMedical;
