import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { InputControl } from "formik-chakra-ui";
import { forgetPassword } from "../../store/actions";
import {
  Button,
  useToast,
  Circle,
  Box,
  useColorMode,
  Text,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../global/changeColor";

const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});
const ForgetPassword = ({ forgetPassword, msg }) => {
  const toast = useToast();
  const history = useHistory();
  const { colorMode } = useColorMode();
  const [counter, setCounter] = useState(1);
  const [initValue] = useState({
    email: "",
  });
  const onFormSubmit = (value) => {
    forgetPassword(value.email, setCounter, toast);
  };

  useEffect(() => {
    if (msg === "Success") setCounter(2);
  }, [msg]);
  return (
    <div className="ForgetPassword mt-5 pb-5">
      <div className="row justify-content-center m-0">
        <div className="col-lg-5 col-md-7 col-11">
          {counter < 3 ? (
            <Formik
              initialValues={initValue}
              enableReinitialize
              validationSchema={ForgetPasswordSchema}
              onSubmit={onFormSubmit}
            >
              <Box
                as={Form}
                bg={checkBgColorMode(colorMode)}
                className=" rounded shadow-sm p-5"
              >
                {counter === 1 ? (
                  <div className="step-1">
                    <div className="Forget-header">
                      <h5 className="fw-bold">Reset Password</h5>
                      <p className="my-3 " style={{ fontSize: "14px" }}>
                        Please enter your email,you will recive an email with a
                        link to reset your password shortly
                      </p>
                    </div>
                    <div className="input-group mt-2">
                      <InputControl
                        name="email"
                        label="E-mail"
                        inputProps={{ placeholder: "E-mail" }}
                      />
                    </div>

                    <div className=" ">
                      <Button
                        type="submit"
                        h="54px"
                        className="rounded mt-3"
                        bg={checkColorMode(colorMode)}
                        color={"white"}
                        _hover={{ background: checkColorModeHover(colorMode) }}
                        w="100%"
                        fontWeight="bold"
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                ) : null}

                {counter === 2 ? (
                  <div className="step-2">
                    <div className="Forget-header text-center">
                      <Box
                        w="100%"
                        className="d-flex justify-content-center my-5"
                      >
                        <Circle
                          size="130px"
                          bg={"#42B79E"}
                          color="white"
                          fontSize="80px"
                        >
                          <i className="fas fa-check"></i>
                        </Circle>
                      </Box>

                      <h3 className="h3 "> Code sent successfully!</h3>
                      <Text
                        color="gray.400"
                        className="my-3 fw-bold text-secondary"
                        style={{ fontSize: "14px" }}
                      >
                        Please check your email for the code you will need to
                        reset your password
                      </Text>
                    </div>

                    <div className="">
                      <Button
                        h="54px"
                        className="rounded mt-3"
                        bg={checkColorMode(colorMode)}
                        color={"white"}
                        _hover={{ background: checkColorModeHover(colorMode) }}
                        w="100%"
                        fontWeight="bold"
                        onClick={() => {
                          history.push("/login");
                          setCounter(1);
                        }}
                      >
                        Done
                      </Button>
                    </div>
                  </div>
                ) : null}
              </Box>
            </Formik>
          ) : null}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    msg: state.forgetPassword.user,
  };
};
export default connect(mapStateToProps, { forgetPassword })(ForgetPassword);
