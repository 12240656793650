import React from "react";
import { Field } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useColorMode } from "@chakra-ui/react";

const TelephoneInput = (props) => {
  const { colorMode } = useColorMode();
  return (
    <>
      <label htmlFor={props.name} className="chakra-form__label mb-2">
        Phone Number
      </label>
      <Field name={props.name} className="">
        {({ field, form: { touched, errors } }) => (
          <PhoneInput
            {...field}
            inputProps={{
              className: `${
                colorMode === "dark" ? "css-xpongc" : "css-1c6j008"
              }  ps-5`,
              name: field.name,
              onBlur: field.onBlur,
              onChange: field.onChange,
            }}
            country={"gb"}
            enableSearch
            disableSearchIcon
            dropdownClass={
              colorMode === "dark"
                ? "bg-dark tex-white input-drop-down-phone input-drop-down-dark"
                : "bg-white input-drop-down-phone input-drop-down-light"
            }
            buttonClass={
              colorMode === "dark" ? "bg-dark input-drop-down-button-dark" : ""
            }
            searchClass={`input-drop-down-search ${
              colorMode === "dark"
                ? "bg-dark input-drop-down-search-dark"
                : "bg-white"
            }`}
          />
        )}
      </Field>
    </>
  );
};

export default TelephoneInput;
