import { types } from "../types/types";
const reportTypes = types.reportTypes;

const INIT_REPORTS_STATE = {
  loading: false,
  error: null,
  data: null,
};
export const getReportsListReducer = (
  state = INIT_REPORTS_STATE,
  { type, payload }
) => {
  switch (type) {
    case reportTypes.GET_REPORTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case reportTypes.GET_REPORTS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case reportTypes.GET_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
