import React from "react";
import { Box, Text, useColorMode } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import {
  checkColorMode,
  checkBgColorMode,
  checkColorModeHover,
} from "../global/changeColor";
import emptyConsultationsSvg from "../../assets/svg-icons/empty-consultations.svg";
const EmptyConsultations = ({ history }) => {
  const { colorMode } = useColorMode();
  return (
    <div className="container mt-5">
      <div className="row m-0 justify-content-center">
        <Box
          bg={checkBgColorMode(colorMode)}
          className="col-md-8 col-12 p-3 pb-5 rounded shadow m-0 "
        >
          <img
            className="mx-auto"
            width={250}
            src={emptyConsultationsSvg}
            alt="Empty Consultations"
          />

          <Text
            color={colorMode === "dark" ? "white" : "black"}
            fontWeight="bold"
            fontSize="xl"
            className="text-center mb-4"
          >
            There are no new consultations
          </Text>
          <Text
            color="smallTextGray"
            fontSize="sm"
            className="text-center mb-4"
          >
            There are no new consultations now, Once a patient request a new
            consultation we will let you know
          </Text>
          <Box className="col-12 text-center">
            <Button
              className="rounded"
              w="60%"
              size="lg"
              bg={checkColorMode(colorMode)}
              color={"#fff"}
              _hover={{ color: "#fff", bg: checkColorModeHover(colorMode) }}
              as={Link}
              to={"/active_consultations"}
            >
              View Active Consultations
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default EmptyConsultations;
