import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import FirstView from "../../Repeated-Component/FirstView";
import ques from "../../../../../../../../assets/medical-record/requests/ques.jpg";
import { connect } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { getQuestionList } from "../../../../../../../../store/actions";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ListingQuestion = ({ patient, getQuestionList, QuestionList }) => {
  let { id } = useParams();
  const { url } = useRouteMatch();
  let query = useQuery().get("page");
  const toast = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Questions, setQuestions] = useState([]);
  const [filterQuestions, setFilterQuestions] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    getQuestionList(id, query);
  }, [getQuestionList, query]);

  useEffect(() => {
    if (QuestionList) {
      const { loading, error, data } = QuestionList;
      setLoading(loading);
      setError(error);
      if (data) {
        setQuestions(data.data);
        setFilterQuestions(data.data);
        setTotalResults(data.meta.total);
        setCuttentPage(data.meta.current_page);
      }
    }
  }, [QuestionList]);
  return (
    <div>
      <FirstView
        loading={loading}
        error={error}
        headerName="Questions"
        buttonLink={`${url}/request-question`}
        cardLink={`${url}/view-question`}
        data={Questions}
        filterData={filterQuestions}
        setFilter={setFilterQuestions}
        conditionImage={ques}
        pages={pagesNumber}
        nextPage={nextPage}
        prevPage={prevPage}
        specificPage={specificPage}
        currentPage={currentPage}
        totalResults={totalResults}
        question={true}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    QuestionList: state.QuestionList,
  };
};

export default connect(mapStateToProps, {
  getQuestionList,
})(ListingQuestion);
