// import { createBrowserHistory } from "history";
import isAuthenticated from "./utils/isAuthenticated";
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import "./App.scss";
import Header from "./components/header/Header";
import Footer from "./components/Footer/Footer";
import RenderRoute from "./Routes/routes";
import { connect } from "react-redux";
import {
  getConsultantProfile,
  getUnReadNotifications,
  gitNotificationList,
} from "./store/actions";
import theme from "./components/global/Theme";

// const getEndTime = (endTime) => {
//   if (endTime) {
//     const end = moment.unix(endTime).format();
//     const now = moment(new Date());
//     const compaire = moment(end);
//     const fullDate = moment.duration(now.diff(compaire));
//     console.log(fullDate);
//   }
// };
function App({
  getConsultantProfile,
  consultant,
  user,
  getUnReadNotifications,
  gitNotificationList,
}) {
  const [isAuth, setIsAuth] = useState(false);
  const doctorId = localStorage.getItem("doctorId");
  const token = localStorage.getItem("doctorToken");

  useEffect(() => {
    if (isAuthenticated()) {
      setIsAuth(isAuthenticated());
    }
  }, [isAuthenticated()]);

  useEffect(() => {
    if (doctorId && token) {
      getConsultantProfile();
      gitNotificationList();
      getUnReadNotifications();
    }
  }, [user, doctorId, token, getConsultantProfile]);

  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />

        <header>
          <Header isAuth={isAuth} setIsAuth={setIsAuth} />
        </header>
        <main className="main">
          <RenderRoute />
        </main>
        <footer>
          <Footer />
        </footer>
      </ChakraProvider>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
  return {
    consultant: state.consultant,
    user: state.login,
  };
};
export default connect(mapStateToProps, {
  getConsultantProfile,
  getUnReadNotifications,
  gitNotificationList,
})(App);
