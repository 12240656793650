import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch, Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import {
  getActiveConsultations,
  getConsultationsTyeps,
} from "../../store/actions";

import ActiveConsulttationList from "./ActiveConsulttationList";
import AccountNotActivate from "../AccountNotActivate/AccountNotActivate";

const ActiveConsultations = ({ consultant }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { path } = useRouteMatch();
  const loaction = useLocation();
  useEffect(() => {
    const { loading, error, data } = consultant;
    setLoading(loading);
    setError(error);
  }, [consultant]);
  const renderView = () => {
    if (loading) {
      return "Loading ...";
    }
    if (error) {
      return "Error";
    }
    if (consultant.data) {
      return (
        <motion.div className="container-fluid Consultations">
          {consultant.data && consultant.data.verifiedAt ? (
            <AnimatePresence exitBeforeEnter>
              <Switch location={loaction} key={loaction.pathname}>
                <Route
                  exact
                  path={`${path}`}
                  component={ActiveConsulttationList}
                />
              </Switch>
            </AnimatePresence>
          ) : (
            <AccountNotActivate />
          )}
        </motion.div>
      );
    } else {
      return "";
    }
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    consultant: state.consultant,
  };
};
export default connect(mapStateToProps, {
  getActiveConsultations,
  getConsultationsTyeps,
})(ActiveConsultations);
