import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import NotFound from "../../../../../../NotFound/NotFound";
import ListingQuestion from "./Children/listingQuestion";
import RequestQuestions from "./Children/requestQuestion";
import ViewQuestion from "./Children/viewOneQuestion";

const Questions = ({ patient }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <ListingQuestion patient={patient} />}
      />
      <Route
        exact
        path={`${path}/request-question`}
        component={RequestQuestions}
      />
      <Route
        path={`${path}/view-question/:medicalId`}
        render={() => <ViewQuestion patient={patient} />}
      />
      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};

export default Questions;
