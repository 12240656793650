import React from "react";

import {
  useRouteMatch,
  Switch,
  Route,
  useLocation,
  NavLink,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Box, Circle, Image, Text, useColorMode } from "@chakra-ui/react";
import {
  pageTransition,
  pageVariants,
} from "../../../../global/Animations/Animations";
import {
  checkBgColorMode,
  checkColorMode,
} from "../../../../global/changeColor";
import NavLinks, { navLinks } from "./Children/navLinks/NavLinks";

import NotFound from "../../../../NotFound/NotFound";
import "./MedicalRecord.scss";
import Conditions from "./Children/Conditions/Conditions";
import Allergies from "./Children/Allergies/Allergies";
import Ecg from "./Children/Ecg/Ecg";
import LabResult from "./Children/LabResult/LabResult";
import MedicalReport from "./Children/MedicalReport/MedicalReport";
import Questions from "./Children/Questions/Questions";
import Radiology from "./Children/Radiology/Radiology";
import Xray from "./Children/Xray/Xray";
import QuestionImg from "../../../../../assets/svg-icons/Question.svg";

const MedicalRecord = ({ patient, Consultation }) => {
  const { path, url } = useRouteMatch();
  const { colorMode } = useColorMode();
  const location = useLocation();
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Box
        bg={checkBgColorMode(colorMode)}
        className="col-12 shadow-sm rounded p-3 pb-0 row m-0 align-items-center my-2 mt-4"
      >
        <Text
          className="my-2"
          fontSize="2xl"
          fontWeight="semibold"
          color={checkColorMode(colorMode)}
        >
          Medical Record
        </Text>
        <div className="d-none d-lg-flex">
          <NavLinks consultation={Consultation} />
        </div>
        <div className="d-xl-none d-lg-none d-xl-flex d-flex flex-wrap medical-small-wrap">
          {navLinks.map((item, index) => (
            <Box w="200px" className="text-start my-1" key={index}>
              <NavLink
                activeClassName={
                  colorMode === "dark"
                    ? "midical-active-small-dark"
                    : "midical-active-small"
                }
                to={item.linkTo}
                className={`rounded fw-600 p-2 mx-2 midical-nav-small d-flex align-items-center' ${
                  colorMode === "dark" ? "midical-nav-small-dark" : ""
                }`}
              >
                <Circle
                  size="30px"
                  color="#fff"
                  className="me-2 circle bg-secondary"
                  transition="0.2s ease-in-out"
                >
                  {item.navImg ? (
                    <Image src={item.navImg} alt={item.navName} maxW="50%" />
                  ) : (
                    <i className={item.navIcon}></i>
                  )}
                </Circle>
                {item.navName}
              </NavLink>
            </Box>
          ))}
          {Consultation?.status === "new" ? null : (
            <Box w="200px" className="text-start my-1">
              <NavLink
                activeClassName={
                  colorMode === "dark"
                    ? "midical-active-small-dark"
                    : "midical-active-small"
                }
                to={`${url}/question`}
                className={`rounded fw-600 p-2 mx-2 midical-nav-small d-flex align-items-center' ${
                  colorMode === "dark" ? "midical-nav-small-dark" : ""
                }`}
              >
                <Circle
                  size="30px"
                  color="#fff"
                  className="me-2 circle bg-secondary"
                  transition="0.2s ease-in-out"
                >
                  <Image src={QuestionImg} alt={"QuestionImg"} maxW="70%" />
                </Circle>
                Question
              </NavLink>
            </Box>
          )}
        </div>
      </Box>
      <div className="col-12 my-2">
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route
              path={`${path}/conditions`}
              render={() => (
                <Conditions patient={patient} Consultation={Consultation} />
              )}
            />
            <Route
              path={`${path}/medical-report`}
              render={() => (
                <MedicalReport patient={patient} Consultation={Consultation} />
              )}
            />
            <Route
              path={`${path}/lab-result`}
              render={() => (
                <LabResult patient={patient} Consultation={Consultation} />
              )}
            />
            <Route
              path={`${path}/allergies`}
              render={() => (
                <Allergies patient={patient} Consultation={Consultation} />
              )}
            />
            <Route
              path={`${path}/mri-ct scan`}
              render={() => (
                <Radiology patient={patient} Consultation={Consultation} />
              )}
            />
            <Route
              path={`${path}/ecg`}
              render={() => (
                <Ecg patient={patient} Consultation={Consultation} />
              )}
            />
            <Route
              path={`${path}/x-ray`}
              render={() => (
                <Xray patient={patient} Consultation={Consultation} />
              )}
            />
            <Route
              path={`${path}/question`}
              render={() => (
                <Questions patient={patient} consultation={Consultation} />
              )}
            />
            <Route path="*" exact={true} component={NotFound} />
          </Switch>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default MedicalRecord;
