import React, { useState, useEffect } from "react";
import { InputControl } from "formik-chakra-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Text, useToast, useColorMode, Box } from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  postConsultantVerifyMobile,
  resendConsultantVerifyMobile,
} from "../../store/actions";
import { checkBgColorMode, checkColorMode } from "../global/changeColor";

const SignUpSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .min(6, "Please Entar a valid code")
    .max(6, "verificationCode must be at most 6 number")
    .required("Required"),
});

const VerifyMobile = ({
  history,
  resendConsultantVerifyMobile,
  resendVerifyMobile,
  postConsultantVerifyMobile,
}) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [user, setuser] = useState();
  const [initValue] = useState({
    verificationCode: "",
  });

  useEffect(() => {
    const registrUser = JSON.parse(localStorage.getItem("register"));
    if (registrUser) {
      setuser(registrUser);
    } else {
      history.push("/signup");
      toast({
        title: "Complete your registration first",
        status: "success",
        duration: 7000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, []);

  const onFormSubmit = (value) => {
    const data = {
      verificationCode: String(value.verificationCode),
    };
    postConsultantVerifyMobile(data, toast, history);
  };

  const resendVerify = () => {
    return resendConsultantVerifyMobile(toast);
  };

  return (
    <div className="row justify-content-center mt-5">
      <div className="col-md-5 col-12">
        <Box bg={checkBgColorMode(colorMode)} className="rounded shadow-sm p-5">
          <Formik
            initialValues={initValue}
            validationSchema={SignUpSchema}
            enableReinitialize
            onSubmit={onFormSubmit}
          >
            <div>
              <Form>
                <div className="d-flex justify-content-center flex-wrap">
                  <div
                    className="text-white d-flex justify-content-center align-items-center rounded-circle"
                    style={{
                      width: "130px",
                      height: "130px",
                      backgroundColor: "#42B79E",
                      fontSize: "60px",
                    }}
                  >
                    <span>
                      <i className="fas fa-check"></i>
                    </span>
                  </div>
                  <div className="w-100 text-center">
                    <Text fontSize="2xl" className="fw-bold mt-4">
                      Verify your mobile Number
                    </Text>
                    <Text className="fw-600 mt-1" color={"gray.400"}>
                      We’ve sent you a verification code via SMS to the number{" "}
                      <span className="me-2">{user ? user.mobile : ""}</span>
                      <Button
                        className="ms-1"
                        color={checkColorMode(colorMode)}
                        onClick={() => {
                          history.push("/signup?edit=mobile");
                        }}
                      >
                        <i className="fas fa-pen"></i>
                      </Button>
                    </Text>
                  </div>
                </div>
                <div className="first-four">
                  <div className="input-group mt-2">
                    <InputControl
                      name="verificationCode"
                      inputProps={{
                        placeholder: "Enter verification code",
                        type: "number",
                      }}
                    />
                  </div>
                </div>

                <div className="buttonSubmit row">
                  <div className="col-12">
                    <Button
                      type="submit"
                      className="w-100 mt-3 fw-bold"
                      size="lg"
                      bg="primary"
                      color="#fff"
                      _hover={{ bg: "primary_hover" }}
                    >
                      Verify
                    </Button>
                  </div>
                  <div className="col-12 text-center my-3">
                    <h1>Didn't receive code?</h1>
                  </div>
                  <div className="col-12">
                    <Button
                      type="button"
                      color="#fff"
                      size="lg"
                      bg={"secondary"}
                      _hover={{ bg: "secondary_hover" }}
                      className="w-100"
                      onClick={resendVerify}
                      isLoading={resendVerifyMobile.loading}
                    >
                      Resend
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Formik>
        </Box>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    VerifyMobile: state.VerifyMobile,
    resendVerifyMobile: state.resendVerifyMobile,
  };
};
export default connect(mapStateToProps, {
  postConsultantVerifyMobile,
  resendConsultantVerifyMobile,
})(VerifyMobile);
