import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import NotFound from "../../../../../../NotFound/NotFound";
import ListingCondition from "./Children/listingCondition";
import RequestCondition from "./Children/requestCondition";
import ViewOneCondition from "./Children/viewOneCondition";

const Conditions = ({ condition, patient }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <ListingCondition patient={patient} />}
      />
      <Route
        exact
        path={`${path}/request-conditions`}
        component={RequestCondition}
      />
      <Route
        path={`${path}/view-conditions/:medicalId`}
        render={() => <ViewOneCondition patient={patient} />}
      />
      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};

export default Conditions;
