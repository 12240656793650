import React, { useEffect } from "react";
import Card from "./Card";
import HeaderSection from "./headerSection";
import { motion } from "framer-motion";
import {
  pageVariants,
  pageTransition,
} from "../../../../../../global/Animations/Animations";
import EmptyMedical from "./empetyMedical";
import Pagination from "../../../../../../Pagination/Pagination";
import { Box, useColorMode } from "@chakra-ui/react";
import { connect } from "react-redux";

const FirstView = ({
  loading,
  error,
  headerName,
  data,
  buttonLink,
  cardLink,
  filterData,
  setFilter,
  conditionImage,
  pages,
  nextPage,
  prevPage,
  specificPage,
  currentPage,
  totalResults,
  question,
  SingleConsultation,
}) => {
  useEffect(() => {
    renderView();
  }, []);
  const onInputChange = (e) => {
    let term = e.target.value;
    if (data && filterData) {
      if (term.trim().length > 0) {
        const filterD = data.filter((one) =>
          one.title
            ? one.title.toLowerCase().includes(term.trim().toLowerCase())
            : one.value.toLowerCase().includes(term.trim().toLowerCase())
        );
        if (filterD.length) {
          setFilter(filterD);
        } else {
          setFilter([{ noMatch: "There are no matching records." }]);
        }
      } else {
        setFilter(data);
      }
    } else {
      return null;
    }
  };

  const renderView = () => {
    if (loading) {
      return " ";
    }
    if (error) {
      return " ";
    }
    if (data && data.length) {
      return (
        <>
          <HeaderSection
            headerName={headerName}
            onInputChange={onInputChange}
            buttonLink={buttonLink}
            question={question}
            SingleConsultation={SingleConsultation}
          />
          <motion.div>
            {filterData.map((item, i) => (
              <Box key={i}>
                {!item.noMatch ? (
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    transition={{ duration: 0.6, delay: i * 0.1 }}
                  >
                    <Card item={item} url={`${cardLink}`} />
                  </motion.div>
                ) : (
                  <Box
                    className="text-center"
                    fontWeight="semibold"
                    fontSize="xl"
                  >
                    {item.noMatch}
                  </Box>
                )}
              </Box>
            ))}
          </motion.div>
          {totalResults && totalResults > 15 ? (
            <Pagination
              pages={pages}
              nextPage={nextPage}
              prevPage={prevPage}
              specificPage={specificPage}
              currentPage={currentPage}
            />
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <EmptyMedical
          name={headerName}
          image={conditionImage}
          LinkTo={buttonLink}
          SingleConsultation={SingleConsultation}
          question={question}
        />
      );
    }
  };
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {renderView()}
    </motion.div>
  );
};
const mapStateToProps = (state) => {
  return {
    SingleConsultation: state.SingleConsultation.data,
  };
};
export default connect(mapStateToProps)(FirstView);
