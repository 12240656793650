import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  InputGroup,
  Input,
  InputLeftElement,
  Text,
  Avatar,
  Circle,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import moment from "moment";
import { Link, useRouteMatch } from "react-router-dom";
import { motion } from "framer-motion";
import chest from "../../assets/svg-icons/chest.svg";
import patientTest from "../../assets/patientTest.png";
import {
  checkColorMode,
  checkBgColorMode,
  checkWhiteAndBlackColor,
} from "../global/changeColor";
import EmptyActiveConsultations from "./EmptyActiveConsultations.js";
import { check_status_string } from "../NewConsultations/NewConsulttationList";
import { pageTransition, pageVariants } from "../global/Animations/Animations";
import {
  getActiveConsultations,
  getConsultationsTyeps,
} from "../../store/actions";
import { GetSpecialityIcon } from "../global/GetSpecialityIcon.js";

const ActiveConsulttationList = ({
  getActiveConsultations,
  getConsultationsTyeps,
  ActiveConsultationsList,
  consultationTypes,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [consultation, setConsultation] = useState([]);
  const [filterConsultation, setFilterConsultation] = useState([]);
  const [ConsultationType, setConsultationType] = useState([]);

  let { url } = useRouteMatch();
  const { colorMode } = useColorMode();
  const filterStatus = (status) => {
    switch (status) {
      case "assigned":
        const assignedData = consultation.filter(
          (element) => element.status == "assigned"
        );
        setFilterConsultation(assignedData);
        break;

      case "pending tests":
        const pendingData = consultation.filter(
          (element) => element.status == "pending tests"
        );
        setFilterConsultation(pendingData);
        break;
      default:
        setFilterConsultation(consultation);
        break;
    }
  };

  useEffect(() => {
    getActiveConsultations();
    getConsultationsTyeps();
  }, [getActiveConsultations, getConsultationsTyeps]);

  useEffect(() => {
    if (consultationTypes && consultationTypes.data) {
      setConsultationType(consultationTypes.data);
    }
  }, [consultationTypes]);

  useEffect(() => {
    const { loading, error, data } = ActiveConsultationsList;
    setLoading(loading);
    setError(error);

    if (data && data.data) {
      const list = data.data;
      const notDone = list.filter((item) => item.status !== "done");
      setConsultation(notDone);
      setFilterConsultation(notDone);
    }
  }, [ActiveConsultationsList]);

  const renderView = () => {
    if (loading) {
      return "";
    }
    if (error) {
      return "Error";
    }
    if (consultation.length) {
      return (
        <motion.div
          className="container pb-5"
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <div className="row">
            <div className="h4 p-0 py-3 fw-bold d-flex flex-wrap align-items-center justify-content-between">
              <Text
                fontSize="2xl"
                className="col-md-4 my-4"
                fontWeight="semibold"
              >
                Active Consultations
              </Text>

              <div className="col-md-6 col-12 d-flex flex-wrap align-items-center ">
                <div className="input-search col-md-7 col-12 me-auto">
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <Text
                          className="icon-search"
                          color={checkColorMode(colorMode)}
                        >
                          <i className="fas fa-search"></i>
                        </Text>
                      }
                    />
                    <Input
                      w="100%"
                      placeholder="Search"
                      size="lg"
                      className="ps-5"
                      bg={checkBgColorMode(colorMode)}
                      onChange={(e) => {
                        let term = e.target.value;
                        if (term.trim().length > 0) {
                          const filterD = consultation.filter(
                            (one) =>
                              one.symptoms
                                .toLowerCase()
                                .includes(term.trim().toLowerCase()) ||
                              one.patient.fullName
                                .toLowerCase()
                                .includes(term.trim().toLowerCase())
                          );
                          setFilterConsultation(filterD);
                        } else {
                          setFilterConsultation(consultation);
                        }
                      }}
                    />
                  </InputGroup>
                </div>

                <div className="col-md-4 col-sm-4 col-5 my-2">
                  <Menu>
                    <MenuButton
                      w="100%"
                      as={Button}
                      color={checkColorMode(colorMode)}
                      bg={
                        colorMode === "dark"
                          ? "secondaryOpacity"
                          : "primaryOpacity"
                      }
                      size="lg"
                      _hover={{
                        bg:
                          colorMode === "dark"
                            ? "secondary_hover_Opacity"
                            : "primaryOpacity_hover",
                      }}
                      _active={{
                        bg:
                          colorMode === "dark"
                            ? "secondary_hover_Opacity"
                            : "primaryOpacity_hover",
                      }}
                    >
                      <span className="me-2">
                        <i className="fas fa-filter"></i>
                      </span>{" "}
                      Filter
                    </MenuButton>
                    <MenuList className="shadow p-2 " minW={170}>
                      <MenuItem
                        p={2}
                        fontSize="17px"
                        className="text-center rounded"
                        color={checkColorMode(colorMode)}
                        _hover={{
                          backgroundColor:
                            colorMode === "dark"
                              ? "secondary_hover_Opacity"
                              : "primaryOpacity_hover",
                        }}
                        onClick={() => filterStatus("pending tests")}
                      >
                        <Text fontWeight="semibold" w={"100%"}>
                          Pending for test
                        </Text>
                      </MenuItem>
                      <MenuItem
                        p={2}
                        fontSize="17px"
                        className="text-center rounded"
                        color={checkColorMode(colorMode)}
                        _hover={{
                          backgroundColor:
                            colorMode === "dark"
                              ? "secondary_hover_Opacity"
                              : "primaryOpacity_hover",
                        }}
                        onClick={() => filterStatus("assigned")}
                      >
                        <Text fontWeight="semibold" w={"100%"}>
                          {" "}
                          Assigned
                        </Text>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
            </div>

            {filterConsultation.map((item, index) => (
              <motion.div
                key={index}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                className="p-0"
              >
                <Box
                  as={Link}
                  to={`/consultation/${item.id}`}
                  bg={checkBgColorMode(colorMode)}
                  className="m-0 mb-2 shadow-sm row flex-wrap align-items-center p-3 pe-0 rounded "
                  _hover={{ color: checkWhiteAndBlackColor(colorMode) }}
                >
                  <div className="col-lg-4 my-2">
                    <Text fontSize="lg" fontWeight={700}>
                      {item.symptoms}
                    </Text>
                    <Text color="smallTextGray" fontSize="12px">
                      {moment
                        .unix(item.createdAt)
                        .format("DD/MM/YYYY - hh:mm A")}
                    </Text>
                  </div>

                  <div className="col-lg-3 d-flex align-items-center m-0 me-auto my-2">
                    <div className=" me-2">
                      <Circle size="40px" bg={checkColorMode(colorMode)}>
                        {item.consultationTypeId ? (
                          <img
                            className="image-fluid"
                            src={GetSpecialityIcon(
                              Number(item.consultationTypeId)
                            )}
                            alt="chest"
                          />
                        ) : (
                          ""
                        )}
                      </Circle>
                    </div>

                    <Box className="col-9 p-0">
                      <Text
                        color="smallTextGray"
                        fontSize="15px"
                        fontWeight="600"
                      >
                        Speciality
                      </Text>
                      <Text fontSize="13px" fontWeight="700">
                        {ConsultationType.length
                          ? ConsultationType.find(
                              (element) =>
                                element.id === item.consultationTypeId
                            ).name
                          : ""}
                      </Text>
                    </Box>
                  </div>
                  {item.patient ? (
                    <div className="col-lg-3 d-flex align-items-center m-0 my-2">
                      <div className="me-2">
                        <Avatar
                          style={{ width: "40px", height: "40px" }}
                          bg={checkColorMode(colorMode)}
                          size="md"
                          src={item.patient.picture}
                          name={`${item.patient.firstName} ${item.patient.lastName}`}
                          color="#FFF"
                        />
                      </div>
                      <Box className="col-9 p-1">
                        <Text
                          color="smallTextGray"
                          fontSize="15px"
                          fontWeight="600"
                        >
                          Patient
                        </Text>
                        <Text fontSize="13px" fontWeight="700">
                          {`${item.patient.fullName} `}
                        </Text>
                      </Box>
                    </div>
                  ) : null}

                  <div className="col-lg-2 my-2">
                    <div
                      className={`${
                        check_status_string(item.statusString).className
                      } text-center rounded`}
                    >
                      {item.statusString}
                    </div>
                  </div>
                </Box>
              </motion.div>
            ))}
          </div>
        </motion.div>
      );
    } else {
      return <EmptyActiveConsultations />;
    }
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    ActiveConsultationsList: state.ActiveConsultationsList,
    consultationTypes: state.consultationTypes,
  };
};
export default connect(mapStateToProps, {
  getActiveConsultations,
  getConsultationsTyeps,
})(ActiveConsulttationList);
