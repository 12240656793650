import { combineReducers } from "redux";
import {
  getConsultationTypeListReducer,
  getPatientTypesReducer,
} from "./common";
import {
  loginReducer,
  logOutReducer,
  signupReducer,
  resendConsultantVerifyMobileReducer,
  consultantVerifyMobileReducer,
  forgetPasswordReducer,
} from "./consultant_auth";

import {
  getConsultantProfileReducer,
  updateConsultantProfileReducer,
  changePasswordReducer,
  updateConsultantProfilePictureReducer,
  getQualificationsListReducer,
  getSchedulesListReducer,
  addQualificationsReducer,
  updateQualificationsReducer,
  updateSchedulesReducer,
  getConsultationsListReducer,
} from "./consultant_File";

import {
  getPatientNotificationsReducer,
  getPatientUnReadNotificationsReducer,
  markAllNotificationAssReadReducer,
  markOneNotificationAssReadReducer,
} from "./Notifications";

import {
  getActiveConsultationsListReducer,
  getSingleConsultationReducer,
  getQuestionListReducer,
  getSingleQuestionReducer,
  requestQuestionReducer,
  addedReportReducer,
  requestScanReducer,
  acceptConsultationReducer,
  rejectConsultationReducer,
} from "./consultationReducers";

import { getAppointmentListReducer } from "./appointmentsReducders";
import { getReportsListReducer } from "./ReportsReducers";
import {
  getOneConditionReducer,
  getOneAllergyReducer,
  getOneEcgReducer,
  getOneLabResultReducer,
  getOneMedicalReportReducer,
  getOneXrayReducer,
  getOneRadiologyReducer,
  getRadiologyListReducer,
  getAllergyListReducer,
  getConditionListReducer,
  getEcgListReducer,
  getLabResultListReducer,
  getMedicalReportListReducer,
  getXrayListReducer,
} from "./medicalRecordReducers";

export default combineReducers({
  //Common
  consultationTypes: getConsultationTypeListReducer,
  patientTypes: getPatientTypesReducer,
  // Auth
  login: loginReducer,
  logOut: logOutReducer,
  signup: signupReducer,
  VerifyMobile: consultantVerifyMobileReducer,
  resendVerifyMobile: resendConsultantVerifyMobileReducer,
  forgetPassword: forgetPasswordReducer,
  /// consultant
  consultant: getConsultantProfileReducer,
  updateProfile: updateConsultantProfileReducer,
  changePassword: changePasswordReducer,
  updateConsultantPicture: updateConsultantProfilePictureReducer,
  QualificationsList: getQualificationsListReducer,
  SchedulesList: getSchedulesListReducer,
  addQualifications: addQualificationsReducer,
  updatedQualification: updateQualificationsReducer,
  updatedSchedule: updateSchedulesReducer,
  acceptConsultation: acceptConsultationReducer,
  rejectConsultation: rejectConsultationReducer,
  //Consultations
  ConsultationsList: getConsultationsListReducer,
  ActiveConsultationsList: getActiveConsultationsListReducer,
  SingleConsultation: getSingleConsultationReducer,
  requestedSacn: requestScanReducer,
  // medical record
  OneCondition: getOneConditionReducer,
  OneAllergy: getOneAllergyReducer,
  OneEcg: getOneEcgReducer,
  OneLabResult: getOneLabResultReducer,
  OneMedicalReport: getOneMedicalReportReducer,
  OneXray: getOneXrayReducer,
  OneRadiology: getOneRadiologyReducer,
  RadiologyList: getRadiologyListReducer,
  AllergyList: getAllergyListReducer,
  ConditionList: getConditionListReducer,
  EcgList: getEcgListReducer,
  LabResultList: getLabResultListReducer,
  MedicalReportList: getMedicalReportListReducer,
  XrayList: getXrayListReducer,
  //Question
  QuestionList: getQuestionListReducer,
  SingleQuestion: getSingleQuestionReducer,
  RequestQuestion: requestQuestionReducer,
  // notifications
  NotificationList: getPatientNotificationsReducer,
  unReadCount: getPatientUnReadNotificationsReducer,
  markAll: markAllNotificationAssReadReducer,
  markOne: markOneNotificationAssReadReducer,
  // Appointment
  AppointmentList: getAppointmentListReducer,
  // Reports
  ReportsList: getReportsListReducer,
  // medical report
  addedReport: addedReportReducer,
});
