import React, { useEffect, useState } from "react";

import { Box, Button, Text, useColorMode, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateConsultantProfile,
  getConsultantProfile,
  getConsultationsTyeps,
  patientTypeList,
} from "../../../../../store/actions";
import { InputControl, SelectControl } from "formik-chakra-ui";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../global/changeColor";
import NmberInput from "../../../../global/NmberInput";

const SubmitSchema = Yup.object().shape({
  patientTypeId: Yup.string().required("Required"),
  consultationTypes: Yup.string().required("Required"),
  gmcNumber: Yup.string().required("Required"),
  currentEmployer: Yup.string()
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "Only alphabetic characters allowed"
    )
    .required("Required"),
  yearsOfExperienceAsConsultant: Yup.string().required("Required"),
  jobTitle: Yup.string()
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "Only alphabetic characters allowed"
    )
    .required("Required"),
});

const AboutMe = ({
  consultantProfile,
  updateConsultantProfile,
  getConsultantProfile,
  updateProfile,
  getConsultationsTyeps,
  patientTypeList,
  patientTypes,
  ConsultationTypes,
}) => {
  const toast = useToast();
  const history = useHistory();
  const { colorMode } = useColorMode();
  const [edite, setEdite] = useState(false);
  const [initValue, setInitValue] = useState({
    patientTypeId: "",
    consultationTypes: "",
    gmcNumber: "",
    currentEmployer: "",
    yearsOfExperienceAsConsultant: "",
    jobTitle: "",
  });

  const onFormSubmit = (value) => {
    const consultationTypesList = [];
    consultationTypesList.push(value.consultationTypes);
    const data = {
      name: consultantProfile.name,
      address: consultantProfile.address,
      email: consultantProfile.email,
      mobile: consultantProfile.mobile,
      dateOfBirth: consultantProfile.dateOfBirth,
      gender: consultantProfile.gender,

      gmcNumber: value.gmcNumber,
      yearsOfExperienceAsConsultant: value.yearsOfExperienceAsConsultant,
      currentEmployer: value.currentEmployer,
      patientTypeId: Number(value.patientTypeId),
      consultationTypes: consultationTypesList,
      jobTitle: value.jobTitle,
    };
    updateConsultantProfile(
      data,
      history,
      toast,
      null,
      setEdite,
      getConsultantProfile
    );
  };

  useEffect(() => {
    if (consultantProfile) {
      setInitValue({
        ...initValue,
        gmcNumber: consultantProfile.gmcNumber,
        yearsOfExperienceAsConsultant:
          consultantProfile.yearsOfExperienceAsConsultant,
        currentEmployer: consultantProfile.currentEmployer,
        patientTypeId: consultantProfile.patientTypeId,
        consultationTypes: consultantProfile.consultationTypes[0],
        jobTitle: consultantProfile.jobTitle,
      });
    }
  }, [consultantProfile]);

  useEffect(() => {
    getConsultationsTyeps();
    patientTypeList();
  }, []);

  const renderView = () => {
    if (edite) {
      return (
        <Formik
          initialValues={initValue}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={SubmitSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                <div>
                  <Text fontSize="lg" color={"secondary"} fontWeight="semibold">
                    {" "}
                    About Me
                  </Text>
                </div>
                <div className="w-100 text-end my-2">
                  <Button
                    width={"50px"}
                    height="50px"
                    bg="#f3e9eb"
                    _hover={{ bg: "#f3d3da" }}
                    fontSize="20px"
                    color="#D42C51"
                    className="me-2"
                    onClick={() => setEdite(false)}
                    type="button"
                  >
                    <i className="fas fa-times"></i>
                  </Button>

                  <Button
                    width={"50px"}
                    height="50px"
                    bg="secondary"
                    _hover={{ bg: "secondary_hover" }}
                    fontSize="20px"
                    type="submit"
                    color="#fff"
                    isLoading={updateProfile.loading}
                  >
                    <i className="fas fa-save"></i>
                  </Button>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-3">
                  <SelectControl
                    label="Patient Type"
                    name="patientTypeId"
                    selectProps={{
                      placeholder: "Patient Type",
                      className: "chakra-input",
                    }}
                    color="gray.500"
                  >
                    {patientTypes && patientTypes.length
                      ? patientTypes.map((item, index) => (
                          <option
                            key={index}
                            value={item.id}
                            style={{ color: "#000" }}
                          >
                            {item.name}
                          </option>
                        ))
                      : null}
                  </SelectControl>
                </div>

                <div className="col-md-6 mt-3">
                  <SelectControl
                    label="Specialty"
                    name="consultationTypes"
                    selectProps={{
                      placeholder: "Specialty",
                      className: "chakra-input",
                    }}
                    color="gray.500"
                  >
                    {ConsultationTypes && ConsultationTypes.length
                      ? ConsultationTypes.map((item, index) => (
                          <option
                            key={index}
                            value={item.id}
                            style={{ color: "#000" }}
                          >
                            {item.name}
                          </option>
                        ))
                      : null}
                  </SelectControl>
                </div>

                <div className="col-md-6 mt-3">
                  <NmberInput
                    name="gmcNumber"
                    label="GMC number"
                    placeholder="GMC number"
                  />
                </div>

                <div className="col-md-6 mt-3">
                  <InputControl
                    name="currentEmployer"
                    label="Current employer"
                    inputProps={{ placeholder: "Current employer" }}
                  />
                </div>

                <div className="col-md-6 mt-3">
                  <NmberInput
                    name="yearsOfExperienceAsConsultant"
                    label="Years of experience as Consultant"
                    placeholder="Years of experience as Consultant"
                  />
                </div>

                <div className="col-md-6 mt-3">
                  <InputControl
                    name="jobTitle"
                    label="Job Title"
                    inputProps={{ placeholder: "Job Title" }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    } else {
      return (
        <>
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Text fontSize="lg" color={"secondary"} fontWeight="semibold">
              {" "}
              About Me
            </Text>
            <Button
              width={"50px"}
              height="50px"
              bg={checkColorMode(colorMode)}
              _hover={{ bg: checkColorModeHover(colorMode) }}
              fontSize="17px"
              color="white"
              onClick={() => setEdite(true)}
            >
              <i className="fas fa-pen"></i>
            </Button>
          </div>

          <div className="col-12 d-flex flex-wrap">
            <div className="col-md-4 col-12 p-0 mt-3">
              <Text fontWeight="semibold" color={"smallTextGray"}>
                Patient Type
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {consultantProfile && patientTypes.length
                  ? patientTypes.find(
                      (item) => item.id === consultantProfile.patientTypeId
                    ).name
                  : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0 mt-3">
              <Text fontWeight="semibold" color={"smallTextGray"}>
                Specialty
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {consultantProfile && ConsultationTypes.length
                  ? ConsultationTypes.find(
                      (element) =>
                        element.id === consultantProfile.consultationTypes[0]
                    ).name
                  : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0  mt-3">
              <Text fontWeight="semibold" color={"smallTextGray"}>
                GMC Number
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {consultantProfile ? consultantProfile.gmcNumber : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0  mt-4">
              <Text fontWeight="semibold" color={"smallTextGray"}>
                Current Employer
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {consultantProfile ? consultantProfile.currentEmployer : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0  mt-4">
              <Text fontWeight="semibold" color={"smallTextGray"}>
                Years of experience
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {consultantProfile
                  ? consultantProfile.yearsOfExperienceAsConsultant
                  : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0  mt-4">
              <Text fontWeight="semibold" color={"smallTextGray"}>
                Job Title
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {consultantProfile ? consultantProfile.jobTitle : "null"}
              </Text>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="MyInfo rounded shadow-sm p-4  row m-0 flex-wrap mb-4"
    >
      {renderView()}
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    updateProfile: state.updateProfile,
    patientTypes: state.patientTypes.data,
    ConsultationTypes: state.consultationTypes.data,
  };
};
export default connect(mapStateToProps, {
  getConsultantProfile,
  updateConsultantProfile,
  getConsultationsTyeps,
  patientTypeList,
})(AboutMe);
