import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import NotFound from "../../../../../../NotFound/NotFound";
import ListingXray from "./Children/listingXray";
import requestXray from "./Children/requestXray";
import ViewOneXray from "./Children/viewOneXray";

const Xray = ({ patient }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <ListingXray patient={patient} />}
      />
      <Route exact path={`${path}/request-x-ray`} component={requestXray} />
      <Route
        path={`${path}/view-x-ray/:medicalId`}
        render={() => <ViewOneXray patient={patient} />}
      />
      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};

export default Xray;
