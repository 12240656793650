import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getAppointments } from "../../store/actions";
import { checkColorMode } from "../global/changeColor";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const FilterByMonth = ({ getAppointments }) => {
  const { colorMode } = useColorMode();
  const filterWithSpecificMonth = (month) => {
    const year = new Date().getFullYear();
    const tsm = moment(`${month + 1}/01/${year} 00:00:00`).format("X");
    if (tsm) getAppointments(null, tsm);
  };

  return (
    <div className="my-2">
      <Menu>
        <MenuButton
          as={Button}
          color={checkColorMode(colorMode)}
          bg={colorMode === "dark" ? "secondaryOpacity" : "primaryOpacity"}
          size="lg"
          _hover={{
            bg:
              colorMode === "dark"
                ? "secondary_hover_Opacity"
                : "primaryOpacity_hover",
          }}
          _active={{
            bg:
              colorMode === "dark"
                ? "secondary_hover_Opacity"
                : "primaryOpacity_hover",
          }}
        >
          <span className="me-2">
            <i className="fas fa-angle-down"></i>
          </span>{" "}
          Month
        </MenuButton>
        <MenuList className="shadow p-2 " minW={170}>
          {months.map((month, index) => (
            <MenuItem
              key={index}
              p={2}
              fontSize="17px"
              className="text-center rounded"
              color={checkColorMode(colorMode)}
              _hover={{
                backgroundColor:
                  colorMode === "dark"
                    ? "secondary_hover_Opacity"
                    : "primaryOpacity_hover",
              }}
              onClick={() => filterWithSpecificMonth(index)}
            >
              <Text fontWeight="semibold" w={"100%"}>
                {month}
              </Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};

export default connect(null, { getAppointments })(FilterByMonth);
