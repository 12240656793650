import {
  Box,
  Button,
  Circle,
  Divider,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LinksAndImages } from "../../Notifications/helperImageAndLinkes";
import MenuComponent from "../../Notifications/menu.Component";
import {
  checkColorMode,
  checkBgColorMode,
  checkColorModeHover,
} from "../../global/changeColor";
const MyAvtivityHome = ({ NotificationList }) => {
  const [notifications, setNotifications] = useState([]);
  const { colorMode } = useColorMode();
  useEffect(() => {
    if (NotificationList) {
      const { data } = NotificationList;
      const newData = data.slice(0, 3);
      setNotifications(newData);
    }
  }, [NotificationList]);

  const renderView = () => {
    if (notifications && notifications.length) {
      return (
        <Box
          as={motion.div}
          bg={checkBgColorMode(colorMode)}
          className="row m-0  rounded shadow-sm my-3 align-items-center mt-5 myActivityHome mb-5"
        >
          <div className="col-12 p-3 row m-0 my-3 align-items-center">
            <div className="col-6">
              <Text fontWeight="semibold">My Activity</Text>
            </div>
            <div className="col-6 text-end">
              <Button
                as={Link}
                to="/my_activity"
                color={checkColorMode(colorMode)}
                _hover={{ color: checkColorMode(colorMode) }}
              >
                More
              </Button>
            </div>
          </div>

          {notifications.map((item, index) => (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              className="m-0 p-0"
              key={index}
            >
              <div className="col-12  d-flex  justify-content-center">
                <Divider
                  className="text-center"
                  width="95%"
                  color="smallTextGrayLight"
                />
              </div>
              <Box className="col-12 p-3 row m-0 align-items-center">
                <Box className="col-md-9 row">
                  {" "}
                  <div className="col-md-7 col-12 d-flex align-items-center my-2">
                    <Circle
                      size="40px"
                      bg={checkColorMode(colorMode)}
                      color="#fff"
                      className="me-2"
                    >
                      {LinksAndImages(item).img ? (
                        <Image
                          maxW={"80%"}
                          maxH="70%"
                          className="image-fluid"
                          src={LinksAndImages(item).img}
                          alt={item.data.type ? item.data.type : "no"}
                        />
                      ) : (
                        <i className={LinksAndImages(item).className}></i>
                      )}
                    </Circle>
                    <div className="col-9" style={{ fontSize: "13.5px" }}>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        color="smallTextGray"
                      >
                        Activity
                      </Text>
                      <Text fontSize="13px" fontWeight="700">
                        {item.data && item.data.title
                          ? item.data.title
                          : "null"}
                      </Text>
                    </div>
                  </div>
                  <div className="col-md-5 col-12 d-flex align-items-center my-2">
                    <div className="col-9 " style={{ fontSize: "13.5px" }}>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        color="smallTextGray"
                      >
                        Time
                      </Text>
                      <Text fontSize="12px" fontWeight="700">
                        {item.updatedAt
                          ? moment
                              .unix(item.updatedAt)
                              .format("DD/MM/YYYY hh:mm a")
                          : "null"}
                      </Text>
                    </div>
                  </div>
                </Box>

                <Box className="col-md-3 p-0 text-end">
                  <MenuComponent LinksAndImages={LinksAndImages} item={item} />
                </Box>
              </Box>
            </motion.div>
          ))}
        </Box>
      );
    } else {
      return "";
    }
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    NotificationList: state.NotificationList.data,
  };
};
export default connect(mapStateToProps, {})(MyAvtivityHome);
