import React from "react";
import { useDropzone } from "react-dropzone";
import { useToast, useColorMode, Text } from "@chakra-ui/react";

import "./upload.scss";
import upload from "../../../../../../../assets/svg-icons/upload.svg";
import { checkColorMode } from "../../../../../../global/changeColor";

const UploadComponent = (props) => {
  //
  const toast = useToast();
  const { colorMode } = useColorMode();
  //
  const { setFieldValue, currntFiles } = props;
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: "application/pdf",
    onDrop: (acceptedFiles, rejectedFiles) => {
      setFieldValue("files", [...acceptedFiles]);
      currntFiles(acceptedFiles);
      if (rejectedFiles.length) {
        const erroes = rejectedFiles[0].errors;
        Object.entries(erroes).map(([key, value]) => {
          return toast({
            title: value.message,
            status: "error",
            duration: 7000,
            isClosable: true,
            position: "top-right",
          });
        });
      }
    },
  });
  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        <div className="w-100 d-flex justify-content-center">
          <img src={upload} alt="upload" />
        </div>
        <div className="text-center">
          <input {...getInputProps()} />

          <Text className="fw-bold mt-4" color="gray.500">
            {" "}
            Drop your files here
          </Text>
          <p>( PDF )</p>
          <Text color={checkColorMode(colorMode)} className="fw-bold">
            Click to upload
          </Text>
        </div>
      </div>
    </>
  );
};

export default UploadComponent;
